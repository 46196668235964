import { MidasMGT } from "./midasMGT";
class MidasMGTAnalysis {

    constructor(callbacks = false) {
        this.fileAnalysis = {};
        this.md5OfFiles = {
            //"md5":filename,
        };
        this.filenames = {
            // filename:filename
        };
        this.callWorker = {};
        this.init(callbacks);
    }
    init(callback) {

        if (callback) {
            for (let i in callback) {
                this.callWorker[i] = callback[i];
            }
        }
        else {
            this.log("resAnalysis : can't find the callback or worker input");
        }
        this.log("resAnalysis Init.");
    }
    async do(data) {
        if (data.ext == "mgt") {
            this.fileAnalysis = new MidasMGT(data);
            if (this.fileAnalysis.succeed)
                this.postMSG({
                    type: "midasMGT",
                    data: this.fileAnalysis.data,
                    succeed: this.fileAnalysis.succeed,
                    index: data.index,
                    subject: { "midasMGT": data.ext }
                });
            else {
                this.log({
                    type: "error",
                    succeed: this.fileAnalysis.succeed,
                    subject: "解析资源错误",
                    data: this.fileAnalysis.MSG,
                    sender: "midas MGT module",
                });
            }
        }


    }
    postMSG(data) {
        // this.callWorker.onMSG({ type: "Mesh", content: "dfasdfasdf" }, this.callWorker.parent);
        this.callWorker.onMSG(data);
    }
    // this function will be rewrite by worker fork class
    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callWorker["log"] != "undefined") {
            that.callWorker.log(msg, that.callWorker.parent);
        }
        else {
            console.log("Midas MGT : ", msg);
        }
    }

}

export { MidasMGTAnalysis };