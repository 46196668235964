class MeshOrigin {
    constructor(data, RGB = false) {
        this.parent = data.parent;
        this.data = {
            source: {
                nodes: {},
                mesh: {},
                type: "msh",

            },
            drawTree: {
            }
        };
        this.PLTFR = {
            matrix: false,
            type: "PLTFR",
            points: {
                index: [],
                indices: false,
                xyz: [],
                pressures: {},
                color: [],
            },
            lines: {
                index: [],
                indices: false,
                xyz: [],
                pressures: {},
                color: [],
                cm: [],
            },
            triangles: {
                index: [],
                indices: false,
                xyz: [],
                uv: [],
                normal: [],
                cm: [], pressures: {},
            },
            frameLines: {
                index: [],
                indices: false,
                xyz: [],
                color: []
            },
            shellPoints: {
                index: {},//对象，key是index
                // xyz: [],
            },
            network: [],
        };
        this.colors = {
            frameLine: [0, 0, 0],
            line: [1, 1, 1],
            point: [1, 1, 1]
        };
        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;
        if (RGB === true) {
            this.defaultColorR = -9.;
            this.defaultColorG = -9.;
            this.defaultColorB = -9.;
        }
        this.MSG = {};
        this.succeed = false;

        return this;
    }
    log(msg) {

        if (typeof this.parent != "undefined") {
            this.parent.log(msg, this.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }
    init(data) {
        let succeed = this.getFileMesh(data);

        // if (succeed)
        // succeed =
        this.calculateMesh();

        this.succeed = succeed;
    }

    getFileMesh(text) { }
    calculateMesh() { }
    // calculateNetwork() {
    //     for (let i in this.data.drawTree) {
    //         if (i == "Dimension_2OR3") {
    //             continue;
    //         }
    //         let perMesh = this.data.drawTree[i];
    //         for (let j in perMesh.network.list) {
    //             perMesh.network.index.push(perMesh.network.list[j].index);
    //             perMesh.network.xyz.push(perMesh.network.list[j].xyz);
    //         }
    //     }
    // }
}
export { MeshOrigin };