import { CMOrigin } from "../cm/cmOrigin";
import { DrawMidas } from "./drawMidas";
import { GetFileOfMidas } from "./getfileofmidas";
class CMMidas extends CMOrigin {
    // parent, file, check = 0, callback
    constructor(input = false) {

        super(input);
        this.midasSetting = {
            visible: this.input.visible,
            onlyShow: false,
        };
        let that = this;

        if (typeof this.input.extra.midas.resOBJ != "undefined") {
            this.info.resOBJ = this.input.extra.midas.resOBJ.info.data;
            this.otherParent = this.input.extra.midas.resOBJ;
        }

        this.Draw = new DrawMidas(this, {
            log: this.log,
            afterDraw: function () {
                that.afterDraw(that, "draw");
            },
        });
        this.OtherShowOrHide = {};
        this.init(input.callbacks);
        return this;
    }
    init() {
        let that = this;
        this.log({
            type: "log",
            succeed: true,
            subject: "1%:初始化",
            sender: "CM midas module"
        });
        this.worker.getFile = new GetFileOfMidas({
            log: this.log,
            onMSG: function (data) {
                if (data.subject == "midas") {
                    that.info.setExtraMidas(data.data, true);
                    that.draw(data, that, that.input.visible);
                }
            },
            parent: this
        });

        // this.worker.loadPLTFR = new SaveDB({
        //     log: this.log,
        //     onMSG: function (data) {
        //         that.draw(data, that);
        //     },
        //     parent: this,
        //     dbName: this.input.file,
        // });

        this.log({
            type: "log",
            succeed: true,
            subject: "2%:开始传输文件",
            sender: "CM midas module",
        });

        // 异步获取不到res等的数据
        // if (typeof this.input.extra != undefined)
        //     if (typeof this.input.extra.midas.data != undefined) {
        //         for (let i in this.input.extra.midas.data) {
        //             if (i == "res ") {
        //                 this.info.setRES(this.input.extra.midas.data[i]);
        //             }
        //             if (i == "str") {
        //                 this.info.setExtraStr(this.input.extra.midas.data[i]);
        //             }
        //         }
        //     }

        if (typeof this.input.file != "undefined")
            this.worker.getFile.readFile(this.input.file, this.input.extra);

    }
    setVisible(show = true) {
        this.Draw.data.meshTree.visible = show;
        this.midasSetting.visible = show;
    }

    setOtherToHide() {
        let parent = this.Draw.data.meshTree.parent;
        let that = this;
        for (let child of parent.children) {
            if (child.name != "midas" && child.name != "meshTree") {
                that.otherParent[child.name] = child.visible;
                child.visible = false;
            }

        }
    }
    restoreOther() {
        let parent = this.Draw.data.meshTree.parent;
        let that = this;
        for (let child of parent.children) {
            if (child.name != "midas" && child.name != "meshTree") {
                child.visible = that.otherParent[child.name];
            }
        }
    }
    setMidasShowOnly(only = true) {
        this.midasSetting.onlyShow = only;
        if (only === true) {
            this.setOtherToHide();

            if (typeof this.Draw.data.meshTree != "undefined" && typeof this.Draw.data.meshTree.visible != "undefined")
                this.Draw.data.meshTree.visible = true;
        }
        else {
            this.restoreOther();
        }

    }

    draw(value, that = false) {
        // this.log(value);
        if (that == false) {
            that = this;
        }
        if (typeof value.type !== "undefined" && typeof value.succeed !== "undefined" && typeof value.data !== "undefined") {

            if (value.type == "data" && value.succeed == true && value.data != "") {
                that.log({
                    type: "log",
                    succeed: true,
                    subject: "90%:数据分析完毕",
                    sender: "CM module",
                    // data: value
                });
                if (typeof this.input.visible !== "undefined" && this.input.visible === true)
                    that.Draw.draw(value);// data is from callback or worker ,it's a JSON that includ PLTFR
                this.log({ type: "log", succed: true, subject: "100% 完成" });
            } else {
                that.log({
                    type: "error",
                    succeed: false,
                    subject: "错误:数据分析异常,CMMidas:48",
                    data: value,
                    sender: "CM module",
                });
            }
        } else {
            that.log({
                type: "error",
                succeed: false,
                subject: "错误:数据分析异常,CMMidas:57",
                data: value,
                sender: "CM module",
            });
        }
    }

    afterDraw(that) {

        if (typeof that.callbacks.afterDraw == "function") {
            that.callbacks.afterDraw(that);
        }

    }


}
export { CMMidas };
