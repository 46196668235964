// import { GetFile } from "./getfile";// worker ready
// import { SaveDB } from "./savedb";//no worker 
import { Draw } from "./drawThree";// no worker
// import { Info } from "./info";// no worker
// // import testWorker from '../worker/test.worker.js'
// import { OctreeDraw } from "../tree/octreeDraw";
import { CMOrigin } from "./cmOrigin";

class CMThree extends CMOrigin {
    // parent, file, check = 0, callback
    constructor(input = false) {

        super(input);
        let that = this;
        this.Draw = new Draw(this, {
            log: this.log,
            afterDraw: function () {
                that.afterDraw(that, "draw");
            },
        });

        this.init(input.callbacks);
        return this;
    }


    draw(value, that = false) {
        // this.log(value);
        if (that == false) {
            that = this;
        }
        if (typeof value.type !== "undefined" && typeof value.succeed !== "undefined" && typeof value.data !== "undefined") {

            if (value.type == "data" && value.succeed == true && value.data != "") {
                that.log({
                    type: "log",
                    succeed: true,
                    subject: "90%:数据分析完毕",
                    sender: "CM module",
                    // data: value
                });
                that.Draw.draw(value);// data is from callback or worker ,it's a JSON that includ PLTFR
                this.log({ type: "log", succed: true, subject: "100% 完成" });
            } else {
                that.log({
                    type: "error",
                    succeed: false,
                    subject: "错误:数据分析异常,cmthree:48",
                    data: value,
                    sender: "CM module",
                });
            }
        } else {
            that.log({
                type: "error",
                succeed: false,
                subject: "错误:数据分析异常,cmthree:57",
                data: value,
                sender: "CM module",
            });
        }
    }

    afterDraw(that) {
        super.afterDraw(that,"draw");

        // if (typeof that.callbacks.afterDraw == "function" && typeof that.callbacks.afterDrawFlag != "undefined" && that.callbacks.afterDrawFlag == "draw" && this.cache !== true) {
        //     that.callbacks.afterDraw(that);
        // }
        // that.setDimension();
        // that.updateENVLast();
    }
}



export { CMThree };
