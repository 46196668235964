import { Color } from "three";

class CanvasLUT {

    constructor(canvas, count = 12) {
        this.ctx = document.getElementById(canvas).getContext("2d");
        this.colorOfRect = { r: .5, g: .5, b: .5 };
        this.colorOfText = { r: .0, g: .0, b: .0 };
        this.bgColor = false;
        this.heightOfTitle = 60;
        this.x = 15;
        this.y = this.heightOfTitle;
        this.w = 25;
        this.h = 15;
        this.max = false;
        this.min = false;
        this.count = count;
        this.sizeOfFont = 12;
        this.unit = " ";

    }
    clean() {
        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.ctx.canvas.offsetWidth, this.ctx.canvas.offsetHeight);
            if (this.bgColor !== false) {
                // this.ctx.fillStyle = "rgb(" + this.bgColor.r * 255 + "," + this.bgColor.g * 255 + "," + this.bgColor.b * 255 + ")";
                // // console.log(ctx.fillStyle);
                // this.ctx.fillRect(0, 0, this.ctx.canvas.offsetWidth, this.ctx.canvas.offsetHeight);
                this.ctx.canvas.style.background = "rgba(" + this.bgColor.r * 255 + "," + this.bgColor.g * 255 + "," + this.bgColor.b * 255+ "," + this.bgColor.a + ")";
            }
            this.hide();
        }
    }
    show() {
        if (this.ctx) {
                  this.ctx.canvas.style.display = "block"; 
        }
    }
    hide() {
        if (this.ctx) {
                  this.ctx.canvas.style.display = "none"; 
        }
    }
    format(ss) {
        let ll = 7;

        ss = parseFloat(ss).toExponential();
        // return ss.split('e')[0].slice(0, 5) + "E" + ss.split('e')[1];
        let a = ss.split("e")[0].slice(0, ll);
        let b = " E" + ss.split("e")[1];
        if (a.length < 8) {
            if (a.match(/\./) ? false : true) {
                a = a + ".";
            }
            let last_1 = a.substr(a.length - 1, 1);
            let last_2 = a.substr(a.length - 2, 1, 2);
            last_1 = parseInt(last_1);
            last_2 = parseInt(last_2);
            if (last_1 > 5) {
                last_2++;
                a = a.substr(0, a.length - 2) + last_2;
            }

            let len = a.length;
            for (let i = 1; i <= ll - len; i++) {
                a = a + "0";
            }
            if (a.length > 6 && a > 0) {
                a = a.substr(0, 6);
            }

        }
        if (a == "0.00000") a = "0.0000";
        let sss = 0.0000;
        if (ss > 0) {
            sss = " +" + a + b;
        }
        else if (ss == 0e+0) {
            sss = "  " + a + b;
        }
        else {
            sss = " " + a + b;
        }

        return sss;
    }

    getValues(max, min, count) {
        if (typeof this.unit == "undefined") {
            this.unit = "";
        }
        max = parseFloat(max);
        min = parseFloat(min);
        let n = count;
        let values = [];
        values.push("_" + this.format(max.toExponential()) + " " + this.unit);

        for (let i = n - 1; i >= 0; i--) {
            values.push("_" + this.format((max - min) / n * i + min) + " " + this.unit);
        }
        // values.push("_" + format(min.toExponential()));
        return values;
    }

    getColorMap(count = 32) {

        count -= 1;
        let step = 1.0 / count;
        let lut = [];

        lut.length = 0;

        let colormap = [
            [0.08333333333333333, 0x0000FF],
            [0.16666666666666666, 0x005cff],
            [0.25, 0x00b9ff],
            [0.3333333333333333, 0x00ffe7],
            [0.41666666666666663, 0x00ff8b],
            [0.49999999999999994, 0x00ff2e],
            [0.5833333333333333, 0x2eff00],
            [0.6666666666666666, 0x8bff00],
            [0.75, 0xe7ff00],
            [0.8333333333333334, 0xffb900],
            [0.9166666666666667, 0xff5c00],
            [1.0, 0xFF0000]]
            ;
        for (let i of colormap) {
            lut.push(new Color(i[1]));
        }

        // let colormap = [[0.0, 0x0000FF], [0.3, 0x00FFFF], [0.5, 0x00FF00], [0.7, 0xFFFF00], [1.0, 0xFF0000]];
        // lut.push(new Color(colormap[0][1]));
        // let vi;
        // for (let i = step; i <= 1; i += step) {
        //     console.log(i, step)
        //     for (let j = 0; j <= colormap.length - 1; j++) {

        //         if (typeof colormap[j + 1] == 'undefined' && i == 1.) {
        //             lut.push(new Color(colormap[j][1]));
        //         }
        //         else
        //             if (i >= colormap[j][0] && i < colormap[j + 1][0]) {
        //                 const min = colormap[j][0];
        //                 const max = colormap[j + 1][0];
        //                 const minColor = new Color(colormap[j][1]);
        //                 const maxColor = new Color(colormap[j + 1][1]);
        //                 // const color = minColor.lerpColors(minColor, maxColor, (i - min) / (max - min));
        //                 const color = minColor.lerp(maxColor, (i - min) / (max - min));
        //                 lut.push(color);
        //                 // break;
        //             }

        //     }
        //     vi = i;
        // }
        // if ((1. - vi) > step * 0.5) {
        //     lut.push(new Color(colormap[colormap.length - 1][1]));
        // }

        // console.log(lut)
        return lut;

    }
    _draw(title, colors = [], colorOfText, colorOfRect, x = 5, y = 5, w = 25, h = 25, max, min) {
        // console.log(strings)
        let strings = this.getValues(max === false ? this.max : max, min === false ? this.min : min, colors.length);

        let ctx = this.ctx;

        if (this.bgColor !== false) {
            // ctx.fillStyle = "rgba(" + this.bgColor.r * 255 + "," + this.bgColor.g * 255 + "," + this.bgColor.b * 25 + this.bgColor.a  +")";
            // // console.log(ctx.fillStyle);
            // ctx.fillRect(0, 0, this.ctx.canvas.offsetWidth, this.ctx.canvas.offsetHeight);
            ctx.canvas.style.background = "rgba(" + this.bgColor.r * 255 + "," + this.bgColor.g * 255 + "," + this.bgColor.b * 255+ "," + this.bgColor.a + ")";
        }
        let j = 0;
        for (var i = colors.length - 1; i >= 0; i--, j++) {
            let color = colors[i];
            ctx.fillStyle = "rgb(" + color.r * 255 + "," + color.g * 255 + "," + color.b * 255 + ")";
            // console.log(ctx.fillStyle);
            ctx.fillRect(x, y + j * h, w, h);
            ctx.strokeStyle = "rgb(" + colorOfRect.r * 255 + "," + colorOfRect.g * 255 + "," + colorOfRect.b * 255 + ")";
            ctx.strokeRect(x, y + j * h, w, h);

            ctx.fillStyle = "rgb(" + colorOfText.r * 255 + "," + colorOfText.g * 255 + "," + colorOfText.b * 255 + ")";
            ctx.font = this.sizeOfFont + "px serif";
            ctx.fillText(strings[j], x + w, y + j * h);
        }
        ctx.fillStyle = "rgb(" + colorOfText.r * 255 + "," + colorOfText.g * 255 + "," + colorOfText.b * 255 + ")";
        ctx.font = this.sizeOfFont + "px serif";
        ctx.fillText(strings[j], x + w, y + j * h);

        ctx.fillStyle = "rgb(" + colorOfText.r * 255 + "," + colorOfText.g * 255 + "," + colorOfText.b * 255 + ")";
        ctx.font = this.sizeOfFont * 1.5 + "px serif";
        ctx.fillText(title, 15, 30);
        this.show();
    }
    setColorStairs(n) {
        this.count = n;
    }
    setMM(max = false, min = false) {
        this.max = max;
        this.min = min;
    }
    setWH(w) {
        this.w = w;
        this.h = w * .6;
        if (w < 30)
            this.sizeOfFont = w * .6 - 3;
        else
            this.sizeOfFont = w * .6 - 5;
    }
    setXY(x, y) {
        this.x = x;
        this.y = y + this.heightOfTitle;
    }
    setColorOfRect(color = false) {
        if (color)
            this.colorOfRect = color;
        else
            this.colorOfRect = { r: .5, g: .5, b: .5 };
    }
    setBgColor(color = false) {
        if (color) {
            this.bgColor = color;
            this.ctx.canvas.style.background = "rgba(" + this.bgColor.r * 255 + "," + this.bgColor.g * 255 + "," + this.bgColor.b * 255 + ","+ this.bgColor.a + ")";
        }

    }
    setColorOfText(color = false) {
        if (color)
            this.colorOfText = color;
        else
            this.colorOfText = { r: 0., g: .0, b: 0. };
    }
    draw(title = "", count = false, max = false, min = false, colorOfText = false, colorOfRect = false, x = false, y = false, w = false, h = false) {
        if (this.ctx)
            if (max !== false && min !== false)
                this._draw(title, this.getColorMap(count === false ? this.count : count), colorOfText === false ? this.colorOfText : colorOfText, colorOfRect === false ? this.colorOfRect : colorOfRect, x === false ? this.x : x, y === false ? this.y : y, w === false ? this.w : w, h === false ? this.h : h, max, min);
            else {
                console.log("pls check max and min .");
            }
    }
}

export { CanvasLUT };