

class AnalysisPLTFR {

    constructor(parent, callbacks) {
        this.parent = parent;
        this.callbacks = callbacks;
        this.readyForAnlysis = 0;
        this.step = 0;
        this.hasMesh = 1;// 1= start ,2=has mesh ,3=finish // 是否含有mesh
        this.memeryDB = {
            setting: {
                bestTransformValue: 1,
                site: {
                    current: 1,
                    count: 1,
                    countList: [1]
                },

            },
            drawTree: {
                mesh1: {
                    points: {
                        index: [],
                        xyzs: [],
                        res: {
                            1: {
                                index: { 1: 1, 3: 2, 6: 3 },
                                pressures: {
                                    "U.U1": []
                                }
                            }
                        }
                    },
                    lines: {
                        index: [],
                        xyzs: [], res: {
                            1: {
                                index: { 1: 1, 3: 2, 6: 3 },
                                pressures: {
                                    "U.U1": []
                                }
                            }
                        }
                    },
                    triangle: {
                        index: [],
                        xyzs: [],
                        res: {
                            1: {
                                index: { 1: 1, 3: 2, 6: 3 },
                                pressures: {
                                    "U.U1": []
                                }
                            }
                        }
                    },
                    frameLine: {
                        index: [],
                        xyzs: []
                    },
                },
            },
        };
        this.data = {
            res: {
                res: {}
            },
            source: {},
            extra: {
                str: {},
            },
            // drawTree: {},
        };
        this.query = {};
    }
    async do(data, fisnsh = false, step = 0,newLoad=0) {
        if (newLoad == 1) {
            this.hasMesh = 1;
        }
        if (typeof data.data.drawTree != "undefined") {
            this.hasMesh = 2;
        }
        if (this.step == 0) {
            this.step = step;
        }
        if (typeof data.subject.mesh != "undefined") {
            this.data.drawTree = data.data.drawTree;
            this.data.source = data.data.source;
        }
        if (typeof data.subject.res != "undefined") {
            if (data.subject.res == "res") {
                await this.analysisRES(data);
            }
            if (data.subject.res == "dat") {
                await this.analysisDAT(data);
            }
        }
        if (typeof data.subject.str != "undefined") {
            this.data.str = data.data;
        }
        this.log({ "AnalysisPLTFR do function  and ready value is :": this.readyForAnlysis });
        this.log({ "AnalysisPLTFR do function ": data });
        if (fisnsh) {

            this.returnAnalysisData(this.hasMesh);


        }

        // this.returnAnalysisData(true);
    }
    // analysisINP() { }
    // analysisMSH() { }
    ////////////////////////////////////////////////////////////////////////////////////////
    // dat 

    analysisDAT(data) {
        if (typeof this.data.res.res[data.index] == "undefined") {
            this.data.res.res[data.index] = {};
        }
        if (data.index == 1) {
            this.data.res.res[data.index] = data.data.res[data.index];
            this.data.res.type = data.data.type;
        }
        else {
            let currentRes = this.data.res.res[1];
            let namesOfPreesures = [];
            for (let i in currentRes) {
                namesOfPreesures.push(i);
            }
            for (let i in data.data.res[data.index]) {
                let alreadyHave = false;
                for (let j of namesOfPreesures) {
                    if (j == i) {
                        alreadyHave = true;
                    }
                }
                if (alreadyHave === true) {
                    this.data.res.res[data.index] = data.data.res[data.index];
                    this.data.res.type = data.data.type;
                }
                else {

                    this.data.res.res[data.index][i] = data.data.res[data.index][i];
                }
            }

        }
        // this.resGetUVW();
        // let cm = this.data.res.res[data.index];
        // if (typeof this.data.drawTree == "undefined") {
        //     this.query[data.index] = data;
        // }
        // else
        //     for (let i in this.data.drawTree) {
        //         if (i == "Dimension_2OR3") {
        //             continue;
        //         }
        //         let perMesh = this.data.drawTree[i];
        //         if (perMesh.points)
        //             if (perMesh.points.index.length > 0) {
        //                 if (typeof perMesh.points.pressures[data.index] == "undefined") {
        //                     perMesh.points.pressures[data.index] = this.datToPerMesh(perMesh.lines.index, cm, i);
        //                 }
        //             }
        //             else {
        //                 delete perMesh.points;
        //             }
        //         if (perMesh.lines)
        //             if (perMesh.lines.index.length > 0) {
        //                 if (typeof perMesh.lines.pressures[data.index] == "undefined") {
        //                     perMesh.lines.pressures[data.index] = this.datToPerMesh(perMesh.lines.index, cm, i);
        //                 }
        //             }
        //             else {

        //                 delete perMesh.lines;
        //             }
        //         if (perMesh.triangles)
        //             if (perMesh.triangles.index.length > 0) {
        //                 if (typeof perMesh.triangles.pressures[data.index] == "undefined") {
        //                     perMesh.triangles.pressures[data.index] = this.datToPerMesh(perMesh.triangles.index, cm, i);
        //                 }
        //             }
        //             else {
        //                 delete perMesh.triangles;
        //             }

        //         if (perMesh.matrix.inp.rotate == false && perMesh.matrix.inp.transform == false && perMesh.matrix.inp.xyz1 == false && perMesh.matrix.inp.xyz2 == false) {
        //             perMesh.matrix = false;
        //         }
        //     }

    }
    datToPerMesh(indexes, cm, instanceName) {
        let list = {};
        for (let i in cm) {
            let perSite = cm[i];
            for (let j in perSite) {
                if (j == instanceName)
                    list[i] = [];
                for (let pi = 0; pi < indexes.length; pi += 3) {
                    list[i].push(
                        cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                        // cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                        // cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                    );
                }

            }
        }
        return list;
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    //  res
    analysisRES(data) {
        this.data.res.res = data.data.res;
        this.data.res.type = data.data.type;
        // this.resGetUVW();
        // if (typeof this.data.drawTree == "undefined") {
        //     this.query[data.index] = data;
        // }
        // else
        //     for (let RESi in this.data.res.res) {
        //         let cm = this.data.res.res[RESi];
        //         for (let i in this.data.drawTree) {
        //             if (i == "Dimension_2OR3") {
        //                 continue;
        //             }
        //             let perMesh = this.data.drawTree[i];

        //             if (perMesh.points)
        //                 if (perMesh.points.index.length > 0) {
        //                     if (typeof perMesh.points.pressures[RESi] == "undefined") {
        //                         perMesh.points.pressures[RESi] = this.resToPerMesh(perMesh.lines.index, cm);
        //                     }
        //                 }
        //                 else {
        //                     delete perMesh.points;
        //                 }
        //             if (perMesh.lines)
        //                 if (perMesh.lines.index.length > 0) {
        //                     if (typeof perMesh.lines.pressures[RESi] == "undefined") {
        //                         perMesh.lines.pressures[RESi] = this.resToPerMesh(perMesh.lines.index, cm);
        //                     }
        //                 }
        //                 else {

        //                     delete perMesh.lines;
        //                 }
        //             if (perMesh.triangles)
        //                 if (perMesh.triangles.index.length > 0) {
        //                     if (typeof perMesh.triangles.pressures[RESi] == "undefined") {
        //                         perMesh.triangles.pressures[RESi] = this.resToPerMesh(perMesh.triangles.index, cm);
        //                     }
        //                 }
        //                 else {
        //                     delete perMesh.triangles;
        //                     // if (perMesh.triangles) delete perMesh.triangles;
        //                     // if (perMesh.frameLines) delete perMesh.frameLines;
        //                     // if (perMesh.shellPoints) delete perMesh.shellPoints;
        //                 }
        //             // perMesh.namesOfCM = this.getPressuresName();
        //         }
        //     }
    }

    resToPerMesh(indexes, cm) {
        let list = {};
        for (let i in cm) {
            let perSite = cm[i];
            for (let j in perSite) {
                if (j != "type") {
                    if (typeof list[i + "." + j] == "undefined") {
                        list[i + "." + j] = [];
                    }
                    for (let pi = 0; pi < indexes.length; pi += 3) {
                        list[i + "." + j].push(
                            cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                            // cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                            // cm[i][j][indexes[pi]], cm[i][j][indexes[pi + 1]], cm[i][j][indexes[pi + 2]],
                        );
                    }
                }
            }
        }
        return list;
    }
    getPressuresName() {
        if (this.data.res.type == "res") {
            return this.resGetPressuresName();
        }
        else if (this.data.res.type == "dat") {
            return this.datGetPressuresName();
        }
    }
    datGetPressuresName() {
        let names = [];
        for (let i in this.data.res.res["1"]) {
            names.push(i);
        }
        return names;
    }
    resGetPressuresName() {
        let names = [];
        for (let i in this.data.res.res["1"]) {
            let perName = this.data.res.res["1"][i];
            // names.push(i);
            for (let j in perName) {
                if (j != "type")
                    names.push(i + "." + j);
            }
        }
        return names;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // return function 
    // send data to master
    returnAnalysisData(mesh = false) {
        if (mesh == 2) {
            this.callbacks.receive({
                type: "data",
                succeed: true,
                subject: "drawTree",
                data: this.data.drawTree,
                namesOfCM: this.getPressuresName()
            }, this.parent);
        }
        else if (mesh == 1) {

            this.callbacks.receive({
                type: "data",
                succeed: true,
                subject: "res",
                data: this.data.drawTree,
                namesOfCM: this.getPressuresName()
            }, this.parent);
            this.log({ type: "log", succed: true, subject: "100% 完成" });
        }
        this.hasMesh = 3;
    }




    log(msg) {
        if (typeof this.callbacks.log !== "undefined") {
            this.callbacks.log(msg, this.parent);
        } else {
            console.log(msg);
        }
    }

}
export { AnalysisPLTFR };