
import { appMain } from "./appMain";
class second extends appMain {
    constructor(value = false) {
        super(value);

    }

}


export { second };