
class rayPickup {

    constructor(inputJSON, parent) {
        this.octree = {};
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of one point pickup
    onepointInit(info) { }
    //cube for onepoint
    onepointInitCube() { }
    //sphere for onepoint
    onepointInitSphere() { }
    //update(xyz) for onepoint
    onepointUpdate(xyz) { }
    //show one point ,value = ture or false
    onepointShow(value = true) { }
    //send point id to parent by callback function 
    onepointInfo(id = false) { }
    autoFixPointSize() { }
}
export { rayPickup };