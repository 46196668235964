

class AnalysisMidas {

    constructor(parent, callbacks) {
        this.parent = parent;
        this.callbacks = callbacks;
        this.readyForAnlysis = 0;
        this.step = 0;

        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;

        this.data = {
            midas: false,
            drawTree: {
                Dimension_2OR3: "3",
                midas: {

                    // lines: {
                    //     index: [],
                    //     xyzs: [], res: {
                    //         1: {
                    //             index: { 1: 1, 3: 2, 6: 3 },
                    //             pressures: {
                    //                 "U.U1": []
                    //             }
                    //         }
                    //     }
                    // },
                    triangle: {
                        cmIndex: [],
                        index: [],
                        xyzs: [],

                    },
                    frameLine: {
                        index: [],
                        xyzs: []
                    },
                }
            },
            pickup: [],
            moved: false,
        };
        this.query = {};
    }
    async do(data) {
        if (data) {
            this.data.midas = data.data;

        }

        if (typeof this.parent.extra.midas != undefined) {
            for (let i in this.parent.extra.midas.fun) {
                let fun = this.parent.extra.midas.fun[i];
                fun(this.data.midas.source);
            }
        }
        this.calculateMesh();
        this.returnAnalysisData();

    }


    calculateMesh() {
        let nodes = this.data.midas.source.nodes;
        let elements = this.data.midas.source.elements;
        let sections = this.data.midas.source.sections;
        this.data.midas.struct = {
            elements: [],
            covers: [//截面线条需要在此实现
                // "1": {//node id
                //     points: [],////[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
                //     index: [],///每个三角形的三个点的index
                // }
            ],
            frameLine: [//不包含截面线条
                // "1": {
                //     points: [],////[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
                //     index: [],///每个三角形的三个点的index
                // }
            ]
        };
        let structElements = this.data.midas.struct.elements;
        let structCovers = this.data.midas.struct.covers;
        let structFrameLine = this.data.midas.struct.frameLine;

        for (let Ei in elements) {
            let perElement = elements[Ei];
            if (perElement.type == "BEAM") {

                let perElementNodes = perElement.iNs;
                let dir = false;
                let X = nodes[perElementNodes[0]][0] - nodes[perElementNodes[1]][0];
                let Y = nodes[perElementNodes[0]][1] - nodes[perElementNodes[1]][1];
                let Z = nodes[perElementNodes[0]][2] - nodes[perElementNodes[1]][2];
                let perSection = sections[perElement.iPRO];


                if (X == 0 && Z == 0) {
                    dir = "Y";
                }
                else if (Y == 0 && Z == 0) {
                    dir = "X";
                }
                if (X == 0 && Y == 0) {
                    dir = "Z";
                }
                // console.log(Ei)

                if (perSection) {
                    if (typeof structElements[Ei] == "undefined") {
                        structElements[Ei] = {
                            points: [],//[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
                            trianglesIndex: [],//每个三角形的三个点的index                  
                        };
                    }
                    if (Ei == 483) {
                        let abc = 1;
                    }
                    let PTLC = this.getStruct(Ei, dir, perElementNodes, perSection, nodes);
                    structElements[Ei] = PTLC.triangle;
                    for (let Ci in PTLC.cover)
                        structCovers[PTLC.cover[Ci].id] = PTLC.cover[Ci];
                    structFrameLine[Ei] = PTLC.frameLine;
                }
            }
        }
    }
    getStruct(Ei, dir, perElementNodes, perSection, nodes) {
        let PTLC = {
            triangle: {
                points: [],//[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
                index: [],//每个三角形的三个点的index 
                cmIndex: []
            },
            cover: {
                // id:{
                // // points: [],
                // // index: [],
                // // cm:[],
                // }
            },
            frameLine: {
                points: [],
                index: [],
            }
        };
        let cm = [];
        let CM = [];
        let points = [];
        let triangle = [];
        let frameLine = [];
        let pointIndexOfNode = [];
        let cmIndexOfStr = [];
        // let OPOLY = {
        //     from: [],
        //     to: []
        // }
        let OxyFrom, OxyTo;
        if (perSection.OPOLY.length == 1) {
            OxyFrom = perSection.OPOLY[0];
            OxyTo = perSection.OPOLY[0];
        }
        else if (perSection.OPOLY.length == 2) {
            OxyFrom = perSection.OPOLY[0];
            OxyTo = perSection.OPOLY[1];
        }


        let upZ = false;//from
        if (dir == "X") {
            for (let i = 1; i < OxyFrom.length - 1; i += 2) {
                if (upZ === false) {
                    upZ = OxyFrom[i];
                }
                if (upZ < OxyFrom[i]) {
                    upZ = OxyFrom[i];
                }
            }
        }
        if (dir == "Y") {
            for (let i = 0; i < OxyFrom.length - 1; i += 2) {
                if (upZ === false) {
                    upZ = OxyFrom[i];
                }
                if (upZ < OxyFrom[i]) {
                    upZ = OxyFrom[i];
                }
            }
        }
        if (upZ === false) {
            upZ = 0;
        }


        let upZ1 = false;//to
        if (dir == "X") {
            for (let i = 1; i < OxyTo.length - 1; i += 2) {
                if (upZ1 === false) {
                    upZ1 = OxyTo[i];
                }
                if (upZ1 < OxyTo[i]) {
                    upZ1 = OxyTo[i];
                }
            }
        }
        if (dir == "Y") {
            for (let i = 0; i < OxyTo.length - 1; i += 2) {
                if (upZ1 === false) {
                    upZ1 = OxyTo[i];
                }
                if (upZ1 < OxyTo[i]) {
                    upZ1 = OxyTo[i];
                }
            }
        }
        if (upZ1 === false) {
            upZ1 = 0;
        }


        if (this.data.moved === false) {
            this.data.moved = upZ > upZ1 ? upZ : upZ1;
        }
        let max = upZ > upZ1 ? upZ : upZ1;
        if (this.data.moved < max) {
            this.data.moved = max;
        }
        // nodes[perElementNodes[0]][2] += upZ/2;
        // nodes[perElementNodes[1]][2] += upZ1/2;

        let a = false, b = false, c = false, d = false;
        let aOld = false, bOld = false;
        if (Ei == 455) {
            let abc = 1;
        }

        for (let i = 0; i < OxyFrom.length - 1; i += 2) {
            if (a !== false) {
                c = b + 1;
                d = b + 2;
            }


            if (dir == "X") {
                points.push([0, OxyFrom[i], OxyFrom[i + 1] - upZ], [0, OxyTo[i + 0], OxyTo[i + 1] - upZ1]);//轴，是0，x,y
            }
            else if (dir == "Y") {
                points.push([OxyFrom[i], 0, OxyFrom[i + 1] - upZ], [OxyTo[i + 0], 0, OxyTo[i + 1] - upZ1]);//Y=x,0,y
            }
            else if (dir == "Z") {
                points.push([OxyFrom[i + 1], OxyFrom[i + 0], 0], [OxyTo[i + 1], OxyTo[i + 0], 0]);//z轴，=y,x,0
            }
            pointIndexOfNode.push(perElementNodes[0], perElementNodes[1]);

            if (a !== false && b !== false && c !== false && d !== false && a != c && b != d) {
                triangle.push(a, b, c, b, d, c);
                frameLine.push(a, c, b, d);
                cmIndexOfStr.push(0, 1, 0, 1, 1, 0);
                a = c;
                b = d;
            }

            if (a === false) {
                a = 0;
                b = 1;
                aOld = a;
                bOld = b;
            }
            frameLine.push(a, b);
        }
        frameLine.push(c, aOld, d, bOld);
        triangle.push(c, d, aOld, d, bOld, aOld);
        cmIndexOfStr.push(0, 1, 0, 1, 1, 0);


        if (upZ === false) {
            upZ = 0;
        }


        a = false;
        b = false;
        c = false;
        d = false;
        if (perSection.IPOLY.length > 0) {
            // a = false, b = false, c = false, d = false;
            // let IPOLY = {
            //     from: [],
            //     to: []
            // };
            let IxyFrom, IxyTo;
            if (perSection.IPOLY.length == 1) {
                IxyFrom = perSection.IPOLY[0];
                IxyTo = perSection.IPOLY[0];
            }
            else if (perSection.IPOLY.length == 2) {
                IxyFrom = perSection.IPOLY[0];
                IxyTo = perSection.IPOLY[1];
            }
            let temp = points.length;
            if (IxyFrom) {
                for (let i = 0; i < IxyFrom.length - 1; i += 2) {
                    if (a !== false) {
                        c = b + 1;
                        d = b + 2;
                    }
                    if (dir == "X") {
                        points.push([0, IxyFrom[i], IxyFrom[i + 1] - upZ], [0, IxyTo[i + 0], IxyTo[i + 1] - upZ1]);
                    }
                    else if (dir == "Y") {
                        points.push([IxyFrom[i], 0, IxyFrom[i + 1] - upZ], [IxyTo[i + 0], 0, IxyTo[i + 1] - upZ1]);
                    }
                    else if (dir == "Z") {
                        points.push([IxyFrom[i + 1], IxyFrom[i + 0], 0], [IxyTo[i + 1], IxyTo[i + 0], 0]);
                    }
                    pointIndexOfNode.push(perElementNodes[0], perElementNodes[1]);

                    if (a && b && c && d && a != c && b != d) {
                        triangle.push(a, b, c, b, d, c);
                        frameLine.push(a, c, b, d);
                        cmIndexOfStr.push(0, 1, 0, 1, 1, 0);
                        a = c;
                        b = d;
                    }

                    if (a === false) {
                        a = temp;
                        b = temp + 1;
                        aOld = a;
                        bOld = b;
                    }
                    frameLine.push(a, b);
                }
            }
            frameLine.push(c, aOld, d, bOld);
            triangle.push(c, d, aOld, d, bOld, aOld);
            cmIndexOfStr.push(0, 1, 0, 1, 1, 0);
        }


        let covers = this.getCovers(Ei, dir, perElementNodes, perSection);

        PTLC.triangle = {
            points: points,
            index: triangle,
            cmIndex: pointIndexOfNode,
            pointIndexOfNode: pointIndexOfNode,
            cmIndexOfStr: cmIndexOfStr,
        };
        PTLC.frameLine = {
            points: points,
            index: frameLine,
            pointIndexOfNode: pointIndexOfNode,
        };
        // for (let i in covers) {
        //     PTLC.cover[i] = covers[i];
        // }
        PTLC.cover = covers;
        return PTLC;
    }
    getCovers(Ei, dir, perElementNodes, perSection) {

        let cmIndexOfStr = [];

        let OxyFrom, OxyTo;
        if (perSection.OPOLY.length == 1) {
            OxyFrom = perSection.OPOLY[0];
            OxyTo = perSection.OPOLY[0];
        }
        else if (perSection.OPOLY.length == 2) {
            OxyFrom = perSection.OPOLY[0];
            OxyTo = perSection.OPOLY[1];
        }

        let IxyFrom, IxyTo;
        if (perSection.IPOLY.length == 1) {
            IxyFrom = perSection.IPOLY[0];
            IxyTo = perSection.IPOLY[0];
        }
        else if (perSection.IPOLY.length == 2) {
            IxyFrom = perSection.IPOLY[0];
            IxyTo = perSection.IPOLY[1];
        }


        let upZ = false;//from
        if (dir == "X") {
            for (let i = 1; i < OxyFrom.length - 1; i += 2) {
                if (upZ === false) {
                    upZ = OxyFrom[i];
                }
                if (upZ < OxyFrom[i]) {
                    upZ = OxyFrom[i];
                }
            }
        }
        if (dir == "Y") {
            for (let i = 0; i < OxyFrom.length - 1; i += 2) {
                if (upZ === false) {
                    upZ = OxyFrom[i];
                }
                if (upZ < OxyFrom[i]) {
                    upZ = OxyFrom[i];
                }
            }
        }
        if (upZ === false) {
            upZ = 0;
        }


        let upZ1 = false;//to
        if (dir == "X") {
            for (let i = 1; i < OxyTo.length - 1; i += 2) {
                if (upZ1 === false) {
                    upZ1 = OxyTo[i];
                }
                if (upZ1 < OxyTo[i]) {
                    upZ1 = OxyTo[i];
                }
            }
        }
        if (dir == "Y") {
            for (let i = 0; i < OxyTo.length - 1; i += 2) {
                if (upZ1 === false) {
                    upZ1 = OxyTo[i];
                }
                if (upZ1 < OxyTo[i]) {
                    upZ1 = OxyTo[i];
                }
            }
        }
        if (upZ1 === false) {
            upZ1 = 0;
        }


        let OFrom = [];
        let OTo = [];
        let IFrom = [];
        let ITo = [];
        for (let i = 0; i < OxyFrom.length; i += 2) {
            if (dir == "X") {
                OFrom.push([0, OxyFrom[i], OxyFrom[i + 1] - upZ]);
            }
            else if (dir == "Y") {
                OFrom.push([OxyFrom[i], 0, OxyFrom[i + 1] - upZ]);
            }
            else if (dir == "Z") {
                OFrom.push([OxyFrom[i + 1], OxyFrom[i + 0], 0]);
            }
        }

        for (let i = 0; i < OxyTo.length; i += 2) {
            if (dir == "X") {
                OTo.push([0, OxyTo[i + 0], OxyTo[i + 1] - upZ1]);
            }
            else if (dir == "Y") {
                OTo.push([OxyTo[i + 0], 0, OxyTo[i + 1] - upZ1]);
            }
            else if (dir == "Z") {
                OTo.push([OxyTo[i + 1], OxyTo[i + 0], 0]);
            }
        }
        if (IxyFrom) {
            for (let i = 0; i < IxyFrom.length; i += 2) {
                if (dir == "X") {
                    IFrom.push([0, IxyFrom[i], IxyFrom[i + 1] - upZ]);
                }
                else if (dir == "Y") {
                    IFrom.push([IxyFrom[i], 0, IxyFrom[i + 1] - upZ]);
                }
                else if (dir == "Z") {
                    IFrom.push([IxyFrom[i + 1], IxyFrom[i + 0], 0]);
                }
            }

            for (let i = 0; i < IxyTo.length; i += 2) {
                if (dir == "X") {
                    ITo.push([0, IxyTo[i + 0], IxyTo[i + 1] - upZ1]);
                }
                else if (dir == "Y") {
                    ITo.push([IxyTo[i + 0], 0, IxyTo[i + 1] - upZ1]);
                }
                else if (dir == "Z") {
                    ITo.push([IxyTo[i + 1], IxyTo[i + 0], 0]);
                }
            }
        }

        let covers = {};
        covers[perElementNodes[0]] = {
            Eid: Ei,
            id: perElementNodes[0],
            cmIndex: perElementNodes[0],
            outside: OFrom,
            inside: [IFrom]
        };

        covers[perElementNodes[1]] = {
            Eid: Ei,
            id: perElementNodes[1],
            cmIndex: perElementNodes[1],
            outside: OTo,
            inside: [ITo]
        };

        return covers;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // return function 
    // send data to master
    returnAnalysisData() {
        this.callbacks.receive({
            type: "midas",
            succeed: true,
            subject: "midas",
            data: this.data,
            // namesOfCM: this.getPressuresName()
        }, this.parent);
    }




    log(msg) {
        if (typeof this.callbacks.log !== "undefined") {
            this.callbacks.log(msg, this.parent);
        } else {
            console.log(msg);
        }
    }

}
export { AnalysisMidas };