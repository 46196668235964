import {
    Group, Vector3, Raycaster, Vector2, Clock, Mesh, BoxBufferGeometry, MeshBasicMaterial, PerspectiveCamera, BoxGeometry,
    Scene, GridHelper, Box3, DoubleSide, PlaneGeometry, PointLight, AmbientLight, WebGLRenderer, Color, AxesHelper, MOUSE, TOUCH,
} from "three";
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { ClippingThree } from "./ClippingThree";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { SSAARenderPass } from "three/examples/jsm/postprocessing/SSAARenderPass.js";
// import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
// import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
// import { fontLayer } from "../font/fontlayer";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";


// var that;

/**
 * value={
 *  DIV:"asdf",
 *  ctl:orbit,traceball
 *  log:function(){},
 *  fun:{
 *      beforeInit:functoin(){},
 *      afterInit:function(){},
 *      init:function(){},
 * }
 * }
 * 
 */
class appMain {
    constructor(value = false) {
        this.GEOs = {};
        this.CMs = [];
        this.oneVector=new Vector3();
        this.viewHelper = {
            axis: false,
            lut: false,
            font: false,
        };
        this.debug = value.debug || false;
        // this.DIV = "container";
        this.worldBox = new Group();
        this.worldBox.name = "worldBox";
        this.log2ohter = false; //输出信息到其他callback
        this.needsUpdate = true;
        if (typeof value.setting != "undefined" && typeof value.setting.realTimeRender != "undefined" && value.setting.realTimeRender === false) {
            this.needsUpdate = false;
        }
        this.control = {
            flagCtl: value.ctl || "orbit",
            ctl: {},
            orbit: false,
            transform: false,
            tracball: false,
            dragging: false,
        };
        this.pickup = {

        };
        this.setting = {
            inputValue: value,
            worldCenter: new Vector3(0, 0, 0),
            cameraLookAt: new Vector3(0, 0, 0),
            maxOfWorldbox: false,
        };
        this.three = {
            // scene: {},
            // perpCamera: {},
            // renderer: {},
            mouse: new Vector2(),
            clock: new Clock(),
            raycaster: new Raycaster(),
            // needsUpdate: false,
            lights: {},
            listener: {},
            container: null,
            viewpoint_width: 300,
            viewpoint_height: 200,
            viewpoint_px: 0,
            viewpoint_py: 0,
            composer: false,
            effectFXAA: false,
            outlinePass: false,
        };
        this.settingOfIntersect = {
            boxOfCameraStep: 0.13, //摄像机步进距离
            boxWHD: 0.02,
            boxOfCamera: new Mesh( //box
                new BoxBufferGeometry(0.2, 0.2, 0.2),
                new MeshBasicMaterial({ transparent: true, opacity: 0 })
            ),
            box3OfCamera: {},//box3
            box3OfWorld: {},//box3
        };
        this.settingOfIntersect.boxOfCamera.visible = false;

        this.settingOfCallback = {};
        if (typeof value.fun == "object") {
            if (typeof value.fun.beforeInit == "function") {
                this.settingOfCallback.beforeInit = value.fun.beforeInit;
            }
            if (typeof value.fun.afterInit == "function") {
                this.settingOfCallback.afterInit = value.fun.afterInit;
            }
            if (typeof value.fun.init == "function") {
                this.settingOfCallback.init = value.fun.init;
            }
            if (typeof value.fun.onMouseClick == "function") {
                this.settingOfCallback.onMouseClick = value.fun.onMouseClick;
            }
            if (typeof value.fun.onMouseMove == "function") {
                this.settingOfCallback.onMouseMove = value.fun.onMouseMove;
            }
        }

        if (typeof value.log == "function") {
            this.log2ohter = value.log;
        }
        if (typeof value.ctl == "function") {
            this.control.flagCtl = value.ctl;
        }
        // if (typeof value.DIV != "undefined") {
        //     this.DIV = value.DIV;
        // }
        this._init();
        return this;
    }
    clean() {
        if (this.viewHelper.lut) {
            this.viewHelper.lut.clean();
        }
        this.reinitWorldBox();

        for (let i of this.CMs) {
            i.clean();
        }
        if (typeof this.CMs !== "undefined") {
            this.CMs = [];
        }
        if (typeof this.GEOs !== "undefined") {
            this.GEOs = {};
        }
        if (typeof this.LUT !== "undefined") {
            this.LUT.clean();
        }
        this.render();
        this.log("重置场景。");
    }
    reinitWorldBox() {
        this.scene.remove(this.scene.getObjectByName("worldBox"));
        this.worldBox = [];
        this.worldBox = new Group();
        this.worldBox.name = "worldBox";
        this.scene.add(this.worldBox);
    }
    _init() {
        this.log("可视化场景开始初始化。");
        let that = this;
        // this.layerFont = new fontLayer(this);
        this.beforeInit();
        this.initScene();
        this.initCamera();
        this.initLights();
        this.initControls();
        this.initMisc();
        this.init_UI();
        this.PP();
        this.init();
        this.afterInit();
        // this.animate(that);
        function animate() {
            requestAnimationFrame(animate);
            if (that.control.ctl)
                that.control.ctl.update();

            var delta = that.three.clock.getDelta();
            if (that.viewHelper.animating === true) {
                that.viewHelper.update(delta);
            }
            if (that.viewHelper)
                if (that.viewHelper.ready)
                    that.viewHelper.render(that.viewHelper.renderer);
            if (that.needsUpdate) {
                that.render();
            }
        }
        animate();

    }
    beforeInit() {
        if (this.settingOfCallback.beforeInit) {
            this.settingOfCallback.beforeInit(this);
        }
    }
    init() {
        if (this.settingOfCallback.init) {
            this.settingOfCallback.init(this);
        }
        else {
            // const geometry = new BoxGeometry(10, 10, 10, 2, 2, 2);
            // const material = new MeshBasicMaterial({ color: 0xff8800 });
            // const box = new Mesh(geometry, material);
            // this.worldBox.add(box);

            // const helper = new VertexNormalsHelper(box, 2, 0x00ff00, 1);
            // this.worldBox.add(helper);

            // const axesHelper = new AxesHelper(50);
            // this.scene.add(axesHelper);
        }
    }
    afterInit() {

        if (this.settingOfCallback.afterInit) {
            this.settingOfCallback.afterInit(this);
        }
        // this.setENVLast();
        this.log("可视化场景初始化完成。");
    }
    initScene() {
        if (this.setting.inputValue.containerType == "object")
            this.three.container = this.setting.inputValue.DIV;
        else
            this.three.container = document.getElementById(this.setting.inputValue.DIV);

        this.three.viewpoint_width = this.three.container.offsetWidth;
        this.three.viewpoint_height = this.three.container.offsetHeight;
        this.three.viewpoint_px = this.three.container.offsetLeft;
        this.three.viewpoint_py = this.three.container.offsetTop;

        this.scene = new Scene();
        this.scene.add(this.worldBox);
        // this.scene.background = new Color(0x00ff00);
        ////////////////////////////////////////////////////////

        this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.autoClear = false;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.three.viewpoint_width, this.three.viewpoint_height);
        this.three.container.appendChild(this.renderer.domElement);

    }
    initCamera() {
        this.perpCamera = new PerspectiveCamera(
            60,
            this.viewpoint_width / this.viewpoint_height,
            0.1,
            1000000
        );
        this.perpCamera.position.set(0, 10, 20);
        this.perpCamera.lookAt(0, 0, 0);
        this.scene.add(this.perpCamera);
    }


    initLights() {
        const light = new AmbientLight(0x808080); // soft white light
        this.scene.add(light);
        this.three.lights["AmbientLight"] = light;
        const pointLight = new PointLight(0xffffff, 0.31);
        this.perpCamera.add(pointLight);
        this.perpCamera.add(this.settingOfIntersect.boxOfCamera);
        this.three.lights["cameraLight"] = pointLight;
    }

    initControls() {
        let that = this;

        if (this.control.flagCtl == "orbit") {
            this.control.ctl = new OrbitControls(this.perpCamera, this.renderer.domElement);
        }
        else if (this.control.flagCtl == "traceball") {
            this.control.ctl = new TrackballControls(this.perpCamera, this.renderer.domElement);
            this.control.ctl.rotateSpeed = 4.0;
            this.control.ctl.zoomSpeed = 0.72;
            this.control.ctl.panSpeed = 0.2;
            this.control.ctl.dynamicDampingFactor = .35;
        }
        else {
            this.control.ctl = new OrbitControls(this.perpCamera, this.renderer.domElement);
        }
        this.control.ctl.addEventListener("change", function () {
            if (that.needsUpdate == false)
                that.render();
        });
        window.addEventListener("resize", function (event) {
            that.onWindowResize(event, that);
        });
        window.addEventListener("keydown", function (event) {
            that.controlSwitch(event, that);
        });
        this.three.container.addEventListener("click", function (event) {
            that.onMouseClick(event, that);
        });
        this.three.container.addEventListener("mousemove", function (event) {
            that.onMouseMove(event, that);
        });
    }
    initMisc() { }
    PP() {
        this.composer = new EffectComposer(this.renderer);
        let ssaaRenderPassP = new SSAARenderPass(this.scene, this.perpCamera);
        this.composer.addPass(ssaaRenderPassP);
    }





    onWindowResize(event, that) {
        // that.orbit.handleResize();
        // console.log('画布更改', left, top, activeWidth, activeHeight)
        that.three.viewpoint_width = that.three.container.offsetWidth;
        that.three.viewpoint_height = that.three.container.offsetHeight;
        that.three.viewpoint_px = that.three.container.offsetLeft;
        that.three.viewpoint_py = that.three.container.offsetTop;
        const width = that.three.viewpoint_width;
        const height = that.three.viewpoint_height;
        that.perpCamera.aspect = width / height;
        that.perpCamera.updateProjectionMatrix();
        that.renderer.setSize(width, height);
        that.composer.setSize(width, height);
        that.render();
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // null function and rewrite function
    onClick() { }
    init_UI() { }
    onMouseClick(event, that) {
        var x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        that.three.mouse.x = ((x - that.three.viewpoint_px) / that.three.viewpoint_width) * 2 - 1;
        that.three.mouse.y = - ((y - that.three.viewpoint_py) / that.three.viewpoint_height) * 2 + 1;
        that.three.raycaster.setFromCamera(that.three.mouse, that.perpCamera);

        if (that.settingOfCallback.onMouseClick) {
            that.settingOfCallback.onMouseClick(that, that.three.raycaster, { threemouse: that.three.mouse, eventxy: { x: x, y: y } });
        }
    }
    onMouseMove(event, that) {
        var x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        that.three.mouse.x = ((x - that.three.viewpoint_px) / that.three.viewpoint_width) * 2 - 1;
        that.three.mouse.y = - ((y - that.three.viewpoint_py) / that.three.viewpoint_height) * 2 + 1;
        that.three.raycaster.setFromCamera(that.three.mouse, that.perpCamera);
        if (that.settingOfCallback.onMouseMove) {
            that.settingOfCallback.onMouseMove(that, that.three.raycaster, { threemouse: that.three.mouse, eventxy: { x: x, y: y } });
        }
    }
    controlSwitch(event, that) { }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //render about 
    render() {
        //change near of camera
        if (this.worldBox) {
            let disBox = this.getMaxOfWolrdBox();
            let disCam = this.getDistanceFromCameraToZero();
            if (disCam - disBox * 0.051 <= disBox) {
                if (disCam > 100) this.perpCamera.near = 1.01;
                else if (disCam > 50) this.perpCamera.near = 0.1;
                else if (disCam > 10) this.perpCamera.near = 0.0051;
                else this.perpCamera.near = 0.01;
            } else {
                this.perpCamera.near = this.getDistanceFromCameraToZero() / 20;
            }
        }


        if (typeof this.debug == "undefined" || this.debug == false)
            if (typeof this.settingOfIntersect.boxOfCamera != "undefined" && typeof this.settingOfIntersect.box3OfWorld != "undefined") {
                this.settingOfIntersect.box3OfCamera = new Box3();
                this.settingOfIntersect.box3OfCamera.expandByObject(this.settingOfIntersect.boxOfCamera);
                let intersect = this.settingOfIntersect.box3OfWorld.intersectsBox(this.settingOfIntersect.box3OfCamera);
                let inside = this.settingOfIntersect.box3OfWorld.containsBox(this.settingOfIntersect.box3OfCamera);
                let x = this.perpCamera.position.x;
                let y = this.perpCamera.position.y;
                let z = this.perpCamera.position.z;
                let doX = false,
                    doY = false,
                    doZ = false;
                if (intersect) {
                    // console.log('intersect');
                    if (x > this.settingOfIntersect.box3OfWorld.min.x && x < this.settingOfIntersect.box3OfWorld.max.x && y > this.settingOfIntersect.box3OfWorld.min.y && y < this.settingOfIntersect.box3OfWorld.max.y
                    ) {
                        if (z <= this.settingOfIntersect.box3OfWorld.min.z - this.settingOfIntersect.boxOfCameraStep) {
                            this.perpCamera.position.z =
                                this.settingOfIntersect.box3OfWorld.min.z - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(1);
                        } else if (z > this.settingOfIntersect.box3OfWorld.max.z) {
                            this.perpCamera.position.z =
                                this.settingOfIntersect.box3OfWorld.max.z + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(2);
                        }
                    } else if (
                        x > this.settingOfIntersect.box3OfWorld.min.x && x < this.settingOfIntersect.box3OfWorld.max.x && z > this.settingOfIntersect.box3OfWorld.min.z && z < this.settingOfIntersect.box3OfWorld.max.z
                    ) {
                        if (y < this.settingOfIntersect.box3OfWorld.min.y) {
                            this.perpCamera.position.y =
                                this.settingOfIntersect.box3OfWorld.min.y - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(3);
                        } else if (y > this.settingOfIntersect.box3OfWorld.max.y) {
                            this.perpCamera.position.y =
                                this.settingOfIntersect.box3OfWorld.max.y + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(4);
                        }
                    } else if (z > this.settingOfIntersect.box3OfWorld.min.z && z < this.settingOfIntersect.box3OfWorld.max.z && y > this.settingOfIntersect.box3OfWorld.min.y && y < this.settingOfIntersect.box3OfWorld.max.y
                    ) {
                        if (x < this.settingOfIntersect.box3OfWorld.min.x) {
                            this.perpCamera.position.x =
                                this.settingOfIntersect.box3OfWorld.min.x - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(5);
                        } else if (x > this.settingOfIntersect.box3OfWorld.max.x) {
                            this.perpCamera.position.x =
                                this.settingOfIntersect.box3OfWorld.max.x + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(6);
                        }
                    }
                }
                if (inside) {
                    // console.log('inside');
                    this.perpCamera.getWorldDirection(this.setting.cameraLookAt);
                    let cx = Math.abs(this.setting.cameraLookAt.x);
                    let cy = Math.abs(this.setting.cameraLookAt.y);
                    let cz = Math.abs(this.setting.cameraLookAt.z);
                    cx > cy ? (doX = true) : cy > cz ? (doY = true) : (doZ = true);
                    let xUp = Math.abs(this.settingOfIntersect.box3OfWorld.max.x - x);
                    let xDown = Math.abs(this.settingOfIntersect.box3OfWorld.min.x - x);

                    if (doX)
                        if (xUp < xDown) {
                            // if (x > this.settingOfIntersect.box3OfWorld.min.x && x < this.settingOfIntersect.box3OfWorld.max.x)
                            this.perpCamera.position.x =
                                this.settingOfIntersect.box3OfWorld.max.x + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(7);
                            doX = true;
                        } else {
                            this.perpCamera.position.x =
                                this.settingOfIntersect.box3OfWorld.min.x - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(8);
                            doX = true;
                        }
                    let yUp = Math.abs(this.settingOfIntersect.box3OfWorld.max.y - y);
                    let yDown = Math.abs(this.settingOfIntersect.box3OfWorld.min.y - y);
                    if (doY)
                        if (yUp < yDown) {
                            // if (y > this.settingOfIntersect.box3OfWorld.min.y && y < this.settingOfIntersect.box3OfWorld.max.y && doX == false)
                            this.perpCamera.position.y =
                                this.settingOfIntersect.box3OfWorld.max.y - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(9);
                            doY = true;
                        } else {
                            this.perpCamera.position.y =
                                this.settingOfIntersect.box3OfWorld.min.y + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(10);
                            doY = true;
                        }

                    let zUp = Math.abs(this.settingOfIntersect.box3OfWorld.max.z - z);
                    let zDown = Math.abs(this.settingOfIntersect.box3OfWorld.min.z - z);
                    if (doZ)
                        if (zUp < zDown) {
                            // if (z > this.settingOfIntersect.box3OfWorld.min.z && z < this.settingOfIntersect.box3OfWorld.max.z && doX == false && doY == false)
                            this.perpCamera.position.z =
                                this.settingOfIntersect.box3OfWorld.max.z + this.settingOfIntersect.boxOfCameraStep;
                            // console.log(11);
                        } else {
                            this.perpCamera.position.z =
                                this.settingOfIntersect.box3OfWorld.min.z - this.settingOfIntersect.boxOfCameraStep;
                            // console.log(12);
                        }
                }
            }


        this.renderer.render(this.scene, this.perpCamera);
        this.composer.render();

        this.oldPositionOfCamera = {
            x: this.perpCamera.position.x,
            y: this.perpCamera.position.y,
            z: this.perpCamera.position.z,
        };

    }
    renderOnly() {
        this.renderer.render(this.scene, this.perpCamera);
        this.composer.render();
    }
    checkRender() {
        if (this.needsUpdate === false) {
            this.render();
        }
    }
    //get max value of world box for render collision
    // if the CM don't in the center of world , the collision have some problem 
    getMaxOfWolrdBox() {
        if (this.worldBox) {
            this.settingOfIntersect.box3OfWorld = new Box3();
            this.settingOfIntersect.box3OfWorld.expandByObject(this.worldBox);
            if (this.settingOfIntersect.box3OfWorld) {
                let box3 = this.settingOfIntersect.box3OfWorld;
                let x = box3.max.x - box3.min.x;
                let y = box3.max.y - box3.min.y;
                let z = box3.max.z - box3.min.z;
                let max = x > y ? x : y;
                max = max > z ? max : z;
                return max;
            }
        } else {
            return false;
        }
    }
    //get the camera distance to zero for render collision
    getDistanceFromCameraToZero() {
        return this.setting.worldCenter.distanceTo(this.perpCamera.position);
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  env
    gotoOrignViewPoint() {
        this.setENVLast();
    }

    maxOfWorldbox() {
        var box3 = new Box3();
        // 计算层级模型group的包围盒
        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
        box3.expandByObject(this.worldBox);
        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
        let cx = box3.max.x - box3.min.x;
        let cy = box3.max.y - box3.min.y;
        let cz = box3.max.z - box3.min.z;
        let centerMax = cx > cy ? cx : (cy > cz ? cy : cz);
        this.setting.maxOfWorldbox = centerMax;
        return centerMax;
    }
    //last set the camera position ...
    setENVLast() {
        let cameraPosition = [];
        let cameraLookat = [];
        let cameraMatrix = [];
        let ctlTarget = [];
        let max;
        let maxRate = 1.8;
        this.control.ctl.reset();

        this.perpCamera.rotation._x = 0;
        this.perpCamera.rotation._y = 0;
        this.perpCamera.rotation._z = 0;
        if (typeof this.setting.inputValue.setting != "undefined" && typeof this.setting.inputValue.setting.cameraAuto != "undefined" && this.setting.inputValue.setting.cameraAuto === false) {

            if (typeof this.setting.cameraMatrix != "undefined" && this.setting.cameraMatrix.length == 16) {
                cameraMatrix = this.setting.cameraMatrix;
                if (typeof this.setting.ctlTarget != "undefined" && typeof this.setting.ctlTarget.length == 3) {
                    ctlTarget = this.setting.ctlTarget;
                }
            }
            else {
                if (typeof this.setting.inputValue.setting.cameraPosition != "undefined" && this.setting.inputValue.setting.cameraPosition.length == 3) {
                    cameraPosition = this.setting.inputValue.setting.cameraPosition;
                }
                else {
                    max = this.maxOfWorldbox();
                    cameraPosition = [0, 0, max * maxRate];
                }
                if (typeof this.setting.inputValue.setting.cameraLookat != "undefined" && this.setting.inputValue.setting.cameraLookat.length == 3) {
                    cameraLookat = this.setting.inputValue.setting.cameraLookat;
                }
                else {
                    max = this.maxOfWorldbox();
                    cameraLookat = [0, 0, 0];
                }
            }
        }
        else {
            max = this.maxOfWorldbox();
            cameraPosition = [0, 0, max * maxRate];
            cameraLookat = [0, 0, 0];
            // ctlTarget = [0, 0, 0];
        }

        if (cameraMatrix.length == 16) {
            this.perpCamera.matrix.elements = cameraMatrix;
            this.perpCamera.updateMatrix();
        }
        else {
            this.perpCamera.position.set(cameraPosition[0], cameraPosition[1], cameraPosition[2]);
            this.perpCamera.lookAt(cameraLookat[0], cameraLookat[1], cameraLookat[2]);
            this.perpCamera.far = max * 1000;
        }

        if (ctlTarget.length == 3) {
            this.control.ctl.target.x = ctlTarget[0];
            this.control.ctl.target.y = ctlTarget[1];
            this.control.ctl.target.z = ctlTarget[2];
        }

        this.render();

    }

    setDimension(dimension) {
        console.log(dimension);

        if (dimension == "2") {
            this.control.ctl.dispose();

            this.control.ctl = new OrbitControls(this.perpCamera, this.renderer.domElement);

            this.control.ctl.addEventListener("change", function () {
                if (this.needsUpdate == false)
                    this.render();
            });

            this.control.ctl.keys = {
                LEFT: "ArrowLeft", //left arrow
                UP: "ArrowUp", // up arrow
                RIGHT: "ArrowRight", // right arrow
                BOTTOM: "ArrowDown" // down arrow
            };
            this.control.ctl.mouseButtons = {
                LEFT: MOUSE.PAN,
                MIDDLE: MOUSE.DOLLY,
                RIGHT: MOUSE.PAN
            };
            this.control.ctl.touches = {
                ONE: TOUCH.PAN,
                TWO: TOUCH.PAN
            };
            this.control.ctl.maxAzimuthAngle = 0;
            this.control.ctl.minAzimuthAngle = 0;

            this.control.ctl.maxPolarAngle = 0.5 * Math.PI;
            this.control.ctl.minPolarAngle = 0.5 * Math.PI;
        }

    }

    initClipping(obj = false) {
        let clippingOfValues = {
            enable: true,
            global: false,
            type: "free",//one ,three ,free
            clippingObject: obj,
            parent: this,
        };
        this.clipping = new ClippingThree(clippingOfValues);
    }
    setClipping(value = false) {
        // if (value === true)
        {
            if (typeof this.clipping == "undefined") {
                this.initClipping();
            }
            this.clipping.setEnable(value);
        }
    }
    getClipping() {
        if (typeof this.clipping == "undefined") {
            return false;
        }
        return this.clipping.enable;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //

    setViewAxis(axis = false) {
        let object;
        this.perpCamera.up.x = 0;
        this.perpCamera.up.y = 1;
        this.perpCamera.up.z = 0;
        switch (axis) {
        case "X":
            object = "posX";

            break;

        case "Y":
            object = "posY";

            break;

        case "Z":
            object = "posZ";
            break;

        case "-X":
            object = "negX";
            break;

        case "-Y":
            object = "negY";
            break;

        case "-Z":
            object = "negZ";
            break;
        }
        if (this.viewHelper)
            if (axis) {
                this.viewHelper.setViewAxis(object, new Vector3(0, 0, 0));
            }
    }
    log() {
        let msg = "";
        for (let perMsg of arguments) {
            msg += perMsg;
        }
        if (this.log2ohter) {
            this.log2ohter(msg);
        } else {
            console.log(msg);
        }
    }


}

export { appMain };
