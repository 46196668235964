import { Octree, Bounds3 } from "./octree";
// import {
//     SphereGeometry, Ray, Vector3, Vector2,
// }
//     from "three";
class OctreeEntry {
    constructor(points,) {
        this.points = points;
        this.root = null;
        this.init(points);
    }

    init(points) {
        this.root = this.createRootNode(points);
        for (let i in points) {
            this.root.addLeaf(i, points, 0);
        }

    }
    createRootNode(points) {
        // Edge case deserves empty region:
        if (points.length === 0) {
            var empty = new Bounds3();
            return new Octree(empty);
        }

        // Otherwise let's figure out how big should be the root region
        var minX = Number.POSITIVE_INFINITY;
        var minY = Number.POSITIVE_INFINITY;
        var minZ = Number.POSITIVE_INFINITY;
        var maxX = Number.NEGATIVE_INFINITY;
        var maxY = Number.NEGATIVE_INFINITY;
        var maxZ = Number.NEGATIVE_INFINITY;
        for (let i in points) {
            let perPoint = points[i];
            let x = perPoint[0];
            let y = perPoint[1];
            let z = perPoint[2];

            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
            if (z < minZ) minZ = z;
            if (z > maxZ) maxZ = z;
        }

        // Make bounds square:
        var side = Math.max(Math.max(maxX - minX, maxY - minY), maxZ - minZ);
        // since we need to have both sides inside the area, let's artificially
        // grow the root region:
        side += 2;
        minX -= 1;
        minY -= 1;
        minZ -= 1;
        var half = side / 2;

        var bounds = new Bounds3(minX + half, minY + half, minZ + half, half);
        return new Octree(bounds);
    }
    getRoot() {
        return this.root;
    }

    //精度问题，相交点，如果null ，再匹配box3
    intersectRay(origin, direction) {
        if (this.root === null) {
            return false;
        }
        let list = this.root.query(this.points, origin, direction);
        return list;
    }
    ///////////////////////////////////////////////////////////////////
    // 
    postPoint() {

    }

    getFrameLines() {
        if (this.root) {
            return this.root.getFrameLines();
        }
    }
    getBoxMaxHalf() {
        if (this.root) {
            return this.root.bounds.half;
        }
        else
            return false;
    }
}
export { OctreeEntry };