import { Ray } from "three";
import { BufferAttribute, BufferGeometry, LineSegments, LineBasicMaterial, Mesh, MeshBasicMaterial, SphereGeometry, Line, Group, Sphere, Vector3 } from "three";
import { OctreeEntry } from "./cctreeEntry";

class OctreeDraw {
    constructor(parent, points, matrix = false, R, color, percentOfHalfBoxForSphere = 0.003) {
        this.parent = parent;
        this.points = points;
        this.matrix = matrix;
        this.percentOfHalfBoxForSphere = percentOfHalfBoxForSphere;
        this.selectPoint = false;
        this.radius = R || false;
        this.color = color || 0xffffff;
        this.lines = false;//frame line
        this.init(points);
    }
    init(points) {
        this.octreeEntry = new OctreeEntry(points);
        // this.drawFrameLines();
    }
    reInit(parent, points, matrix = false, R, color, percentOfHalfBoxForSphere = 0.003) {
        if (this.selectPoint) {
            this.parent.parent.scene.remove(this.selectPoint);
        }
        this.parent = parent;
        this.points = points;
        this.matrix = matrix;
        this.percentOfHalfBoxForSphere = percentOfHalfBoxForSphere;
        this.selectPoint = false;
        this.radius = R || false;
        this.color = color || 0xffffff;
        // this.lines = false;//frame line
        this.init(points);

    }
    clean() {
        if (this.selectPoint) {
            this.parent.parent.scene.remove(this.selectPoint);
        }
    }
    drawFrameLines() {
        if (this.octreeEntry) {
            if (this.lines) {
                this.parent.parent.scene.remove(this.lines);
            }
            let vertices = new Float32Array(this.octreeEntry.getFrameLines());
            let geometry = new BufferGeometry();
            geometry.setAttribute("position", new BufferAttribute(vertices, 3));
            this.lines = new LineSegments(geometry, new LineBasicMaterial());
            if (this.matrix)
                for (let i of this.matrix) {
                    // let matrix = i.invert();
                    this.lines.applyMatrix4(i);
                }
            this.parent.scene.add(this.lines);
        }
    }
    eraseFrameLines() {
        if (this.lines) {
            this.parent.parent.scene.remove(this.lines);
        }
    }
    pickup(originOld, directionOld, raycaster) {
        let origin = originOld.clone();
        let direction = directionOld.clone();

        if (this.matrix)
            for (let i of this.matrix) {
                let matrix = i.clone();
                matrix.invert();
                origin.applyMatrix4(matrix);
                // direction.applyMatrix4(matrix);
            }
        // origin.applyMatrix4(this.parent.parent.perpCamera.matrixWorldInverse);
        // direction.applyMatrix4(this.parent.parent.perpCamera.matrixWorldInverse);

        let ray = new Ray(origin, direction);


        // ////////////////////
        // /// 辅助
        // // no martix line
        // const material1 = new LineBasicMaterial({
        //     color: 0xff0000
        // });
        // let linePoints1 = [];
        // linePoints1.push(originOld.clone());
        // let dirP1 = new Vector3();
        // raycaster.ray.at(5000, dirP1);
        // linePoints1.push(dirP1);

        // const geometry1 = new BufferGeometry().setFromPoints(linePoints1);

        // // if (this.line1)
        // //     this.parent.parent.scene.remove(this.line1);
        // this.line1 = new Line(geometry1, material1);
        // this.parent.parent.scene.add(this.line1);
        // this.parent.parent.checkRender();


        // // // martix line for pints 

        // const material = new LineBasicMaterial({
        //     color: 0x00ff00
        // });
        // let linePoints = [];
        // linePoints.push(origin.clone());
        // let dirP2 = new Vector3();
        // ray.at(5000, dirP2);
        // linePoints.push(dirP2);
        // const geometry = new BufferGeometry().setFromPoints(linePoints);

        // // // if (this.line) {
        // // //     this.parent.parent.scene.remove(this.line);
        // // // }
        // this.line = new Line(geometry, material);
        // this.parent.parent.scene.add(this.line);

        // console.log(origin, originOld, direction, directionOld);

        // this.parent.parent.checkRender();
        // /// end 
        // //////////////////////////

        let points = [];
        if (this.octreeEntry) {
            points = this.octreeEntry.intersectRay(origin, direction);
        }
        if (points.length > 0) {
            if (this.parent.sphereRadius) {
                this.radius = this.parent.sphereRadius;
            }
            else if (this.radius === false) this.radius = this.getBoxMaxHalf() * this.percentOfHalfBoxForSphere;
            let radius = this.radius;

            let spheres = [];
            for (let i of points) {
                let perXYZ = this.points[i];
                let oneSphere = new Sphere(new Vector3(perXYZ[0], perXYZ[1], perXYZ[2]), radius);
                let p1 = new Vector3();
                ray.intersectSphere(oneSphere, p1);
                if (p1.x != 0 || p1.y != 0 || p1.z != 0) {
                    spheres.push([i, origin.distanceTo(p1)]);
                }
            }
            if (spheres.length > 0) {
                let distance = false;
                let first = false;
                for (let perOne of spheres) {
                    if (distance === false) {
                        distance = perOne[1];
                        first = perOne[0];
                    }
                    if (perOne[1] < distance) {
                        distance = perOne[1];
                        first = perOne[0];
                    }
                }
                if (first) {
                    let perXYZ = this.points[first];
                    if (this.selectPoint) {
                        this.parent.parent.scene.remove(this.selectPoint);
                    }
                    let geo = new SphereGeometry(radius, 20, 20);
                    let material = new MeshBasicMaterial({ color: this.color });//new THREE.MeshBasicMaterial( { color:0x2C590A, wireframe: false, opacity: 0.5 } );
                    this.selectPoint = new Mesh(geo, material);
                    this.selectPoint.position.set(perXYZ[0], perXYZ[1], perXYZ[2]);
                    if (this.matrix)
                        for (let i of this.matrix) {
                            this.selectPoint.applyMatrix4(i);
                        }

                    this.parent.parent.scene.add(this.selectPoint);
                    this.parent.parent.checkRender();
                    return first;
                }
            }
            else
                return false;


        }
        return false;
    }
    getBoxMaxHalf() {
        if (this.octreeEntry) {
            return this.octreeEntry.getBoxMaxHalf();
        }
        else
            return false;
        // return 1;
    }


}

export { OctreeDraw };