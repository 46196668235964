import { MeshGID } from "./meshGID";
import { MeshAbaqus } from "./meshAbaqus";
class MeshAnalysis {

    constructor(callbacks = false) {
        this.fileAnalysis = {};
        this.md5OfFiles = {
            //"md5":filename,
        };
        this.filenames = {
            // filename:filename
        };
        this.callWorker = {};
        this.RGB = callbacks.RGB;
        this.init(callbacks);
    }
    init(callback) {

        if (callback) {
            for (let i in callback) {
                this.callWorker[i] = callback[i];
            }
        }
        else {
            this.log("MeshAnalysis : can't find the callback or worker input");
        }
        this.log("MeshAnalysis Init.");
    }
    do(data) {
        data.parent = this;
        if (data.ext == "msh") {
            this.fileAnalysis = new MeshGID(data,this.RGB);
        }
        else if (data.ext == "inp") {
            this.fileAnalysis = new MeshAbaqus(data, this.RGB);
        }
        else {
            this.log({
                type: "error",
                succeed: false,
                subject: "工程文件不在已经解析的范围内",
                sender: "mesh module",
            });
            return;
        }
        if (this.fileAnalysis.succeed)
            this.postMSG({
                type: "data",
                subject: { "mesh": data.ext },
                data: this.fileAnalysis.data,
                succeed: this.fileAnalysis.succeed,
            });
        else {
            this.log({
                type: "error",
                succeed: this.fileAnalysis.succeed,
                subject: "解析文件错误",
                data: this.fileAnalysis.MSG,
                sender: "mesh module",
            });
        }
    }
    postMSG(data) {
        // this.callWorker.onMSG({ type: "Mesh", content: "dfasdfasdf" }, this.callWorker.parent);
        this.callWorker.onMSG(data);
    }
    // this function will be rewrite by worker fork class
    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callWorker["log"] != "undefined") {
            that.callWorker.log(msg, that.callWorker.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }

}
export { MeshAnalysis };