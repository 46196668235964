class StrOne {
    constructor(data) {
        this.data = {
            type: "str",
            subType: "str",
            data: {
            },
            // site: [],
        };
        this.MSG = {};
        this.succeed = false;
        this.init(data);
        return this;
    }
    init(data) {


        this.succeed = this.getRES(data);
    }
    getData() {

    }
    getRES(text) {
        if (text.data) {
            let title = [];
            let title_name = [];
            let file_lines = text.data.split("\n");
            let mesh = false;
            for (let i in file_lines) {
                let perLine = file_lines[i].split("\r")[0];

                if ((mesh || mesh === false) && perLine.indexOf("Mesh") != -1) {
                    mesh = false;
                    title = [];
                    title_name = [];
                    mesh = perLine.split(/\s+/)[1].replace(/"/g, "").replace(/'/g, "");
                    
                    // console.log(mesh);
                }

                if (mesh) {
                    let perLineArray = [];
                    if (typeof this.data.data[mesh] == "undefined") {
                        this.data.data[mesh] = {
                            data: {},
                            site: [],
                        };
                    }
                    if (title.length == 0 && perLine.indexOf("|") != -1) {
                        title = perLine.replace(/\s+/g, "").split("|");
                        title.shift();
                        //获取具体name
                        let jj = 0;//
                        let nameOld = false;
                        for (let j of title) {

                            let name = j.split("_")[0];

                            if (nameOld === false) {
                                nameOld = name;
                            }
                            else if (name != nameOld) {
                                jj++;
                            }

                            if (typeof title_name[jj] == "undefined") {
                                title_name[jj] = { name: name, count: 1 };
                            }
                            else {
                                title_name[jj].count = title_name[jj].count + 1;
                            }

                            if (nameOld !== false && nameOld != name) {
                                nameOld = name;
                            }
                        }

                        for (let siteI in title_name) {
                            this.data.data[mesh].site.push(title_name[siteI].name);
                        }
                        this.data.site = this.data.data[mesh].site;
                        continue;
                    }
                    else {
                        perLineArray = perLine.split(/\s+/);
                        perLineArray.shift();
                    }

                    if (perLineArray.length == title.length + 1) {//每行内容一致

                        // if (perLineArray[0] + 1 === parseInt(perLineArray[0]) + 1) {//是单元号，数字

                        let arrayIofElement = 1;

                        for (let elementNoI in title_name) {//场
                            let eiOBJ = title_name[elementNoI];
                            let count = eiOBJ.count;
                            let name = eiOBJ.name;
                            for (let count_I = 0; count_I < count; count_I++) {
                                if (typeof this.data.data[mesh].data[name] == "undefined") {
                                    this.data.data[mesh].data[name] = {};
                                }
                                if (typeof this.data.data[mesh].data[name][perLineArray[0]] == "undefined") {
                                    this.data.data[mesh].data[name][perLineArray[0]] = [];
                                }
                                this.data.data[mesh].data[name][perLineArray[0]].push(parseFloat(perLineArray[arrayIofElement++]));
                            }
                        }
                        // }
                    }
                }




            }
            // console.log(this.data.data[mesh].data, title_name, title);
            return true;
        }
        else {
            return false;
        }
    }




}
export { StrOne };