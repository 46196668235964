import { GetFile } from "./getfile";// worker ready
import { SaveDB } from "./savedb";//no worker 
import { Draw } from "./drawThree";// no worker
import { Info } from "./info";// no worker
// import testWorker from '../worker/test.worker.js'
import { OctreeDraw } from "../tree/octreeDraw";
import { Vector3 } from "three";

class CMOrigin {
    // parent, file, check = 0, callback
    constructor(input = false) {


        this.worker = {};
        this.info = new Info(this, { log: this.log });
        this.sphereRadius = false;
        this.sphereSegment = 20;

        this.RGB = false;
        this.cache = input.cache || input.check != 2 ? true : false;
        if (typeof input.preSetting != "undefined" && typeof input.preSetting.RGB != "undefined" && input.preSetting.RGB == "white") {
            this.RGB = true;
        }
        if (input) {
            this.input = input;
            if (typeof input.sphereRadius != "undefined")
                this.sphereRadius = input.sphereRadius;
            if (typeof input.sphereSegment != "undefined")
                this.sphereSegment = input.sphereSegment;

            if (typeof input.parent !== "undefined") { this.parent = input.parent; }
            else {
                this.log({
                    type: "error",
                    subject: "CM object didn't find the value of parent ",
                    sender: "CM"
                });
            }

        } else {
            this.log({
                type: "log",
                succeed: false,
                subject: "cm error:didn't find input value!",
                sender: "CM module",
            });
            return false;
        }


        let that = this;
        this.Draw = {};
        // this.Draw = new Draw(this, {
        //     log: this.log,
        //     afterDraw: function () {
        //         that.afterDraw(that);
        //     },
        // });
        this.info = new Info(this, {
            log: this.log,
            afterDraw: function () {
                if (typeof that.callbacks.afterDraw == "function") {/* && (typeof that.callbacks.afterDrawFlag == "undefined" || that.callbacks.afterDrawFlag == "info") || that.cache === false*/
                    // if (that.cache === false)
                    // that.afterDraw(that, "info");
                    try {
                        that.callbacks.afterDraw(that);
                    }
                    catch (err) {
                        that.log({
                            type: "error",
                            subject: "afterDraw 的附件callback函数错误,请检查主入口CM的afterDraw()的正确性",
                            sender: "CM"
                        });
                    }
                }
            },

        });
        this.setting = {
            afterDrawCount: 0,//3
            center: true,
            instanceOfAbaqus: false,
        };
        if (typeof input.center != "undefined")
            if (input.center === false) {
                this.setting.center = input.center;
            }

        this.callbacks = input.callbacks;
        // this.init(input.callbacks);//remove the son of class
        return this;
    }

    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callbacks.log !== "undefined") {
            that.callbacks.log(msg);
        } else {
            console.log("getFile : ", msg);
        }
    }

    // this function will be rewrite by worker fork class
    init() {
        let that = this;
        this.log({
            type: "log",
            succeed: true,
            subject: "1%:初始化",
            sender: "CM module"
        });
        this.worker.getFile = new GetFile({
            log: this.log,
            onMSG: function (data) {
                if (data.subject == "source") {
                    that.info.setSource(data);
                }
                else if (data.subject == "res") {
                    that.info.setRES(data);
                }
                else if (data.subject == "drawTree") {
                    that.beforDraw(data, that);
                    that.draw(data, that);
                    that.info.setDrawTree(data.data);

                }
                else if (data.subject == "str") {
                    that.info.setExtraStr(data.data);

                }
                else if (data.subject == "midas") {
                    that.info.setExtraMidas(data.data);

                }
                else if (data.subject == "midasMGT") {
                    that.info.setMidasMGT(data.data, true);
                    that.draw({
                        data: data.data.data.drawTree,
                        subject: "drawTree",
                        succeed: true,
                        type: "data",
                    }, that);
                }
            },
            RGB: that.RGB,
            parent: this,
            cache: this.cache,


        });

        // this.worker.loadPLTFR = new SaveDB({
        //     log: this.log,
        //     onMSG: function (data) {
        //         that.draw(data, that);
        //     },
        //     parent: this,
        //     dbName: this.input.file,
        // });

        this.log({
            type: "log",
            succeed: true,
            subject: "2%:开始传输文件",
            sender: "CM module",
        });
        // if (typeof this.input.check !== undefined) {
        if (typeof this.input.check == "undefined" || this.input.check == 0 || this.input.check == "1") { // load from cacahe

            // errorOfLoad = this.worker.loadPLTFR.load("drawTree", function (data) {
            //     that.draw(data, that);
            // });
            let myFirstPromise = new Promise(function (resolve, reject) {
                that.worker.loadPLTFR = new SaveDB({
                    log: that.log,
                    onMSG: function (data) {
                        that.draw(data, that);
                    },
                    parent: that,
                    dbName: that.input.file,
                    fun: function () {
                        resolve("init finished!");
                    },
                    cache: true,
                });
            });

            myFirstPromise.then(function (successMessage) {
                console.log(successMessage);
                //回调 
                that.worker.loadPLTFR.DB.getCount(
                    function (count) {
                        that.worker.loadPLTFR.get("drawTree", function (data) {
                            // console.log(data);
                            if (data === false) {
                                that.worker.getFile.readFile(that.input.file);
                            }
                            else {
                                if (data.subject == "drawTree") {
                                    that.info.setDrawTree(data.data, count - 1);
                                    that.draw(data, that);
                                }
                            }
                        });
                        that.worker.loadPLTFR.get("source", function (data) {
                            // console.log(data);
                            if (data) {
                                if (data.subject == "source")
                                    that.info.setSource(data, count - 1);
                            }
                        });
                        that.worker.loadPLTFR.get("res", function (data) {
                            // console.log(data);
                            if (data) {
                                if (data.subject == "res")
                                    that.info.setRES(data, count - 1);
                            }
                        });
                    }
                );

            });



        }
        else { // get file from http
            this.worker.getFile.readFile(this.input.file);
            if (typeof this.input.extra != "undefined")
                this.worker.getFile.readExtraFile(this.input.extra);
        }
        // } else {
        //     this.worker.getFile.readFile(this.input.file);
        // }
    }

    reInit() { }
    initLoad() { }
    reLoad() { }
    updateENVLast() {
        this.parent.setENVLast();
    }
    setDimension() {
        this.parent.setDimension(this.getDimension());
    }
    //  value={
    //     type:"data"/"msg",
    //     succeed:true/false,
    //     subject:'',
    //     data:{}
    // }
    draw(value, that = false) {
        // this.log(value);
        if (that == false) {
            that = this;
        }
        if (typeof value.type !== "undefined" && typeof value.succeed !== "undefined" && typeof value.data !== "undefined") {

            if (value.type == "data" && value.succeed == true && value.data != "") {
                that.log({
                    type: "log",
                    succeed: true,
                    subject: "90%:数据分析完毕",
                    sender: "CM module",
                    // data: value
                });
                that.Draw.draw(value);// data is from callback or worker ,it's a JSON that includ PLTFR
                this.log({ type: "log", succed: true, subject: "100% 完成" });
            } else {
                that.log({
                    type: "error",
                    succeed: false,
                    subject: "错误:数据分析异常,cmOrigin:215",
                    data: value,
                    sender: "CM module",
                });
            }
        } else {
            that.log({
                type: "error",
                succeed: false,
                subject: "错误:数据分析异常cmOrigin:222",
                data: value,
                sender: "CM module",
            });
        }
    }

    afterDraw(that, type = false) {
        that.setting.afterDrawCount++;
        if (type == "draw") {
            that.Draw.data.meshTree.updateMatrix();
            that.matrixOld = that.Draw.data.meshTree.matrix.clone();
            if (that.input.rotate && Object.keys(that.input.rotate).length > 0) {

                for (let dir in that.input.rotate) {
                    let valueOfDir = that.input.rotate[dir];
                    if (dir == "x") {
                        that.rotateX(parseFloat(valueOfDir));

                    }
                    if (dir == "y") {
                        that.rotateY(parseFloat(valueOfDir));
                    }
                    if (dir == "z") {
                        that.rotateZ(parseFloat(valueOfDir));
                    }
                }

            }
            if (typeof that.callbacks.afterDraw == "function") {
                if (this.cache === false)
                    try {
                        that.callbacks.afterDraw(that);
                    }
                    catch (err) {
                        that.log({
                            type: "error",
                            subject: "afterDraw 的附件callback函数错误,请检查主入口CM的afterDraw()的正确性",
                            sender: "CM"
                        });
                    }
            }
            that.setDimension();
            that.updateENVLast();
        }
    }

    /// /////////////////////////////////////////////////////////////////////////////////////////////
    // part of webworker

    initGetFile() {
        // this.getFile = new getFile();
        // this.worker.getFIler = new Worker('./worker/get1.worker.js', { type: 'module' });
        // // this.worker.getFIler = new Worker(url);
        // // this.worker.getFIler = new Worker(url, { type: 'module' });
        // this.worker.getFIler.onerror = function (e) {
        //     console.log('error:', e)
        // }
        // this.worker.getFIler.postMessage("test");
    }

    getBlobOfString(stringOfWorker) {
        if (stringOfWorker) {
            const blob = new Blob([stringOfWorker], { type: "text/javascript" });
            return URL.createObjectURL(blob);
        } else { return false; }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //function  CM for outside 

    /////////////////////////////////
    //draw 
    updateColors(nameOfSiteOfCM, title = " ", unit = " ") {
        this.Draw.updateColors(nameOfSiteOfCM, title, unit);
    }
    //get global cm max min setting of enable
    getGlobalCMEnable() {
        return this.Draw.setting.globalSiteEnable;
    }

    // set max and min of global 
    setGlobalCMEnable(value = false) {
        this.Draw.setGlobalCMEnable(value);
    }



    getNameListOfCM() {
        return this.info.getNameListOfCM();
        // if (this.Draw.data.namesOfCM) {
        //     return this.Draw.getNameListOfCM();
        // }
        // else
        //     return [];
    }
    getTransEnable() {
        this.Draw.getTransEnable();
    }
    setTransEnable(value = false) {
        this.Draw.setTransEnable(value);
    }
    setTransRate(value = false) {
        this.Draw.setTransRate(value);
    }
    getTransRate() {
        return this.Draw.getTransRate();
    }
    clean() {
        this.Draw.clean();
        this.info.clean();
        let that = this;
        this.Draw = new Draw(this, {
            log: this.log,
            afterDraw: function () {
                that.afterDraw(that, "draw");
            },
        });
        this.info = new Info(this, {
            log: this.log,
            afterDraw: function () {
                that.afterDraw(that, "info");
            },
        });
    }
    show() {
        this.Draw.show();
    }
    play(s = 0.5) {
        this.Draw.play(s);
    }
    stop() {
        this.Draw.stop();
    }
    showFrameLines(value = true) {
        this.Draw.showFrameLines(value);
    }

    getDimension() {
        return this.Draw.getDimension();
    }

    getCurrentLevel() {
        return this.Draw.getCurrentLevel();
    }
    setCurrentLevel(value) {
        this.Draw.setCurrentLevel(value);
    }

    getCurrentLevelByIndex() {
        return this.Draw.getCurrentLevelByIndex();
    }
    setCurrentLevelByIndex(value) {
        this.Draw.setCurrentLevelByIndex(value);
    }
    isItAni() {
        return this.Draw.isItAni();
    }
    setClipping(localPlane = false) {
        this.Draw.setClipping(localPlane);
    }

    repairPoly() {
        this.Draw.repairPoly();
    }

    getCurrentCMLevel() {
        return this.Draw.setting.currentCMLevel;
    }
    clippingShow() {
        if (typeof this.Draw.clipping != "undefined") {
            this.Draw.clipping.show();
        }
    }
    clippingHide() {
        if (typeof this.Draw.clipping != "undefined") {
            this.Draw.clipping.hide();
        }
    }

    /////////////////////////////////
    //info 

    getBestTransRate() {
        return this.info.getBestTransRate();
    }

    getDefaultTrans() {
        return this.info.getDefaultTrans();
    }
    getGlobalMaxMinOfCM(nameOfCM) {
        if (nameOfCM)
            return this.info.getGlobalCMMM(nameOfCM);
        else
            this.log({
                type: "error",
                subject: "getGlobalMaxMinOfCM(需要数值场名称)",
                sender: "CM"
            });
    }
    getCurrentMaxMinOfCM(nameOfCM, level = 1) {
        if (nameOfCM) {
            if (nameOfCM.indexOf("str.") != -1) {
                return this.info.getMMOfStrALL(nameOfCM);
            }
            else
                return this.info.getCurrentLevelCMMM(nameOfCM, level);
        }
        else
            this.log({
                type: "error",
                subject: "getCurrentMaxMinOfCM(需要数值场名称)",
                sender: "CM"
            });
        return false;
    }

    // for Draw use ,
    getMaxMinOfCM(nameOfSite, globalSiteEnable = false, currentCMLevel = 1) {
        if (nameOfSite) {
            if (globalSiteEnable)
                return this.getGlobalMaxMinOfCM(nameOfSite);
            else
                return this.getCurrentMaxMinOfCM(nameOfSite, currentCMLevel);
        }
        else {
            return { max: 1, min: 0 };
        }
    }

    // Has  global CM ? 
    //return true/false
    getGlobalCMMMVisable() {
        if (Object.keys(this.info.data.res.res).length > 1) {
            return true;
        }
        else {
            return false;
        }
    }

    // get the ani play of res list of timer
    getResTimeList() {
        if (this.getGlobalCMMMVisable) {
            return this.info.getResTimeList();
        }
        else {
            return false;
        }
    }

    // problem !!
    getRES() {
        return this.info.data.res;
    }
    getSiteTimeList() {
        return this.info.getSiteTimeList();
    }
    //////////////////////
    // info of uvw and xyz 
    getSiteValuesOfTriangles(instanceName, nameOfSiteCM, level) {
        return this.info.getSiteValuesOfTriangles(instanceName, nameOfSiteCM, level);
    }
    getSiteValuesOfLines(instanceName, nameOfSiteCM, level) {
        return this.info.getSiteValuesOfLines(instanceName, nameOfSiteCM, level);
    }
    getSiteValuesOfPoints(instanceName, nameOfSiteCM, level) {
        return this.info.getSiteValuesOfPoints(instanceName, nameOfSiteCM, level);
    }
    getOnePointUVW(instanceName, level, site, index) {
        return this.info.getOnePointUVW(instanceName, level, site, index);
    }
    getCMObject() {
        return this.Draw.data.meshTree;
    }
    getOnePointPressure(instanceName, level, site, index) {
        return this.info.getOnePointPressure(instanceName, level, site, index);
    }
    getOnePointXYZ(instanceName, index) {
        return this.info.getOnePointXYZ(instanceName, index);
    }
    getPointsXYZUVW(instanceName, indeies) {
        let siteOfUVW = this.getDefaultTrans();
        let level = this.getCurrentCMLevel();
        let xyz = [];
        let uvw = [];
        for (let i of indeies) {
            xyz.push(this.getOnePointXYZ(instanceName, i));
            if (siteOfUVW && siteOfUVW[1].length != 0)
                uvw.push(this.getOnePointUVW(instanceName, level, siteOfUVW[1], i));
        }
        return { xyz: xyz, uvw: uvw };
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // pickup
    getPickupObjectIsVisible(name, that) {
        let meshTree = that.Draw.data.meshTree;
        for (let i of meshTree.children) {
            if (i.name == name && i.visible === true) {
                return true;
            }
        }
        return false;
    }
    reinitPickUpByInstanceName(that, name, R, color = 0xffffff) {
        if (this.sphereRadius) {
            R = this.sphereRadius;
        }
        let points = {};
        let data = that.Draw.data;
        data.meshTree.updateMatrix();
        // let matrix = [this.matrixOld];
        let matrix = [data.meshTree.matrix];

        let rotation = data.meshTree.rotation;

        {
            let i = name;
            let perShellPoints = {};
            if (that.getPickupObjectIsVisible(i, that)) {

                let perMesh = data.drawTree[i];
                if (typeof perMesh.shellPoints.index != "undefined" || Object.keys(perMesh.shellPoints.index).length != 0) {
                    for (let j in perMesh.shellPoints.index) {
                        perShellPoints[j] = perMesh.shellPoints.index[j];
                    }
                    perShellPoints = this.Draw.updatePointsPositionForTrans(i, perShellPoints);
                    for (let k in perShellPoints) {
                        points[k] = perShellPoints[k];
                    }
                }
            }
            // if (perMesh.shellPoints.matrix) {
            //     matrix.push(perMesh.shellPoints.matrix);
            // }

        }
        if (Object.keys(points).length > 0) {
            if (that.octree)
                that.octree.reInit(that, points, matrix, R, color);
            else
                that.octree = new OctreeDraw(that, points, matrix, R, color);
            // that.octree.drawFrameLines();//demo only
            that.parent.octree = that.octree;
        }
    }
    initPickUp(that, R, color = 0xffffff) {
        if (this.sphereRadius) {
            R = this.sphereRadius;
        }
        let points = {};
        let data = that.Draw.data;
        data.meshTree.updateMatrix();
        // let matrix = [this.matrixOld];
        let matrix = [data.meshTree.matrix];

        let rotation = data.meshTree.rotation;
        for (let i in data.drawTree) {
            let perShellPoints = {};
            if (that.getPickupObjectIsVisible(i, that)) {
                if (i == "Dimension_2OR3") continue;
                let perMesh = data.drawTree[i];
                if (typeof perMesh.shellPoints.index == "undefined" || Object.keys(perMesh.shellPoints.index).length == 0)
                    continue;
                for (let j in perMesh.shellPoints.index) {
                    perShellPoints[j] = perMesh.shellPoints.index[j];
                }
                perShellPoints = this.Draw.updatePointsPositionForTrans(i, perShellPoints);
                for (let k in perShellPoints) {
                    points[k] = perShellPoints[k];
                }
            }
            // if (perMesh.shellPoints.matrix) {
            //     matrix.push(perMesh.shellPoints.matrix);
            // }
        }

        if (Object.keys(points).length > 0) {
            if (that.octree)
                that.octree.reInit(that, points, matrix, R, color);
            else
                that.octree = new OctreeDraw(that, points, matrix, R, color);
            // that.octree.drawFrameLines();//demo only
            that.parent.octree = that.octree;
        }
    }
    rotateX(angle = false) {
        if (angle !== false)
            this.Draw.rotateX(angle);
    }
    rotateY(angle = false) {
        if (angle !== false)
            this.Draw.rotateY(angle);
    }
    rotateZ(angle = false) {
        if (angle !== false)
            this.Draw.rotateZ(angle);
    }
    resetViewPoint() {

    }

    //设置STR的rate
    setStrRate(value = 1) {
        this.Draw.setStrRate(value);
    }
    // 获取STR的一个点的属性
    getStrOnePointValue(id = false) {
        if (id) {
            return this.info.getStrOnePointValue(id);
        }
        else {
            this.log({
                type: "error",
                subject: "getStrOnePointValue need one node id",
                sender: "CM.getStrOnePointValue()"
            });
        }

    }
    cleanInfoPoints() {
        this.Draw.cleanInfoPoints();

    }
    showInfoPoints(ids = "all", color = false, R = false) {
        if (this.sphereRadius) {
            R = this.sphereRadius;
        }
        if (ids.length > 0 || ids == "all") {
            if (R) this.Draw.setInfoPointRadius(R);
            if (color) this.Draw.setInfoPointCorle(color);
            // this.Draw.drawInfoPoints(ids);
            this.Draw.setInfoPointIDS(ids);
        }
        else {
            this.log({
                type: "error",
                subject: "drawInfoPoints need one node id or some ids",
                sender: "CM.drawInfoPoints()"
            });
        }
    }
    cleanSelectPoint() {
        if (this.octree)
            this.octree.clean();
    }
    getOnePointAllCM(index, instanceName = false) {
        let CM = {};
        let listOfCM = this.getNameListOfCM();
        let timer = this.getResTimeList();
        if (timer.length == 1 && timer[0] == 1)
            for (let i of listOfCM) {
                if (this.info.data.source.type == "msh") {
                    CM[i] = this.getOnePointPressure(false, 1, i, index);
                }
                else if (this.info.data.source.type == "inp") {
                    CM[i] = this.getOnePointPressure(this.setting.instanceOfAbaqus, 1, i, index);
                }
            }
        else {
            for (let i of listOfCM) {
                CM[i] = {};
                let list = this.getTimeAniFrameName();
                let goodNameList = false;
                if (list !== false) {
                    if (list.length == Object.keys(timer).length) {
                        goodNameList = true;
                    }
                }
                let iOfName = 0;
                for (let j in timer) {
                    let timerName = j;
                    if (goodNameList) {
                        timerName = list[iOfName++];
                    }
                    if (this.info.data.source.type == "msh") {
                        CM[i][timerName] = this.getOnePointPressure(false, timer[j], i, index);
                    }
                    else if (this.info.data.source.type == "inp") {
                        let name = this.setting.instanceOfAbaqus;
                        if (instanceName) {
                            name = instanceName;
                        }
                        if (name) {
                            CM[i][timerName] = this.getOnePointPressure(name, timer[j], i, index);
                        }
                        else {
                            this.log({
                                type: "error",
                                succeed: false,
                                subject: "setPickupInstanceName(name) ,需要一个instance名称",
                                sender: "CM oringin ",
                            });
                        }
                    }
                }
            }
        }
        return CM;
    }
    setPickupInstanceName(name = false) {
        if (name) {
            this.setting.instanceOfAbaqus = name;
        } else {
            this.log({
                type: "error",
                succeed: false,
                subject: "setPickupInstanceName(name) ,需要一个instance名称",
                sender: "CM oringin ",
            });
        }
    }
    getOnePointPressureForAbaqus(index) {
        if (this.setting.instanceOfAbaqus === false) {
            this.log({
                type: "error",
                succeed: false,
                subject: "首先使用setPickupInstanceName(name) ,需要一个instance名称",
                sender: "CM oringin ",
            });
            return {};
        }

        let onePointCM = this.getOnePointAllCM(index);
        return onePointCM;
    }
    getOnePointAllPressure(index) {
        let onePointCM = this.getOnePointAllCM(index) || false;
        let onetPointOfStr = this.info.getStrOnePointValue(index) || false;
        if (onetPointOfStr)
            return { "CM": onePointCM, "STR": onetPointOfStr };
        else
            return { "CM": onePointCM };
    }

    showShellPointse(pointsArray = "all", color = 0xffffff, radius = false,) {
        this.Draw.drawShellPointsMerge(pointsArray, color, radius);
    }
    cleanShellPoints() {
        this.Draw.cleanShellPointsMerge();
    }
    ///////////////////////////////
    getInstanceOfAll() {
        return this.Draw.getInstanceOfAll();
    }
    hideAllInstance() {
        this.Draw.hideAllInstance();
    }
    showAllInstance() {
        this.Draw.showAllInstance();
    }
    hideInstanceByName(name = false) {
        this.Draw.hideInstanceByName(name);
    }
    showInstanceByName(name = false) {
        this.Draw.showInstanceByName(name);
    }

    addRES(url = false) {
        // if (url) {
        //     this.worker.getFile.readFile(url);
        // }
        let that = this;
        let myFirstPromise = new Promise(function (resolve, reject) {
            that.worker.loadPLTFR = new SaveDB({
                log: that.log,
                onMSG: function (data) {
                    that.draw(data, that);
                },
                parent: that,
                dbName: url,
                fun: function () {
                    resolve("init finished!");
                },
                cache: true,
            });
        });

        myFirstPromise.then(function (successMessage) {
            console.log(successMessage);


            that.worker.loadPLTFR.get("res", function (data) {
                // console.log(data); 
                if (data === false) {
                    that.worker.getFile.readFile(url, that.worker.loadPLTFR);
                }
                else if (data) {
                    if (data.subject == "res")
                        that.info.setRES(data, 0);
                }
            });
        });
    }



    updateColorByInstanceNameForOneValue(name, mm, oneValue, title = false, unit = false) {
        this.Draw.updateColorByInstanceNameForOneValue(name, mm, oneValue, title, unit);
    }
    setTimeAniFrameName(list = false) {
        if (list) {
            this.info.data.res.aniFrameNameList = list;
        }
    }
    cleanTimeAniFrameName() {

        this.info.data.res.aniFrameNameList = false;

    }
    getTimeAniFrameName() {
        if (typeof this.info.data.res.aniFrameNameList != "undefined") {
            return this.info.data.res.aniFrameNameList;
        }
        else {
            return false;
        }
    }
    setRateOfStr(value = false) {
        this.Draw.setRateOfStr(value);
    }

    beforDraw(data, that) {

        if (typeof that.callbacks.beforeDraw == "function") {
            try {
                that.callbacks.beforeDraw(that, data);
            }
            catch (err) {
                that.log({
                    type: "error",
                    subject: "beforeDraw 的附件callback函数错误,请检查主入口CM的beforeDraw()的正确性",
                    sender: "CM"
                });
            }
        }

    }
    setTransformationDir(value) {
        this.Draw.setTransformationDir(value);
    }

}
export { CMOrigin };
