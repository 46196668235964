
import { GetFile } from "./getfile";
import { AnalysisMidas } from "./AnalysisMidas";
import { MidasAnalysis } from "./midas";


class GetFileOfMidas extends GetFile {

    readFile(data, extra) {
        this.readExtraFile(data, extra);
    }
    readExtraFile(data, extra) {//data is filename
        let that = this;
        this.filename = data;
        this.log({
            type: "log",
            succeed: true,
            subject: "10%:......",
            sender: "getfile module , readExtraFile()",
        });

        this.extra = extra;
        if (typeof data != "undefined") {
            let extFilename = data.split(".");

            if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "zip") {
                this.loadExtraMidasZip(data);
            }
            else if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "mct") {
                this.loadExtraMidasFile(data);
            }
        }



    }
}

export { GetFileOfMidas };