import { MeshOrigin } from "./meshOrigin";
class MeshAbaqus extends MeshOrigin {
    constructor(data, RGB = false) {
        super(data);
        this.parent = data.parent;
        this.data = {
            source: {
                type: "inp",
                nodes: {},
                elements: {},
                boundary: {},
                part: {},
                instance: {},
                assembly: {
                    Nset: {},
                    Elset: {},
                    surface: {},
                },
                materials: {},
                // boundary: {},
                step: [],
                // {
                //     Loads:{},
                //     Interaction:{},
                //     "OUTPUT REQUESTS":{},
                //     "FIELD OUTPUT":{},
                //     "HISTORY OUTPUT":{},

                // },

                // group:{},
            },
            drawTree: {
            },
        };
        this.defaultColorR = 0.1;
        this.defaultColorG = 0.1;
        this.defaultColorB = 0.1;
        if (RGB === true) {
            this.defaultColorR = -9.;
            this.defaultColorG = -9.;
            this.defaultColorB = -9.;
        }
        this.colors = {
            frameLine: [0, 0, 0],
            line: [1, 1, 1],
            point: [1, 1, 1]
        };
        this.MSG = {};
        this.succeed = false;
        this.init(data);
        return this;
    }
    log(msg) {

        if (typeof this.parent != "undefined") {
            this.parent.log(msg, this.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }
    init(data) {
        let succeed = this.getFileMesh(data);

        // if (succeed)
        // succeed =
        this.calculateMesh();

        this.succeed = succeed;
    }
    calculateMesh() {
        this.data.drawTree.Dimension_2OR3 = this.data.source.dimension;
        let meshs = this.data.source.instance;
        let parts = this.data.source.part;
        for (let i in meshs) {
            let perInstance = meshs[i];
            if (typeof parts[perInstance.part].PLTFR != "undefined") {
                let perPart = JSON.parse(JSON.stringify(parts[perInstance.part].PLTFR));

                if (typeof perInstance.rotate != "undefined") {
                    if (typeof perPart.matrix.inp == "undefined") {
                        perPart.matrix = { inp: {} };
                    }
                    perPart.matrix.inp.rotate = perInstance.rotate;
                }
                if (typeof perInstance.transform != "undefined") {
                    if (typeof perPart.matrix.inp == "undefined") {
                        perPart.matrix = { inp: {} };
                    }
                    perPart.matrix.inp.transform = perInstance.transform;
                }
                if (typeof perInstance.xyz1 != "undefined") {
                    if (typeof perPart.matrix.inp == "undefined") {
                        perPart.matrix = { inp: {} };
                    }
                    perPart.matrix.inp.xyz1 = perInstance.xyz1;
                }
                if (typeof perInstance.xyz2 != "undefined") {
                    if (typeof perPart.matrix.inp == "undefined") {
                        perPart.matrix = { inp: {} };
                    }
                    perPart.matrix.inp.xyz2 = perInstance.xyz2;
                }
                this.data.drawTree[i] = perPart;
            }
        }
        // this.calculateNetwork();
    }////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // mesh analysis 
    getFileMesh(text) {

        // let text = value[0];
        // let index = value[1];
        if (text.data) {
            try {
                let msh_file_lines = text.data.split("\n");

                let Step_name = false;
                let subStep_name = false;
                let flag_subStep_Name = false;
                let flag_substar = false;
                let flag_subStep_LOADS = false;
                let flag_subStep_INTERACTIONS = false;
                let flag_subStep_OUTPUTREQUESTS = false;
                let flag_subStep_FIELDOUTPUT = false;
                let flag_subStep_HISTORYOUTPUT = false;

                let flag_loadName = false;
                let flag_loadName_content = false;

                let Assembly_name = false;
                let Assembly_instance = false;
                let Assembly_Nset = false;
                let Assembly_Elset = false;
                let Assembly_Surface = false;
                let Assembly_Surface_type = false;

                let Assembly_Node = false;
                let Assembly_Element = false;

                let Instance_name = false;
                let PartOfInstance_name = false;

                let Instance_node = false;
                let Instance_element = false;

                let Part_name = false;
                let Material_name = false;
                let subMaterial_name = false;
                let subMaterial_content_name = false;

                let BOUNDARY_name = false;
                let BOUNDARY_name_inStep = false;
                let subBOUNDARY_name = false;
                let subBOUNDARY_type = false;
                let subBOUNDARY_flag = false;

                let subINTERACTIONS = false;
                let subINTERACTIONS_kind = false;

                // let STEP_name = false;

                let flag_node = false;//标志位，
                let flag_element = false;//标志位，
                let flag_elementType = "";
                let flag_Nset = false;
                let flag_Elset = false;
                let flag_Section = false;
                let flag_Section_Elset = false;
                // let flag_Material = false;

                let flag_NsetGenerate = false;
                let flag_ElsetGenerate = false;


                // let iiiii = 1;
                for (var item_1 of msh_file_lines) {// 每行
                    // console.log(item_1);
                    // console.log(iiiii++);
                    ////console.log(item_1,item_1.split('\r')[0].length);
                    let perLineSplitByDouhao = item_1.split("\r")[0].split(",");
                    //判断大的块区
                    if (perLineSplitByDouhao[0] == "*Part" && Assembly_name === false) {
                        let name = perLineSplitByDouhao[1].split("name=")[1].replace(/\"/g, "");
                        //console.log(perLineSplitByDouhao[0], name[1]);
                        Part_name = name.toLocaleLowerCase();
                        this.data.source.part[Part_name] = {};
                        this.data.source.part[Part_name].element = {};//change  new 20210831
                        Instance_name = false;
                        flag_node = false;
                        flag_element = false;
                        flag_Elset = false;
                        flag_Nset = false;
                        flag_Section = false;
                        continue;
                    }
                    else if (perLineSplitByDouhao[0] == "*Instance") {
                        let name = perLineSplitByDouhao[1].split("name=")[1].replace(/\"/g, "");
                        let part = perLineSplitByDouhao[2].split("part=")[1].replace(/\"/g, "");
                        Instance_name = name.toLowerCase();
                        if (typeof this.data.source.instance.Instance_name == "undefined") {
                            this.data.source.instance[Instance_name] = {};
                            this.data.source.instance[Instance_name].part = part.toLowerCase();
                        }
                        Part_name = false;
                        flag_node = false;
                        flag_element = false;
                        flag_Elset = false;
                        flag_Nset = false;
                        flag_Section = false;
                        Instance_node = false;
                        Instance_element = false;
                        PartOfInstance_name = part.toLowerCase();
                        continue;
                    }
                    else if (perLineSplitByDouhao[0] == "*Assembly") {
                        let name = perLineSplitByDouhao[1].split("name=")[1].replace(/\"/g, "");
                        Assembly_name = name.toLocaleLowerCase();
                        Assembly_Element = false;
                        Assembly_Node = false;
                        Part_name = false;
                        Instance_name = false;
                        continue;
                    }
                    else if (perLineSplitByDouhao[0] == "** MATERIALS") {
                        // Material_name = perLineSplitByDouhao[1].split('name=')[1].replace(/\"/g, '');
                        Material_name = true;
                        continue;
                    }
                    else if (perLineSplitByDouhao[0] == "** BOUNDARY CONDITIONS" && Step_name == false) {
                        flag_subStep_Name = false;//关闭setp name部分的input
                        // let name = perLineSplitByDouhao[1].split('name=')[1].replace(/\"/g, '');
                        Material_name = false;
                        subMaterial_name = false;
                        BOUNDARY_name = true;
                        BOUNDARY_name_inStep = false;
                        continue;
                    }
                    else if (perLineSplitByDouhao[0] == "** BOUNDARY CONDITIONS" && Step_name == true) {
                        flag_subStep_Name = false;//关闭setp name部分的input
                        // let name = perLineSplitByDouhao[1].split('name=')[1].replace(/\"/g, '');
                        Material_name = false;
                        subMaterial_name = false;
                        BOUNDARY_name = false;
                        BOUNDARY_name_inStep = true;
                        // if (typeof subStep_name.boundary == 'undefined')
                        //     subStep_name.boundary = {};
                        continue;
                    }
                    else if (perLineSplitByDouhao[0].split(":")[0] == "** STEP") {
                        BOUNDARY_name = false;
                        Material_name = false;
                        subBOUNDARY_name = false;
                        subBOUNDARY_type = false;
                        subBOUNDARY_flag = false;
                        //存在多个step,reset values
                        subStep_name = false;
                        flag_subStep_Name = false;
                        flag_substar = false;
                        flag_subStep_LOADS = false;
                        flag_subStep_INTERACTIONS = false;
                        flag_subStep_OUTPUTREQUESTS = false;
                        flag_subStep_FIELDOUTPUT = false;
                        flag_subStep_HISTORYOUTPUT = false;

                        // Step_name = perLineSplitByDouhao[1].split(':')[1].replace(/\"/g, '');
                        Step_name = true;
                        subStep_name = {};
                        // subStep_name.name = Step_name;
                        continue;
                    }

                    //每个不同的块区
                    if (Part_name || (PartOfInstance_name && (Instance_element || Instance_node))) {
                        if (PartOfInstance_name) {
                            if (perLineSplitByDouhao[0] == "*End Instance") {
                                // Instance_name = false;
                                // Instance_element = false;
                                // Instance_node = false;
                                // PartOfInstance_name = false;
                                // Part_name = false;
                                // continue;
                            }
                            Part_name = PartOfInstance_name;
                        }
                        if (perLineSplitByDouhao[0] == "*End Part" || (perLineSplitByDouhao[0] == "*End Instance" && PartOfInstance_name !== false)) {
                            // //console.log(perLineSplitByDouhao[0]);
                            for (let typei in this.data.source.part[Part_name].element) {
                                let perType = this.data.source.part[Part_name].element[typei].type;
                                if (perType == "CPE3" || perType == "STRI3") {
                                    this.CPE3(Part_name, perType);
                                }
                                else if (perType == "CPE4") {
                                    this.CPE4(Part_name, perType);
                                }
                                else if (perType.indexOf("S3") != -1 || perType == "SFM3D3") {
                                    this.S3(Part_name, perType);
                                }
                                else if (perType.indexOf("S4") != -1 || perType == "SFM3D4") {
                                    this.S4(Part_name, perType);
                                }
                                else if (perType == "S4" || perType == "S4R") {
                                    this.S4(Part_name, perType);
                                }
                                else if (perType.indexOf("C3D8") != -1) {
                                    this.C3D8R(Part_name, perType);
                                }
                                else if (perType.indexOf("C3D4") != -1) {
                                    this.C3D4(Part_name, perType);
                                }
                                else if (perType.indexOf("C3D10") != -1) {
                                    this.C3D10(Part_name, perType);
                                }

                                else if (perType == "T3D2") {
                                    this.T3D2(Part_name, perType);
                                }
                                else if (perType == "B31") {
                                    this.B31(Part_name, perType);
                                }
                                else if (perType == "C2D9") {
                                    this.C2D9(Part_name, perType);
                                }
                                else if (perType == "FC2D9") {
                                    this.C2D9(Part_name, perType);
                                }
                                // this.format2structure(Part_name, perType);
                            }
                            flag_node = false;
                            flag_element = false;
                            Part_name = false;
                            PartOfInstance_name = false;

                            continue;
                        }
                        let perLineSplitByDouhao_0_ByXinghao = perLineSplitByDouhao[0].split("\*");
                        let perLineSplitByDouhao_0_ByMaohao = perLineSplitByDouhao[0].split(":");
                        if (perLineSplitByDouhao_0_ByXinghao[1] == "Node") {
                            flag_node = true;
                            flag_element = false;
                            flag_Elset = false;
                            flag_Nset = false;
                            continue;
                        }
                        else if (perLineSplitByDouhao_0_ByXinghao[1] == "Element") {
                            this.data.source.part[Part_name].nodesLength = this.data.source.part[Part_name].nodes.length;
                            flag_node = false;
                            flag_element = true;
                            flag_Elset = false;
                            flag_Nset = false;
                            let kind = perLineSplitByDouhao[1].split("=");
                            flag_elementType = kind[1];
                            this.data.source.part[Part_name].element[kind[1]] = {
                                type: flag_elementType,
                                element: [],
                            };
                            continue;
                        }
                        else if (perLineSplitByDouhao_0_ByXinghao[1] == "Nset") {
                            flag_node = false;
                            flag_element = false;
                            flag_Nset = perLineSplitByDouhao[1].replace(/\s+/g, "").split("nset=")[1];
                            if (typeof perLineSplitByDouhao[2] != "undefined") {
                                flag_NsetGenerate = true;//perLineSplitByDouhao[2];
                            }
                            flag_Elset = false;
                            flag_Section = false;
                            continue;
                        }
                        else if (perLineSplitByDouhao_0_ByXinghao[1] == "Elset") {
                            flag_node = false;
                            flag_element = false;
                            flag_Elset = perLineSplitByDouhao[1].split("elset=")[1];
                            if (typeof perLineSplitByDouhao[2] != "undefined") {
                                flag_ElsetGenerate = perLineSplitByDouhao[2];
                            }
                            flag_Nset = false;
                            flag_Section = false;
                            continue;
                        }
                        else if (perLineSplitByDouhao_0_ByMaohao[0] == "** Section") {
                            flag_node = false;
                            flag_element = false;
                            flag_Elset = false;
                            flag_Nset = false;
                            flag_Section = perLineSplitByDouhao_0_ByMaohao[1].substr(1, perLineSplitByDouhao_0_ByMaohao[1].length).split(/\s+/)[0];

                            if (typeof this.data.source.part[Part_name].Section == "undefined") {
                                this.data.source.part[Part_name].Section = {};
                            }
                            if (typeof this.data.source.part[Part_name].Section[flag_Section] == "undefined") {
                                this.data.source.part[Part_name].Section[flag_Section] = {
                                    name: flag_Section,
                                    // profile: profile,
                                    content: [],
                                };
                                if (perLineSplitByDouhao_0_ByMaohao.length > 2) {
                                    let profile_name = perLineSplitByDouhao_0_ByMaohao[1].substr(1).split(/\s+/)[1];
                                    let profile = perLineSplitByDouhao_0_ByMaohao[2].substr(1);
                                    this.data.source.part[Part_name].Section[flag_Section][profile_name] = profile;
                                }
                            }
                            continue;
                        }
                        else if (perLineSplitByDouhao_0_ByXinghao[1] == "*") {
                            flag_node = false;
                            flag_element = false;
                            flag_Elset = false;
                            flag_Nset = false;
                            flag_Section = false;
                            continue;
                        }

                        if (flag_element) {
                            // let temp2 = perLineSplitByDouhao[0].split(/\s+/);
                            if (/^\s+\d+$/.test(perLineSplitByDouhao[0]) || /^\d+$/.test(perLineSplitByDouhao[0])) {
                                let temp_element = parseInt(perLineSplitByDouhao[0]);
                                if (typeof this.data.source.part[Part_name].element[flag_elementType].start == "undefined")
                                    this.data.source.part[Part_name].element[flag_elementType].start = temp_element;
                                this.data.source.part[Part_name].element[flag_elementType].end = temp_element;
                                if (typeof this.data.source.part[Part_name].element[flag_elementType].element == "undefined") {
                                    this.data.source.part[Part_name].element[flag_elementType].element = [];
                                }
                                if (typeof this.data.source.part[Part_name].element[flag_elementType].element[temp_element] == "undefined") {
                                    this.data.source.part[Part_name].element[flag_elementType].element[temp_element] = [];
                                }
                                //根据长度压入element数组
                                for (let i = 1; i < perLineSplitByDouhao.length; i++) {
                                    // this.data.source.part[Part_name].element[temp_element][i-1]=perLineSplitByDouhao[i];//ok
                                    this.data.source.part[Part_name].element[flag_elementType].element[temp_element].push(parseInt(perLineSplitByDouhao[i]));//ok
                                }
                            }
                            else {
                                flag_node = false;
                                flag_element = false;
                                continue;
                            }
                        }
                        else if (flag_node) {//vertex 
                            if (typeof this.data.source.part[Part_name].nodes == "undefined") {
                                this.data.source.part[Part_name].nodes = [];
                            }
                            let index = perLineSplitByDouhao[0];
                            // if (perLineSplitByDouhao[0].indexOf(" ") != "-1") {
                            //     index = perLineSplitByDouhao[0].split(/\s+/)[1];
                            // }
                            // else {
                            //     index = perLineSplitByDouhao[0];
                            // }
                            index = parseInt(index);
                            if (perLineSplitByDouhao.length == 3) {
                                if (typeof this.data.source.dimension == "undefined")
                                    this.data.source.dimension = 2;
                                this.data.source.part[Part_name].nodes[index] = [parseFloat(perLineSplitByDouhao[1].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[2].split(/\s+/)[1]), 0.0];
                            }
                            else {
                                if (typeof this.data.source.dimension == "undefined")
                                    this.data.source.dimension = 3;
                                this.data.source.part[Part_name].nodes[index] = [parseFloat(perLineSplitByDouhao[1].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[2].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[3].split(/\s+/)[1])];
                            }
                            continue;
                        }
                        else if (flag_Nset) {
                            if (typeof this.data.source.part[Part_name].Nset == "undefined") {
                                this.data.source.part[Part_name].Nset = {};
                            }
                            this.data.source.part[Part_name].Nset[flag_Nset] = {
                                name: flag_Nset,
                                generate: flag_NsetGenerate,
                                content: [],
                            };
                            for (let i of perLineSplitByDouhao) {
                                this.data.source.part[Part_name].Nset[flag_Nset].content.push(parseInt(i));
                            }
                        }
                        else if (flag_Elset) {
                            if (typeof this.data.source.part[Part_name].Elset == "undefined") {
                                this.data.source.part[Part_name].Elset = {};
                            }
                            if (typeof this.data.source.part[Part_name].Elset[flag_Elset] == "undefined") {
                                this.data.source.part[Part_name].Elset[flag_Elset] = {
                                    name: flag_Elset,
                                    generate: flag_ElsetGenerate,
                                    content: [],
                                };
                            }
                            for (let i of perLineSplitByDouhao) {
                                this.data.source.part[Part_name].Elset[flag_Elset].content.push(parseInt(i));
                            }

                        }
                        else if (flag_Section) {
                            if (perLineSplitByDouhao_0_ByXinghao.length > 1) {//判断split的*分割的数组是否大于1
                                for (let sectionI in perLineSplitByDouhao) {
                                    if (sectionI == 0) {
                                        this.data.source.part[Part_name].Section[flag_Section].type = perLineSplitByDouhao_0_ByXinghao[1];//*Beam Section
                                    }
                                    else if (perLineSplitByDouhao[sectionI].indexOf("elset=") != -1) {
                                        flag_Section_Elset = perLineSplitByDouhao[sectionI].split("=")[1];
                                        if (typeof this.data.source.part[Part_name].Section[flag_Section].elset == "undefined")
                                            this.data.source.part[Part_name].Section[flag_Section].elset = {};
                                        this.data.source.part[Part_name].Section[flag_Section].elset[flag_Section_Elset] = [];
                                    }
                                    else {                                    //elset=Set-14, material=pre-back, temperature=GRADIENTS, section=CIRC
                                        let temp_split = perLineSplitByDouhao[sectionI].split("=");
                                        if (temp_split.length > 1)
                                            this.data.source.part[Part_name].Section[flag_Section][temp_split[0].substr(1)] = temp_split[1];
                                    }
                                }
                            }
                            else {
                                if (this.data.source.part[Part_name].Section[flag_Section].content.length == 0)
                                    this.data.source.part[Part_name].Section[flag_Section].content.push(perLineSplitByDouhao.join(","));

                                if (flag_Section_Elset) this.data.source.part[Part_name].Section[flag_Section].elset[flag_Section_Elset][0] = perLineSplitByDouhao.join(",");
                            }
                        }

                    }
                    else if (Instance_name) {
                        // console.log(item_1);
                        if (perLineSplitByDouhao[0] == "*End Instance") {
                            Instance_name = false;
                            Instance_element = false;
                            Instance_node = false;
                            PartOfInstance_name = false;
                            // Part_name = false;
                            continue;
                        }
                        if (Instance_name && perLineSplitByDouhao.length == 3 && Instance_node === false && Instance_element === false) {
                            this.data.source.instance[Instance_name].transform = [parseFloat(perLineSplitByDouhao[0]), parseFloat(perLineSplitByDouhao[1]), parseFloat(perLineSplitByDouhao[2])];
                        }
                        if (Instance_name && perLineSplitByDouhao.length == 7 && Instance_node === false && Instance_element === false) {
                            this.data.source.instance[Instance_name].xyz1 = [parseFloat(perLineSplitByDouhao[0]), parseFloat(perLineSplitByDouhao[1]), parseFloat(perLineSplitByDouhao[2])];
                            this.data.source.instance[Instance_name].xyz2 = [parseFloat(perLineSplitByDouhao[3]), parseFloat(perLineSplitByDouhao[4]), parseFloat(perLineSplitByDouhao[5])];
                            this.data.source.instance[Instance_name].rotate = parseFloat(perLineSplitByDouhao[6]);
                        }

                        let perLineSplitByDouhao_0_ByXinghao = perLineSplitByDouhao[0].split("\*");
                        // let perLineSplitByDouhao_0_ByMaohao = perLineSplitByDouhao[0].split(":");

                        if (perLineSplitByDouhao_0_ByXinghao[1] == "Node") {
                            flag_node = true;
                            flag_element = false;
                            Instance_node = true;
                            Instance_element = false;
                        }
                        else if (perLineSplitByDouhao_0_ByXinghao[1] == "Element") {
                            flag_node = false;
                            flag_element = true;
                            Instance_node = false;
                            Instance_element = true;
                        }
                        // if (perLineSplitByDouhao_0_ByXinghao[1] == "Node") {
                        //     flag_node = true;
                        //     flag_element = false;
                        //     flag_Elset = false;
                        //     flag_Nset = false;
                        //     Instance_node = true;
                        //     Instance_element = false;
                        //     continue;
                        // }
                        // else if (perLineSplitByDouhao_0_ByXinghao[1] == "Element") {
                        //     // this.data.source.part[Part_name].nodesLength = this.data.source.part[Part_name].nodes.length;
                        //     flag_node = false;
                        //     flag_element = true;
                        //     flag_Elset = false;
                        //     flag_Nset = false;
                        //     Instance_node = false;
                        //     Instance_element = true;
                        //     let kind = perLineSplitByDouhao[1].split("=");
                        //     flag_elementType = kind[1];
                        //     if (typeof this.data.source.instance[Instance_name].element == "undefined") {
                        //         this.data.source.instance[Instance_name].element = {};
                        //     }
                        //     this.data.source.instance[Instance_name].element[kind[1]] = {
                        //         type: flag_elementType,
                        //         element: [],
                        //     };
                        //     continue;
                        // }

                        // if (Instance_element) {
                        //     // let temp2 = perLineSplitByDouhao[0].split(/\s+/);
                        //     if (/^\s+\d+$/.test(perLineSplitByDouhao[0]) || /^\d+$/.test(perLineSplitByDouhao[0])) {
                        //         let temp_element = parseInt(perLineSplitByDouhao[0]);
                        //         if (typeof this.data.source.instance[Instance_name].element[flag_elementType].start == "undefined")
                        //             this.data.source.instance[Instance_name].element[flag_elementType].start = temp_element;
                        //         this.data.source.instance[Instance_name].element[flag_elementType].end = temp_element;
                        //         if (typeof this.data.source.instance[Instance_name].element[flag_elementType].element == "undefined") {
                        //             this.data.source.instance[Instance_name].element[flag_elementType].element = [];
                        //         }
                        //         if (typeof this.data.source.instance[Instance_name].element[flag_elementType].element[temp_element] == "undefined") {
                        //             this.data.source.instance[Instance_name].element[flag_elementType].element[temp_element] = [];
                        //         }
                        //         //根据长度压入element数组
                        //         for (let i = 1; i < perLineSplitByDouhao.length; i++) {
                        //             // this.data.source.part[Part_name].element[temp_element][i-1]=perLineSplitByDouhao[i];//ok
                        //             this.data.source.instance[Instance_name].element[flag_elementType].element[temp_element].push(parseInt(perLineSplitByDouhao[i]));//ok
                        //         }
                        //     }
                        //     else {
                        //         flag_node = false;
                        //         flag_element = false;
                        //         continue;
                        //     }
                        // }
                        // else if (Instance_node) {//vertex 
                        //     if (typeof this.data.source.instance[Instance_name].nodes == "undefined") {
                        //         this.data.source.instance[Instance_name].nodes = [];
                        //     }
                        //     let index = perLineSplitByDouhao[0];
                        //     index = parseInt(index);
                        //     if (perLineSplitByDouhao.length == 3) {
                        //         if (typeof this.data.source.dimension == "undefined")
                        //             this.data.source.dimension = 2;
                        //         this.data.source.instance[Instance_name].nodes[index] = [parseFloat(perLineSplitByDouhao[1].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[2].split(/\s+/)[1]), 0.0];
                        //     }
                        //     else {
                        //         if (typeof this.data.source.dimension == "undefined")
                        //             this.data.source.dimension = 3;
                        //         this.data.source.instance[Instance_name].nodes[index] = [parseFloat(perLineSplitByDouhao[1].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[2].split(/\s+/)[1]), parseFloat(perLineSplitByDouhao[3].split(/\s+/)[1])];
                        //     }
                        //     continue;
                        // }
                    }
                    else if (Material_name) {
                        let temp_Material_name_1 = perLineSplitByDouhao[0].split(",");
                        let temp_Material_name_2 = perLineSplitByDouhao[0].split("\*");

                        if (temp_Material_name_1[0] == "*Material") {
                            subMaterial_name = perLineSplitByDouhao[1].split("name=")[1].replace(/\"/g, "");
                            if (typeof this.data.source.materials[subMaterial_name] == "undefined") {
                                this.data.source.materials[subMaterial_name] = {
                                    name: subMaterial_name,

                                };
                            }
                            continue;
                        }
                        if (temp_Material_name_2[0] == "" && typeof temp_Material_name_2[1] != "undefined") {
                            if (temp_Material_name_2[1] != "") {
                                subMaterial_content_name = temp_Material_name_2[1];
                                continue;
                            }
                        }
                        if (subMaterial_content_name) {
                            if (typeof this.data.source.materials[subMaterial_name][subMaterial_content_name] == "undefined") {
                                this.data.source.materials[subMaterial_name][subMaterial_content_name] = {
                                    name: subMaterial_content_name,
                                    content: []
                                };
                            }
                            this.data.source.materials[subMaterial_name][subMaterial_content_name].content.push(item_1.split("\r")[0]);
                            subMaterial_content_name = false;

                        }
                    }
                    else if (BOUNDARY_name) {//全局的在这里，step的在step中

                        if (perLineSplitByDouhao[0] == "** PREDEFINED FIELDS" || perLineSplitByDouhao[0] == "** OUTPUT REQUESTS") {//结束boundary的条件之一，是否还有其他，待定
                            BOUNDARY_name = false;
                            continue;
                        }
                        let temp_BOUNDARY_name_1 = perLineSplitByDouhao[0].split(/\s+/);
                        let temp_BOUNDARY_name_2 = perLineSplitByDouhao[0].split("*");
                        if (temp_BOUNDARY_name_1.length == 5 && temp_BOUNDARY_name_1[0] == "**" && (temp_BOUNDARY_name_1[1] == "Name:" || temp_BOUNDARY_name_1[1] == "Interaction:")) {
                            subBOUNDARY_name = temp_BOUNDARY_name_1[2];
                            subBOUNDARY_type = temp_BOUNDARY_name_1[4] ? temp_BOUNDARY_name_1[4] : false;

                            if (typeof this.data.source.boundary[subBOUNDARY_name] == "undefined") {
                                this.data.source.boundary[subBOUNDARY_name] = {
                                    name: subBOUNDARY_name,
                                };
                                if (subBOUNDARY_type) {
                                    this.data.source.boundary[subBOUNDARY_name].type = subBOUNDARY_type;
                                }
                            }
                            continue;
                        }
                        // if (subBOUNDARY_name && (perLineSplitByDouhao[0] == "*Boundary" || perLineSplitByDouhao[0] == "*Fluid Boundary")) {
                        if (subBOUNDARY_name && temp_BOUNDARY_name_2.length > 1 && temp_BOUNDARY_name_2[0] == "" && perLineSplitByDouhao[0].indexOf("**") == "-1") {
                            // subBOUNDARY_flag = true;
                            subBOUNDARY_flag = perLineSplitByDouhao[0].substr(1, perLineSplitByDouhao[0].length);//boundray的种类不同
                            if (typeof this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                                this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                                this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                                for (let ti = 1; ti < perLineSplitByDouhao.length; ti++) {
                                    let temp_4 = perLineSplitByDouhao[ti].split("=");
                                    if (temp_4.length > 1) {
                                        this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[1];
                                    }
                                    else {
                                        this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[0].substr(1);
                                    }
                                }
                            }
                            continue;
                        }
                        if (subBOUNDARY_flag && temp_BOUNDARY_name_1[0] !== "**") {
                            if (subBOUNDARY_flag) {
                                // if (typeof this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                                //     this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                                //     this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [item_1.split('\r')[0]];
                                // }
                                // else {
                                this.data.source.boundary[subBOUNDARY_name][subBOUNDARY_flag].content.push(item_1.split("\r")[0]);
                                // }
                            }
                        }

                    }
                    else if (Step_name) {
                        let temp_Step_name_1 = perLineSplitByDouhao[0].split(",");
                        let temp_Step_name_2 = perLineSplitByDouhao[0].split(":");

                        if (perLineSplitByDouhao[0] == "*Step") {

                            subStep_name.name = perLineSplitByDouhao[1].split("name=")[1];
                            subStep_name.step = {
                                name: perLineSplitByDouhao[1].split("name=")[1],
                                nlgeom: perLineSplitByDouhao[2].split("nlgeom=")[1],
                            };
                            // subStep_name.step.push(perLineSplitByDouhao.join(","));
                            flag_subStep_Name = true;
                            continue;
                        }

                        else if (perLineSplitByDouhao[0] == "** LOADS") {
                            subStep_name.loads = {};
                            // subStep_name.loads.push(item_1.split('\r')[0]);
                            flag_subStep_LOADS = true;
                            flag_subStep_Name = false;
                            flag_subStep_INTERACTIONS = false;
                            flag_loadName = false;
                            flag_loadName_content = false;
                            flag_subStep_Name = false;
                            BOUNDARY_name_inStep = false;
                            continue;
                        }
                        else if (perLineSplitByDouhao[0] == "** INTERACTIONS" /*||temp_3[0] == '** Interaction'*/) {
                            // subStep_name.INTERACTIONS = [];
                            subStep_name.INTERACTIONS = {};
                            // subStep_name.INTERACTIONS.push(item_1.split('\r')[0]);
                            flag_subStep_INTERACTIONS = true;
                            subINTERACTIONS = false;
                            subINTERACTIONS_kind = false;

                            flag_subStep_LOADS = false;
                            flag_loadName = false;
                            flag_loadName_content = false;
                            flag_subStep_Name = false;
                            BOUNDARY_name_inStep = false;
                            continue;
                        }
                        else if (perLineSplitByDouhao[0] == "** OUTPUT REQUESTS") {
                            subStep_name.outputRequests = [];
                            subStep_name.outputRequests.push(item_1.split("\r")[0]);
                            flag_subStep_LOADS = false;
                            flag_subStep_INTERACTIONS = false;
                            flag_subStep_OUTPUTREQUESTS = true;
                            BOUNDARY_name_inStep = false;
                            continue;
                        }
                        else if (temp_Step_name_2[0] == "** FIELD OUTPUT") {
                            subStep_name.fieldoutput = [];
                            subStep_name.fieldoutput.push(item_1.split("\r")[0]);
                            flag_subStep_FIELDOUTPUT = true;
                            flag_subStep_OUTPUTREQUESTS = false;
                            BOUNDARY_name_inStep = false;
                            continue;
                        }
                        else if (temp_Step_name_2[0] == "** HISTORY OUTPUT") {
                            subStep_name.historyoutput = [];
                            subStep_name.historyoutput.push(item_1.split("\r")[0]);
                            flag_subStep_FIELDOUTPUT = false;
                            flag_subStep_HISTORYOUTPUT = true;
                            BOUNDARY_name_inStep = false;
                            continue;
                        }
                        else if (BOUNDARY_name_inStep) {//边界
                            flag_subStep_Name = false;
                            if (typeof subStep_name.boundary == "undefined")
                                subStep_name.boundary = {};
                            // continue;
                        }


                        if (perLineSplitByDouhao[0] != "** " && perLineSplitByDouhao[0] != "*End Step") {

                            if (flag_subStep_Name) {
                                let temp_first_star = perLineSplitByDouhao[0].split("*");
                                if (temp_first_star.length > 1 && temp_first_star[0] == "") {
                                    flag_substar = temp_first_star[1];
                                    if (typeof subStep_name.step[flag_substar] == "undefined") {
                                        subStep_name.step[flag_substar] = {};
                                    }
                                    subStep_name.step[flag_substar][perLineSplitByDouhao[0].substr(1)] = perLineSplitByDouhao[1] ? perLineSplitByDouhao[1].substr(1) : "";//消除空格,同时可以没有第二个值

                                    continue;
                                }
                                else {
                                    if (typeof subStep_name.step[flag_substar].content == "undefined") {
                                        subStep_name.step[flag_substar].content = [];
                                    }
                                    subStep_name.step[flag_substar].content.push(item_1.split("\r")[0]);
                                    continue;
                                }
                            }
                            else if (BOUNDARY_name_inStep) {//边界，step中的boundary的标志位
                                if (perLineSplitByDouhao[0].indexOf(":") != -1) {//判断boundary的名称，//** Name: Disp-BC-3 Type: 位移/转角
                                    let temp_BOUNDARY_name_inStep_1 = perLineSplitByDouhao[0].split(":");
                                    if (temp_BOUNDARY_name_inStep_1[0].indexOf("**") != -1) {
                                        let temp_BOUNDARY_name_inStep_2 = temp_BOUNDARY_name_inStep_1[1].split(/\s+/)[1];//.substr(1);
                                        // if (temp_2.length == 5 && temp_2[0] == "**" && (temp_2[1] == "Name:" || temp_2[1] == "Interaction:")) {
                                        subBOUNDARY_name = temp_BOUNDARY_name_inStep_2;
                                        if (temp_BOUNDARY_name_inStep_1.length > 2) {
                                            subBOUNDARY_type = temp_BOUNDARY_name_inStep_1[2];
                                        }
                                        else {
                                            subBOUNDARY_type = false;
                                        }

                                        if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {
                                            subStep_name.boundary[subBOUNDARY_name] = {
                                                name: subBOUNDARY_name,
                                            };
                                            if (subBOUNDARY_type) {
                                                subStep_name.boundary[subBOUNDARY_name].type = subBOUNDARY_type;
                                            }
                                        }
                                        continue;
                                    }
                                }

                                // if (subBOUNDARY_name && (perLineSplitByDouhao[0] == "*Boundary" || perLineSplitByDouhao[0] == "*Fluid Boundary")) {
                                let temp_b_kind = perLineSplitByDouhao[0].split("*");//具体内容 //*Boundary, op=NEW
                                if (subBOUNDARY_name && temp_b_kind.length > 1 && temp_b_kind[0] == "" && perLineSplitByDouhao[0].indexOf("**") == "-1") {//处理boundary后的的name部分
                                    // subBOUNDARY_flag = true;
                                    subBOUNDARY_flag = perLineSplitByDouhao[0].substr(1, perLineSplitByDouhao[0].length);//boundray的种类不同  //perLineSplitByDouhao[0]是‘，’分割的,//subBOUNDARY_flag=Boundary
                                    // if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {//增加的
                                    //     subStep_name.boundary[subBOUNDARY_name] = {};
                                    if (typeof subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag] == "undefined") {
                                        subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag] = {};
                                        subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                                        for (let ti = 1; ti < perLineSplitByDouhao.length; ti++) {
                                            if (perLineSplitByDouhao[ti].indexOf("=") != -1) {
                                                let temp_4 = perLineSplitByDouhao[ti].split("=");
                                                subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag][temp_4[0].substr(1)] = temp_4[1];
                                            }
                                            else {
                                                subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag][perLineSplitByDouhao[ti].substr(1)] = perLineSplitByDouhao[ti].substr(1);
                                            }
                                        }
                                    }
                                    // }
                                    continue;
                                }


                                if (subBOUNDARY_flag && temp_Step_name_1[0] !== "**") {//处理boundary属性之后的数值部分，一行或多行，//temp是“，”分割，多次分割perLineSplitByDouhao 也是，'**'多次还是'**'(非终止)
                                    if (subBOUNDARY_flag) {
                                        if (typeof subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content == "undefined")
                                            subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content = [];
                                        subStep_name.boundary[subBOUNDARY_name][subBOUNDARY_flag].content.push(item_1.split("\r")[0]);

                                    }

                                }
                            }
                            else if (flag_subStep_LOADS) {
                                // subStep_name.loads.push(item_1.split('\r')[0]);
                                let temp_first_star = perLineSplitByDouhao[0].split(":");
                                if (temp_first_star.length > 1 && temp_first_star[0] == "** Name") {
                                    flag_loadName = temp_first_star[1].split(/\s+/)[1];
                                    let loadType = temp_first_star[2].substr(1);
                                    if (typeof subStep_name.loads[flag_loadName] == "undefined") {
                                        subStep_name.loads[flag_loadName] = {
                                            name: flag_loadName,
                                            type: loadType,
                                        };
                                    }
                                    continue;
                                }
                                else {
                                    if (typeof subStep_name.loads[flag_loadName].content == "undefined") {
                                        subStep_name.loads[flag_loadName].content = {};
                                    }
                                    let temp_first_star = perLineSplitByDouhao[0].split("*");

                                    if (temp_first_star.length > 1 && temp_first_star[0] == "") {
                                        flag_loadName_content = temp_first_star[1];
                                        continue;
                                    }

                                    subStep_name.loads[flag_loadName].content[flag_loadName_content] = item_1.split("\r")[0];
                                    continue;
                                }

                            }
                            else if (flag_subStep_INTERACTIONS) {
                                // subStep_name.INTERACTIONS.push(item_1.split('\r')[0]);
                                if (perLineSplitByDouhao[0].indexOf(":") != -1) {
                                    let temp_flag_subStep_INTERACTIONS_1 = perLineSplitByDouhao[0].split(":");
                                    if (temp_flag_subStep_INTERACTIONS_1[0].indexOf("**") != -1) {
                                        let temp_flag_subStep_INTERACTIONS_2 = temp_flag_subStep_INTERACTIONS_1[1].split(/\s+/)[1];//.substr(1);
                                        subINTERACTIONS = temp_flag_subStep_INTERACTIONS_2;

                                        if (typeof subStep_name.INTERACTIONS[subINTERACTIONS] == "undefined") {
                                            subStep_name.INTERACTIONS[subINTERACTIONS] = {
                                                name: subINTERACTIONS,
                                            };
                                        }
                                        continue;
                                    }
                                }
                                let temp_b_kind = perLineSplitByDouhao[0].split("*");
                                if (subINTERACTIONS && temp_b_kind.length > 1 && temp_b_kind[0] == "" && perLineSplitByDouhao[0].indexOf("**") == "-1") {
                                    subINTERACTIONS_kind = perLineSplitByDouhao[0].substr(1, perLineSplitByDouhao[0].length);//boundray的种类不同
                                    // if (typeof subStep_name.boundary[subBOUNDARY_name] == "undefined") {//增加的
                                    //     subStep_name.boundary[subBOUNDARY_name] = {};
                                    if (typeof subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind] == "undefined") {
                                        subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind] = {};
                                        subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind].content = [];
                                        for (let ti = 1; ti < perLineSplitByDouhao.length; ti++) {


                                            if (perLineSplitByDouhao[ti].indexOf("=") != -1) {
                                                let temp_4 = perLineSplitByDouhao[ti].split("=");
                                                subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind][temp_4[0].substr(1)] = temp_4[1];
                                            }
                                            else {
                                                subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind][perLineSplitByDouhao[ti].substr(1)] = perLineSplitByDouhao[ti].substr(1);
                                            }
                                        }
                                    }
                                    continue;
                                }
                                if (subINTERACTIONS && subINTERACTIONS_kind) {
                                    subStep_name.INTERACTIONS[subINTERACTIONS][subINTERACTIONS_kind].content.push(item_1.split("\r")[0]);
                                }
                                continue;
                            }
                            else if (flag_subStep_OUTPUTREQUESTS) {
                                subStep_name.outputRequests.push(item_1.split("\r")[0]);
                                continue;
                            }
                            else if (flag_subStep_FIELDOUTPUT) {
                                subStep_name.fieldoutput.push(item_1.split("\r")[0]);
                                continue;
                            }
                            else if (flag_subStep_HISTORYOUTPUT) {
                                subStep_name.historyoutput.push(item_1.split("\r")[0]);
                                continue;
                            }
                        }
                        else if (perLineSplitByDouhao[0] == "*End Step") {
                            this.data.source.step.push(subStep_name);
                            subStep_name = false;
                            flag_subStep_Name = false;
                            BOUNDARY_name = false;
                            flag_subStep_LOADS = false;
                            flag_subStep_INTERACTIONS = false;
                            flag_subStep_OUTPUTREQUESTS = false;
                            flag_subStep_FIELDOUTPUT = false;
                            flag_subStep_HISTORYOUTPUT = false;
                            BOUNDARY_name_inStep = false;
                        }
                    }
                    else if (Assembly_name) {
                        let temp_Assembly_name_1 = perLineSplitByDouhao[0].split("\*");
                        if (temp_Assembly_name_1[1] == "Nset") {
                            if (Assembly_Nset !== false) {
                                Assembly_Nset = false;
                                Assembly_instance = false;
                            }
                            Assembly_Nset = perLineSplitByDouhao[1].replace(/\s+/g, "").split("nset=")[1];

                            if (typeof perLineSplitByDouhao[2] == "undefined") {

                                Assembly_Nset = false;
                                Assembly_instance = false;
                                continue;
                            }
                            if (perLineSplitByDouhao[2] == " internal") {
                                Assembly_instance = perLineSplitByDouhao[3].split("instance=")[1].replace(/\"/g, "").toLowerCase();
                            }
                            else
                                Assembly_instance = perLineSplitByDouhao[2].split("instance=")[1].replace(/\"/g, "").toLowerCase();


                            Assembly_Elset = false;
                            if (typeof this.data.source.assembly.Nset[Assembly_Nset] == "undefined") {
                                this.data.source.assembly["Nset"][Assembly_Nset] = {};
                            }
                            this.data.source.assembly["Nset"][Assembly_Nset][Assembly_instance] = {
                                content: []
                            };// per instance of content ,array


                            // Assembly_instance = false;
                            // Assembly_Nset = false;
                            Assembly_Elset = false;
                            Assembly_Surface = false;
                            Assembly_Surface_type = false;
                            continue;
                        }
                        else if (temp_Assembly_name_1[1] == "Elset") {
                            Assembly_instance = false;
                            Assembly_Nset = false;
                            Assembly_Elset = false;
                            Assembly_Surface = false;
                            Assembly_Surface_type = false;

                            for (let ELS_i = 1; ELS_i < perLineSplitByDouhao.length; ELS_i++) {
                                let per = perLineSplitByDouhao[ELS_i];
                                if (per.indexOf("elset=") != -1) {
                                    Assembly_Elset = per.split("elset=")[1];
                                    if (typeof this.data.source.assembly["Elset"][Assembly_Elset] == "undefined") {
                                        this.data.source.assembly.Elset[Assembly_Elset] = {};
                                    }
                                }
                                else if (per.indexOf("instance=") != -1) {
                                    Assembly_instance = per.split("instance=")[1].replace(/\"/g, "").toLowerCase();
                                    this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance] = { content: [] };// per instance of content ,array
                                    let abc = 1;
                                }
                                else if (per.indexOf("=") != -1) {
                                    let temp_set_1 = per.replace(/\s+/g, "").split("=");
                                    if (Assembly_instance) {
                                        this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance][temp_set_1[0]] = temp_set_1[1];
                                    }
                                    else {
                                        this.data.source.assembly["Elset"][Assembly_Elset][temp_set_1[0]] = temp_set_1[1];
                                    }
                                }
                                else {
                                    let temp_set_1 = per.replace(/\s+/g, "");
                                    if (Assembly_instance) {
                                        this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance][temp_set_1] = true;
                                    }
                                    else {
                                        this.data.source.assembly["Elset"][Assembly_Elset][temp_set_1] = true;
                                    }
                                }
                            }


                            continue;
                        }
                        else if (temp_Assembly_name_1[1] == "Surface") {
                            Assembly_instance = false;
                            Assembly_Nset = false;
                            Assembly_Elset = false;
                            Assembly_Surface = perLineSplitByDouhao[2].replace(/\s+/g, "").split("name=")[1];
                            Assembly_Surface_type = perLineSplitByDouhao[1].replace(/\s+/g, "").split("type=")[1];
                            if (typeof this.data.source.assembly.surface[Assembly_Surface] == "undefined") {
                                this.data.source.assembly.surface[Assembly_Surface] = {};
                            }
                            this.data.source.assembly.surface[Assembly_Surface] = {
                                name: Assembly_Surface,
                                type: Assembly_Surface_type,
                                content: [],
                            };

                            continue;
                        }
                        else if (perLineSplitByDouhao[0] == "*End Assembly") {
                            Assembly_name = false;
                            Assembly_instance = false;
                            Assembly_Nset = false;
                            Assembly_Elset = false;
                            Assembly_Surface = false;
                            Assembly_Surface_type = false;
                            continue;
                        }
                        if (Assembly_Nset) {
                            for (let i of perLineSplitByDouhao) {
                                // this.data.source.assembly["Nset"][Assembly_Nset].content.push(parseInt(i));
                                // console.log(item_1);
                                if (!isNaN(parseInt(i)))
                                    this.data.source.assembly["Nset"][Assembly_Nset][Assembly_instance].content.push(parseInt(i));
                            }
                        }
                        else if (Assembly_Elset) {
                            for (let i of perLineSplitByDouhao) {
                                if (!isNaN(parseInt(i)))
                                    this.data.source.assembly["Elset"][Assembly_Elset][Assembly_instance].content.push(parseInt(i));
                            }
                        }
                        else if (Assembly_Surface) {
                            this.data.source.assembly.surface[Assembly_Surface].content.push(perLineSplitByDouhao.join(","));
                        }
                    }


                }
                // return JSON.parse(JSON.stringify(this.data.source));
                return true;
            }
            catch (err) {
                this.log({
                    type: "error",
                    subject: "abaqus inp file getmesh has a error.",
                    data: err,
                });
                return false;
            }
        }
        else {
            return false;
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // element 
    C2D9(Part_name, type) {//二维二次9点

    }
    newC3D8R(Part_name, type) {
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];
            //12345678顺序是abquas的
            // let lines = [
            //     e1, e4, e4, e3, e3, e2, e2, e1,//right
            //     e5, e8, e8, e7, e7, e6, e6, e5,//left
            //     e5, e1, e2, e6, e3, e7, e4, e8,//other
            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            mesh.network.push({
                index: element,
                // xyz: [nodes[e1], nodes[e2], nodes[e3], nodes[e4], nodes[e5], nodes[e6], nodes[e7], nodes[e8]],
                face: "6"
            });

            let box = [
                [e5, e1, e2, e6],//f
                [e8, e4, e3, e7],//b
                [e6, e2, e3, e7],//U
                [e5, e1, e4, e8],//D
                [e8, e5, e6, e7],//L
                [e4, e1, e2, e3],//R
            ];
            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        let indices = {
            index: [],
            indicesOfObject: {
            },
            xyz: [],
            uv: [],
            normal: [],
            cm: [],
        };
        //index
        let i1i = 0;
        let frameLinesTemp = {};
        for (let i1 in cubeFace) {
            let perFace = cubeFace[i1];
            if (perFace.enable) {
                for (let j1 of perFace.points) {
                    if (typeof indices.indicesOfObject[j1] == "undefined") {
                        indices.index.push(j1);
                        indices.xyz.push(nodes[j1][0], nodes[j1][1], nodes[j1][2]);
                        indices.indicesOfObject[j1] = i1i++;
                    }
                }
                let points = perFace.points;
                if (mesh.triangles.indices == false) {
                    mesh.triangles.indices = [];
                }
                mesh.triangles.indices.push(indices.indicesOfObject[points[0]], indices.indicesOfObject[points[1]], indices.indicesOfObject[points[3]], indices.indicesOfObject[points[1]], indices.indicesOfObject[points[2]], indices.indicesOfObject[points[3]]);
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];
                mesh.shellPoints.index[points[3]] = nodes[points[3]];
                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[3]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[3]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[3], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[3], points[0]],
                        enable: true,
                    };
                }

            }
        }
        for (let i2 = 0; i2 < indices.index.length - 1; i2 += 3) {
            indices.uv.push(
                0, 0,
                1, 0,
                1, 1);
            indices.normal.push(
                0, 0, 1,
                0, 0, 1,
                0, 0, 1);
            indices.cm.push(
                this.defaultColorR, this.defaultColorG, this.defaultColorB,
                this.defaultColorR, this.defaultColorG, this.defaultColorB,
                this.defaultColorR, this.defaultColorG, this.defaultColorB);
        }
        //output


        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                if (mesh.frameLines.indices == false) {
                    mesh.frameLines.indices = [];
                }
                // mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.indices.push(indices.indicesOfObject[perLine.points[0]], indices.indicesOfObject[perLine.points[1]]);
            }
        }
        mesh.triangles.index = indices.index;
        mesh.triangles.xyz = indices.xyz;
        mesh.triangles.uv = indices.uv;
        mesh.triangles.normal = indices.normal;
        mesh.triangles.cm = indices.cm;

        mesh.frameLines.index = indices.index;
        mesh.frameLines.xyz = indices.xyz;



    }
    C3D8R(Part_name, type) {
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            let e5 = element[4];
            let e6 = element[5];
            let e7 = element[6];
            let e8 = element[7];
            //12345678顺序是abquas的
            // let lines = [
            //     e1, e4, e4, e3, e3, e2, e2, e1,//right
            //     e5, e8, e8, e7, e7, e6, e6, e5,//left
            //     e5, e1, e2, e6, e3, e7, e4, e8,//other
            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            mesh.network.push({
                index: element,
                // xyz: [nodes[e1], nodes[e2], nodes[e3], nodes[e4], nodes[e5], nodes[e6], nodes[e7], nodes[e8]],
                face: "6"
            });

            let box = [
                [e5, e1, e2, e6],//f
                [e8, e4, e3, e7],//b
                [e6, e2, e3, e7],//U
                [e5, e1, e4, e8],//D
                [e8, e5, e6, e7],//L
                [e4, e1, e2, e3],//R
            ];
            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        //output
        let frameLinesTemp = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            if (perFace.enable) {
                let points = perFace.points;
                mesh.triangles.index.push(points[0], points[1], points[3], points[1], points[2], points[3]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1,

                    0, 0,
                    1, 0,
                    1, 1);
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,

                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];
                mesh.shellPoints.index[points[3]] = nodes[points[3]];
                // mesh.triangles.pressure.push();

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[3]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[3]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[3], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[3], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
            }
        }

    }
    C3D4(Part_name, type,) {
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            // //12345678顺序是abquas的
            // let lines = [
            //     e1, e2,
            //     e2, e3,
            //     e3, e1,

            //     e1, e4,
            //     e2, e4,
            //     e3, e4,
            // ];

            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            mesh.network.push({
                index: element,
                // xyz: [nodes[e1], nodes[e2], nodes[e3], nodes[e4]],
                face: "4"
            });

            let faceAll = [
                [e1, e2, e3],
                [e1, e2, e4],
                [e2, e3, e4],
                [e3, e1, e4],
            ];
            for (let side of faceAll) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        //output
        let frameLinesTemp = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            if (perFace.enable) {
                let points = perFace.points;
                mesh.triangles.index.push(points[0], points[1], points[3], points[1], points[2], points[3]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1);
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }

                per = JSON.parse(JSON.stringify([points[2], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[3], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
            }
        }
    }
    C3D10(Part_name, type) {
        this.C3D4(Part_name, type);
    }
    T3D2(Part_name, type) {
        this.B31(Part_name, type);
    }
    B31(Part_name, type) {
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            //12345678顺序是abquas的
            let box = [
                [e1, e2],
            ];
            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        //output
        for (let i in cubeFace) {
            let perOne = cubeFace[i];
            if (perOne.enable) {
                let points = perOne.points;
                mesh.lines.index.push(points[0], points[1]);
                mesh.lines.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                );
                mesh.lines.color.push(this.colors.line[0], this.colors.line[1], this.colors.line[2]);
                mesh.lines.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
            }
        }
    }

    CPE3(Part_name, type) {
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            //shell face don't has network
            // let lines = [
            //     e1, e2, e2, e3, e3, e1
            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            let box = [
                [e1, e2, e3],
            ];
            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        //output
        let frameLinesTemp = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            if (perFace.enable) {
                let points = perFace.points;
                mesh.triangles.index.push(points[0], points[1], points[2], points[1], points[2], points[0]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1);
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];
                // mesh.triangles.pressure.push();

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
            }
        }
    }
    CPE4(Part_name, type) {
        this.S4(Part_name, type);
    }
    S3(Part_name, type) {
        this.CPE3(Part_name, type);
    }
    S4(Part_name, type) {//四边形，同六面体
        //init
        if (typeof this.data.source.part[Part_name].PLTFR == "undefined") {//有索引的体外面的json
            this.data.source.part[Part_name].PLTFR = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let cubeFace = {};
        let allElements = this.data.source.part[Part_name].element[type].element;
        let nodes = this.data.source.part[Part_name].nodes;
        let mesh = this.data.source.part[Part_name].PLTFR;

        //analysis 
        for (let i in allElements) {//part 的 element 循环
            let element = allElements[i];//element的 单体
            let e1 = element[0];//element 的点序列，矩形体
            let e2 = element[1];
            let e3 = element[2];
            let e4 = element[3];
            //shell面，无网格
            // let lines = [
            //     e1, e2, e2, e3, e3, e4, e4, e1
            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            let box = [
                [e1, e2, e3, e4],
            ];
            for (let side of box) {//消隐
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        //output
        let frameLinesTemp = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            if (perFace.enable) {
                let points = perFace.points;
                mesh.triangles.index.push(points[0], points[1], points[3], points[1], points[2], points[3]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1,

                    0, 0,
                    1, 0,
                    1, 1);
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,

                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];
                mesh.shellPoints.index[points[3]] = nodes[points[3]];
                // mesh.triangles.pressure.push();

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[3]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[3]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[3], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[3], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
                // mesh.frameLines.color.push(this.colors.frameLine[0], this.colors.frameLine[1], this.colors.frameLine[2]);
            }
        }
    }
    FC3D8(Part_name, type) {
        this.C3D8R(Part_name, type);
    }
}
export { MeshAbaqus };