import {
    Vector3,
    PlaneGeometry,
    DoubleSide,
    Plane,
    Triangle,
    Ray,
    Mesh,
    MeshBasicMaterial,
    Box3,
} from "three";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper.js";

class ClippingThree {

    constructor(inputValue) {
        this.parent = inputValue.parent;
        this.enable = inputValue.enable ? true : false;
        this.global = false;//inputValue.global ? true : false;
        this.type = "free";//one ,three ,free
        this.clippingObject = inputValue.clippingObject ? inputValue.clippingObject : this.parent.CMs[0];

        this.scene = this.parent.worldBox;
        this.plane = false;
        // this.localPlane = false;
        this.rayTrianglePA = false;
        this.rayTrianglePB = false;
        this.rayTrianglePC = false;


        this.init(this.clippingObject, this.enable, this.global);

        return this;
    }

    setEnable(enable = false) {
        if (typeof this.localPlane == "undefined") {
            return false;
        }

        this.enable = enable;
        if (enable) {
            if (this.plane) this.plane.visible = true;
            this.parent.renderer.localClippingEnabled = true;
            this.parent.control.transform.attach(this.plane);
            this.clippingObject.setClipping(this.localPlane);
            this.parent.renderer.localClippingEnabled = true;
            this.clippingObject.clippingShow();
            // this.clippingObject.repairPoly(true);
        }
        else {
            if (this.plane) this.plane.visible = false;
            this.parent.renderer.localClippingEnabled = false;
            this.parent.control.transform.detach();
            this.clippingObject.setClipping(false);
            this.clippingObject.clippingHide();
            // this.clippingObject.repairPoly(false);
        }
        this.TransformRender(this);
        this.parent.render();
    }

    // init clipping 
    init(object, enable, global = false) {
        let that = this;
        if (enable) {
            if (typeof this.localPlane == "undefined") {
                let box3 = new Box3();
                box3.expandByObject(object.getCMObject());

                let x = box3.max.x - box3.min.x;
                let y = box3.max.y - box3.min.y;
                let z = box3.max.z - box3.min.z;
                let max = x > y ? x : y;
                max = max > z ? max : z;

                const geometry = new PlaneGeometry(max * 1.3, max * 1.3, 1, 1);
                const material = new MeshBasicMaterial({ color: 0x0000ff, side: DoubleSide, wireframe: true });
                this.plane = new Mesh(geometry, material);
                this.plane.rotateY(Math.PI);
                this.scene.add(this.plane);
                this.parent.control.transform.attach(this.plane);

                this.rayDirection = new Vector3(0, 0, 1);
                this.raySource = new Vector3(0, 0, 0);
                this.ray = new Ray(this.raySource, this.rayDirection);

                this.rayTrianglePA = new Vector3(this.plane.geometry.attributes.position[0], this.plane.geometry.attributes.position[1], this.plane.geometry.attributes.position[2]);
                this.rayTrianglePA.applyMatrix4(this.plane.matrix);
                this.rayTrianglePB = new Vector3(this.plane.geometry.attributes.position[6], this.plane.geometry.attributes.position[7], this.plane.geometry.attributes.position[7]);
                this.rayTrianglePB.applyMatrix4(this.plane.matrix);
                this.rayTrianglePC = new Vector3(this.plane.geometry.attributes.position[3], this.plane.geometry.attributes.position[4], this.plane.geometry.attributes.position[5]);
                this.rayTrianglePC.applyMatrix4(this.plane.matrix);
                this.rayTriangle = new Triangle(this.rayTrianglePA, this.rayTrianglePB, this.rayTrianglePC);
                ///////////////////////////////////////////////////////////////////
                this.pointOfNarmal = new Vector3(0, 0, 1);
                this.localPlane = new Plane(this.pointOfNarmal, 0);//init clip,固定名称
                this.rayTriangle.getPlane(this.localPlane);
                // if (typeof object.setClipping == "function")
                //     object.setClipping(this.localPlane);
                this.parent.renderer.localClippingEnabled = true;
            }
            else {
                if (this.plane) this.plane.visible = true;
                this.parent.renderer.localClippingEnabled = true;
                this.parent.control.transform.attach(this.plane);
            }
            // if (typeof object.repairPoly == "function")
            //     object.repairPoly();
            // this.TransformRender(that);
        }
        else {
            if (this.plane) this.plane.visible = false;
            this.parent.renderer.localClippingEnabled = false;
            this.parent.control.transform.detach();
            // if (typeof object.setClipping == "function")
            //     object.setClipping(false);
        }
        this.parent.render();
    }
    //
    reinitClipData() {
        this.plane.material.color.r = 0;
        this.plane.material.color.g = 0;
        this.plane.material.color.b = 1;
        this.plane.rotation._x = 0;
        this.plane.rotation._y = 0;
        this.plane.rotation._z = 0;
        this.plane.position.x = 0;
        this.plane.position.y = 0;
        this.plane.position.z = 0;
    }

    //update clipping 
    TransformRender() {//update for geometry
        if (this.rayTriangle) {
            this.planehelper = new VertexNormalsHelper(this.plane, 1, 0x00ff00);
            let Nx = this.planehelper.geometry.attributes.position.array[3] - this.planehelper.geometry.attributes.position.array[0];
            let Ny = this.planehelper.geometry.attributes.position.array[4] - this.planehelper.geometry.attributes.position.array[1];
            let Nz = this.planehelper.geometry.attributes.position.array[5] - this.planehelper.geometry.attributes.position.array[2];

            this.rayTrianglePA.x = this.plane.geometry.attributes.position.array[0];
            this.rayTrianglePA.y = this.plane.geometry.attributes.position.array[1];
            this.rayTrianglePA.z = this.plane.geometry.attributes.position.array[2];
            this.rayTrianglePA.applyMatrix4(this.plane.matrix);

            this.rayTrianglePB.x = this.plane.geometry.attributes.position.array[6];
            this.rayTrianglePB.y = this.plane.geometry.attributes.position.array[7];
            this.rayTrianglePB.z = this.plane.geometry.attributes.position.array[8];
            this.rayTrianglePB.applyMatrix4(this.plane.matrix);

            this.rayTrianglePC.x = this.plane.geometry.attributes.position.array[3];
            this.rayTrianglePC.y = this.plane.geometry.attributes.position.array[4];
            this.rayTrianglePC.z = this.plane.geometry.attributes.position.array[5];
            this.rayTrianglePC.applyMatrix4(this.plane.matrix);

            this.rayTriangle.getPlane(this.localPlane);
            if (Nx > 0) {
                this.plane.material.color.r = 0;
            } else {
                this.plane.material.color.r = 1;
            }
            if (Ny > 0) {
                this.plane.material.color.g = 0;
            } else {
                this.plane.material.color.g = 1;
            }
            if (Nz > 0) {
                this.plane.material.color.b = 0;
            }
            else {
                this.plane.material.color.b = 1;
            }
            if (this.parent.needsUpdate === false)
                this.parent.render();
            if (typeof this.clippingObject.repairPoly == "function")
                this.clippingObject.repairPoly();
        }
    }
}

export { ClippingThree };