

class AnalysisMidasMGT {

    constructor(parent, callbacks) {
        this.parent = parent;
        this.callbacks = callbacks;
        this.readyForAnlysis = 0;
        this.step = 0;

        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;

        this.data = {
            source: {
                type: "midasMGT"
            },
            drawTree: {
                midasMGT: {
                    type:"PLTFR",
                    matrix:false,
                    // points: {
                    //     index: [],
                    //     xyzs: [],
                    //     // res: {
                    //     //     1: {
                    //     //         index: { 1: 1, 3: 2, 6: 3 },
                    //     //         pressures: {
                    //     //             "U.U1": []
                    //     //         }
                    //     //     }
                    //     // }
                    // },
                    lines: {
                        index: [],
                        xyz: [],
                        // res: {
                        //     1: {
                        //         index: { 1: 1, 3: 2, 6: 3 },
                        //         pressures: {
                        //             "U.U1": []
                        //         }
                        //     }
                        // }
                    },
                    // triangle: {
                    //     index: [],
                    //     xyzs: [],
                    //     // res: {
                    //     //     1: {
                    //     //         index: { 1: 1, 3: 2, 6: 3 },
                    //     //         pressures: {
                    //     //             "U.U1": []
                    //     //         }
                    //     //     }
                    //     // }
                    // },
                    // frameLine: {
                    //     index: [],
                    //     xyzs: []
                    // },
                },
                type:"PLTFR"
            },
        };
        this.query = {};
    }
    async do(data) {
        if (data) {
            this.data.source = data.data.source;

        }


        this.calculateMesh();
        this.returnAnalysisData();

    }


    calculateMesh() {
        let nodes = this.data.source.nodes;
        let elements = this.data.source.elements;
        let drawTree = this.data.drawTree.midasMGT;
        // let sections = this.data.source.source.sections;
        // this.data.source.struct = {
        //     elements: [],
        //     covers: [//截面线条需要在此实现
        //         // "1": {//node id
        //         //     points: [],////[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
        //         //     index: [],///每个三角形的三个点的index
        //         // }
        //     ],
        //     frameLine: [//不包含截面线条
        //         // "1": {
        //         //     points: [],////[0]=[x,y,z],相对值（来源于section），需要增加node的xyz
        //         //     index: [],///每个三角形的三个点的index
        //         // }
        //     ]
        // };
        // let structElements = this.data.source.struct.elements;
        // let structCovers = this.data.source.struct.covers;
        // let structFrameLine = this.data.source.struct.frameLine;


        for (let Ei in elements) {
            let perElement = elements[Ei];
            if (perElement.type == "BEAM" || perElement.type == "TENSTR") {

                let perElementNodes = perElement.iNs;

                drawTree.lines.index.push(Ei);
                drawTree.lines.xyz.push(nodes[perElementNodes[0]][0], nodes[perElementNodes[0]][1], nodes[perElementNodes[0]][2]);
                drawTree.lines.xyz.push(nodes[perElementNodes[1]][0], nodes[perElementNodes[1]][1], nodes[perElementNodes[1]][2]);

                // let X = nodes[perElementNodes[0]][0] - nodes[perElementNodes[1]][0];
                // let Y = nodes[perElementNodes[0]][1] - nodes[perElementNodes[1]][1];
                // let Z = nodes[perElementNodes[0]][2] - nodes[perElementNodes[1]][2];




            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // return function 
    // send data to master
    returnAnalysisData() {
        this.callbacks.receive({
            type: "midasMGT",
            succeed: true,
            subject: "midasMGT",
            data: this.data,
            // namesOfCM: this.getPressuresName()
        }, this.parent);
    }




    log(msg) {
        if (typeof this.callbacks.log !== "undefined") {
            this.callbacks.log(msg, this.parent);
        } else {
            console.log(msg);
        }
    }

}
export { AnalysisMidasMGT };