import { MeshOrigin } from "./meshOrigin";
class MidasMGT extends MeshOrigin {
    constructor(data) {
        super(data);
        this.data = {
            source: {
                nodes: [],
                elements: [],
                sections: [],
                type: "midasMGT",
            }
        };
        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;
        this.init(data);
        return this;
    }
    log(msg) {

        if (typeof this.parent != "undefined") {
            this.parent.log(msg, this.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }
    init(data) {
        let succeed = this.getFileMesh(data);



        this.succeed = succeed;
    }
    getFileMesh(text) {
        // this.parent.log("......数据优化;");
        if (text.data) {
            let msh_file_lines = text.data.split("\n");

            let nodes = this.data.source.nodes;
            let elements = this.data.source.elements;
            let sections = this.data.source.sections;


            let flageElements = false;
            let flageNodes = false;
            let flagSections = false;
            let flagSectionsSub = false;
            let OPOLY = false, IPOLY = false;
            let sectionIndex = 0;
            let lineEnterOf = false;

            for (let i in msh_file_lines) {
                let perLine = msh_file_lines[i].split("\r")[0];
                let perLineIsValues = perLine.split(/\s+/);

                if (perLineIsValues[0] == ";") {
                    continue;
                }
                let arrayOfBysemicolon = perLine.split(";")[0];

                if (perLine == "*NODE    ; Nodes" || perLine == "; iNO, X, Y, Z") {
                    flageNodes = true;
                    flageElements = false;
                    flagSections = false;
                    flagSectionsSub = false;
                    continue;
                }

                if (perLine == "*ELEMENT    ; Elements" || perLine == "; iNO, X, Y, Z") {
                    flageNodes = false;
                    flageElements = true;
                    flagSections = false;
                    flagSectionsSub = false;
                    continue;
                }
                if (arrayOfBysemicolon == "*LOAD-GROUP    ; Load Group"||arrayOfBysemicolon == "*MATERIAL    ; Material") {
                    flageNodes = false;
                    flageElements = false;
                    continue;
                }
                if (arrayOfBysemicolon == "*SECT-PSCVALUE    ") {
                    flageNodes = false;
                    flageElements = false;
                    flagSections = true;
                    flagSectionsSub = false;
                    continue;
                }

                if (flageNodes) {
                    if (perLine == "") {
                        flageNodes = false;
                        flageElements = false;
                        // flagSections = false;
                        // flagSectionsSub = false;
                        continue;
                    }
                    perLine = perLine.replace(/\s+/g, "");
                    let perArray = perLine.split(",");
                    nodes[perArray[0]] = [parseFloat(perArray[1]), parseFloat(perArray[2]), parseFloat(perArray[3])];
                }
                else if (flageElements) {
                    if (perLine == "") {
                        flageNodes = false;
                        flageElements = false;

                        continue;
                    }
                    perLine = perLine.replace(/\s+/g, "");
                    let perArray = perLine.split(",");
                    elements[perArray[0]] =
                    {
                        type: perArray[1],
                        iMAT: perArray[2],
                        iPRO: perArray[3],
                        iNs: perArray[1] == "BEAM"||"TENSTR" ? [perArray[4], perArray[5]] : [],
                    };
                }


            }//end of for
            return true;
        }//end of if text
        return false;
    }

}
export { MidasMGT };