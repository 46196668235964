// import { JSZip } from "jszip";
// import { JSZipUtils } from "jszip-utils";
// import * as zip from "../libs/zip.js.2.6.21/dist/zip";
import * as zip from "tomzipjs";//webpack don't support 
import { AnalysisPLTFR } from "./analysis"; //no worker
import { MeshAnalysis } from "./mesh"; // worker ready
import { ResAnalysis } from "./res"; // worker ready
import { StrAnalysis } from "./str"; // worker ready
import { SaveDB } from "./savedb";// no worker
import { AnalysisMidas } from "./AnalysisMidas";
import { AnalysisMidasMGT } from "./AnalysisMidasMGT";
import { MidasAnalysis } from "./midas";
import { MidasMGTAnalysis } from "./midasForMGT";
class GetFile {

    constructor(callbacks = false) {

        this.md5OfFiles = {
            //"md5":filename,
        };
        this.extra = {};
        this.filenames = {
            // filename:filename
        };
        this.callbacks = callbacks;
        // this.save = new SaveDB();
        this.worker = {};
        this.setting = {
            stepNo: 0,//0=init,1= only mesh ,2=mesh +data
            step: 0,
            doAnalysis: 50,
            newLoad: 1,
        };
        this.RGB = callbacks.RGB;
        this.parent = parent;
        this.zipEntries = {};
        this.zipFilenames = {};
        this.init();
    }
    // this function will be rewrite by worker fork class ,for close worker 
    destroy() { }
    // this function will be rewrite by worker fork class 
    init() {
        let that = this;
        this.analysis = new AnalysisPLTFR(this, {
            receive: function (data) {
                that.receiveDataFromAnalysis(data, that);
            },
            log: this.log,
            RGB: that.RGB,
        });
        this.analysisMidasMGT = new AnalysisMidasMGT(this, {
            receive: function (data) {
                that.receiveDataFromAnalysisMidas(data, that);
            },
            log: this.log,
        });
        this.analysisMidas = new AnalysisMidas(this, {
            receive: function (data) {
                that.receiveDataFromAnalysisMidas(data, that);
            },
            log: this.log,
        });
        //文件分析列表，
        this.worker = {
            getMeshFile: new MeshAnalysis({
                log: this.log,
                onMSG: function (data) { that.doAnalysis(data, that); },
                parent: this,
                RGB: that.RGB,
            }),
            getResFile: new ResAnalysis({
                log: this.log,
                onMSG: function (data) { that.doAnalysis(data, that); },
                parent: this,
            }),
            getStrFile: new StrAnalysis({
                log: this.log,
                onMSG: function (data) { that.doAnalysis(data, that); },
                parent: this,
            }),
            getMidasFile: new MidasAnalysis({
                log: this.log,
                onMSG: function (data) { that.doMidasAnalysis(data, that); },
                parent: this,
            }),
            getMidasMGT: new MidasMGTAnalysis({
                log: this.log,
                onMSG: function (data) { that.doMidasMGTAnalysis(data, that); },
                parent: this,
            }),
        };
        this.log("getFile Init.");
    }
    // this function will be rewrite by worker fork class
    postMSG2CM(data, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callbacks["postMSG"] != "undefined") {
            that.callbacks.onMSG(data);
        }
        else {
            that.log({ "getFile : error,can't find the callback or worker input ": data });
        }

    }
    // this function will be rewrite by worker fork class
    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callbacks["log"] != "undefined") {
            that.callbacks.log(msg, that.callbacks.parent);
        }
        else {
            console.log("getFile : ", msg);
        }
    }
    // this function will be rewrite by worker fork class
    doAnalysis(data, that = false) {
        if (that == false) {
            that = this;
        }

        // if (typeof that.saveDB != "undefined" && that.saveDB.succeed == true) {
        //     if (data.type == "data") {

        //     }
        // }
        if (data.type == "error" || data.succeed == false) {
            that.log(data);
        }
        else {

            that.log({
                type: "log",
                succeed: true,
                subject: that.setting.doAnalysis++ == 50 ? "50%:第二阶段处理......" : "2:处理中......",
                sender: "geiFile module",
            });
            // that.log(data);
            that.callbacks.parent.setting.stepNo = that.setting.stepNo;
            that.analysis.do(data, that.setting.step++ == that.setting.stepNo - 1 ? true : false, that.setting.stepNo, this.setting.newLoad++);
        }
        //
    }
    doMidasMGTAnalysis(data, that = false) {
        if (that == false) {
            that = this;
        }
        if (data.type == "error" || data.succeed == false) {
            that.log(data);
        }
        else {

            that.log({
                type: "log",
                succeed: true,
                subject: that.setting.doAnalysis++ == 50 ? "extra:第二阶段处理......" : "2:处理中......",
                sender: "geiFile module doMidasAnalysis",
            });
            that.log(data);
            that.analysisMidasMGT.do(data);
        }
    }
    doMidasAnalysis(data, that = false) {
        if (that == false) {
            that = this;
        }
        if (data.type == "error" || data.succeed == false) {
            that.log(data);
        }
        else {

            that.log({
                type: "log",
                succeed: true,
                subject: that.setting.doAnalysis++ == 50 ? "extra:第二阶段处理......" : "2:处理中......",
                sender: "geiFile module doMidasAnalysis",
            });
            that.log(data);
            that.analysisMidas.do(data);
        }
    }

    // this function will be rewrite by worker fork class
    receiveDataFromAnalysisMidas(data, that) {
        that.log({
            type: "log",
            succeed: true,
            subject: "extra:完成优化数据",
            sender: "geiFile module",
        });
        if (data.succeed === true) {


            let midas = {
                type: "data",
                succeed: true,
                subject: data.type,
                data: data,
            };
            that.callbacks.onMSG(midas, that.callbacks.parent);
        }
    }
    // this function will be rewrite by worker fork class
    receiveDataFromAnalysis(data, that) {
        that.log({
            type: "log",
            succeed: true,
            subject: "80%:完成优化数据",
            sender: "geiFile module",
        });
        if (data.succeed === true) {

            // that.log({ "getFile receiveDataFromAnalysis:": data });
            if (typeof this.analysis.data.str != "undefined" && Object.keys(this.analysis.data.str).length > 0) {
                let str = {
                    type: "data",
                    succeed: true,
                    subject: "str",
                    data: this.analysis.data.str,
                };
                that.callbacks.onMSG(str, that.callbacks.parent);
                // if (this.callbacks.cache)  this.saveDB.update("str", str);            
            }

            if (typeof this.analysis.data.source != "undefined" && Object.keys(this.analysis.data.source).length > 0) {
                let source = {
                    type: "data",
                    succeed: true,
                    subject: "source",
                    data: this.analysis.data.source,
                };
                that.callbacks.onMSG(source, that.callbacks.parent);
                if (this.callbacks.cache)
                    this.saveDB.update("source", source);
            }

            if (typeof this.analysis.data.res != "undefined" && Object.keys(this.analysis.data.res).length >= 2) {
                let res = {
                    type: "data",
                    succeed: true,
                    subject: "res",
                    data: this.analysis.data.res,
                };
                that.callbacks.onMSG(res, that.callbacks.parent);
                if (this.callbacks.cache)
                    this.saveDB.update("res", res);
            }

            if (typeof this.analysis.data.drawTree != "undefined" && Object.keys(this.analysis.data.drawTree).length > 0) {
                that.callbacks.onMSG(data, that.callbacks.parent);
                if (this.callbacks.cache)
                    this.saveDB.update("drawTree", data);

                if (this.callbacks.cache)
                    this.saveDB.update("filename", this.filename);
            }
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    //part for read URL zip file or normal file 
    readFile(data, DB = false) {//data is filename
        let that = this;
        this.filename = data;
        this.log({
            type: "log",
            succeed: true,
            subject: "10%:......",
            sender: "geiFile module",
        });

        if (DB !== false) {
            this.saveDB = DB;
        }
        else {

            this.saveDB = new SaveDB(
                {
                    log: this.log,
                    onMSG: function (data) {
                        that.draw(data, that);
                    },
                    parent: this,
                    dbName: data,
                }
            );
        }
        //url include string "?"
        if (data.indexOf("?") != "-1") {
            this.loadZip(data);// do it as a zip file
        }
        //don't include sting "?"
        else {

            let extFilename = data.split(".");

            if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "zip") {
                this.loadZip(data);
            }
            else if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "msh") {
                this.loadFile(data, "msh");
            }
            else if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "inp") {
                this.loadFile(data, "inp");
            }
            else if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "mgt") {
                this.loadExtraMidasFileOfMGT(data);
            }
            else {
                this.loadFile(data);
            }
        }
        // this.postMSG2Mesh(data);
        // this.postMSG2Res(data);
    }
    readExtraFile(data) {//data is filename
        let that = this;
        this.filename = data;
        this.log({
            type: "log",
            succeed: true,
            subject: "10%:......",
            sender: "getfile module , readExtraFile()",
        });

        this.extra = data;
        if (typeof data.midas != "undefined") {
            let extFilename = data.midas.file.split(".");

            if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "zip") {
                this.loadExtraMidasZip(data.midas.file);
            }
            else if (extFilename.length > 1 && extFilename[extFilename.length - 1].toLowerCase() == "mct") {
                this.loadExtraMidasFile(data.file);
            }
        }
    }
    loadFile(url, type) {
        let that = this;
        this.setting.stepNo = 0;
        this.setting.step = 0;
        this.setting.newLoad = 1;
        let httpRequest = new XMLHttpRequest();

        if (!httpRequest) {
            this.log({
                type: "error",
                succeed: false,
                subject: "Giving up :( Cannot create an XMLHTTP instance",
                sender: "geiFile module",
            });
            return false;
        }
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    // alert(httpRequest.responseText);
                    that.setting.stepNo = 1;//20220622 ,此处只能是单独价值msh或inp文件时，其他不能使用，会造成提前产生结束标志。
                    that.log({
                        type: "log",
                        succeed: true,
                        subject: "20%:传输文件完成",
                        sender: "geiFile module",
                    });
                    that.postMSG2Mesh({ data: httpRequest.responseText, ext: type, type: "mesh" });

                } else {
                    that.log({
                        type: "error",
                        succeed: false,
                        subject: "There was a problem with the request.",
                        sender: "geiFile module",
                    });
                }
            }

        };
        httpRequest.open("GET", url);
        httpRequest.send();

    }
    loadExtraMidasFileOfMGT(url) {
        let that = this;
        let httpRequest = new XMLHttpRequest();
        let extFilename = url.split(".");
        extFilename = extFilename[extFilename.length - 1];
        if (!httpRequest) {
            this.log({
                type: "error",
                succeed: false,
                subject: "Giving up :( Cannot create an XMLHTTP instance",
                sender: "geiFile module",
            });
            return false;
        }
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    // alert(httpRequest.responseText);

                    that.log({
                        type: "log",
                        succeed: true,
                        subject: "Extra:传输文件完成",
                        sender: "geiFile module",
                    });
                    that.postMSG2MidasMGT({ type: "data", ext: extFilename, data: httpRequest.responseText, });

                } else {
                    that.log({
                        type: "error",
                        succeed: false,
                        subject: "There was a problem with the request.",
                        sender: "geiFile module",
                    });
                }
            }

        };
        httpRequest.open("GET", url);
        httpRequest.send();

    }
    loadExtraMidasFile(url) {
        let that = this;
        let httpRequest = new XMLHttpRequest();
        let extFilename = url.split(".");
        extFilename = extFilename[extFilename.length - 1];
        if (!httpRequest) {
            this.log({
                type: "error",
                succeed: false,
                subject: "Giving up :( Cannot create an XMLHTTP instance",
                sender: "geiFile module",
            });
            return false;
        }
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    // alert(httpRequest.responseText);

                    that.log({
                        type: "log",
                        succeed: true,
                        subject: "Extra:传输文件完成",
                        sender: "geiFile module",
                    });
                    that.postMSG2Midas({ type: "data", ext: extFilename, data: httpRequest.responseText, });

                } else {
                    that.log({
                        type: "error",
                        succeed: false,
                        subject: "There was a problem with the request.",
                        sender: "geiFile module",
                    });
                }
            }

        };
        httpRequest.open("GET", url);
        httpRequest.send();

    }
    //zip 
    async loadZip(url) {
        this.setting.stepNo = 0;
        this.setting.step = 0;
        this.setting.newLoad = 1;
        const zipReader = new zip.ZipReader(new zip.HttpReader(url, { useXHR: true }));
        this.log({
            type: "log",
            succeed: true,
            subject: "20%:传输文件完成",
            sender: "geiFile module",
        });
        this.zipEntries = await zipReader.getEntries();
        // console.log(this.zipEntries);
        // const dataWriter = new zip.TextWriter(zip.getMimeType(this.zipEntries [0].filename));
        let filenames = [];
        for (let i in this.zipEntries) {
            filenames.push(this.zipEntries[i].filename);
        }
        let DR = this.fileNameAnalysis(filenames);//判断类型，重要
        //console.log("***********************", DR);
        for (let j in DR) {
            let perOne = DR[j];// perOne = array index of entries
            if (j == "mesh" && perOne !== false) {
                let perName = DR[j][0];
                //console.log(perName);
                let that = this;
                let mesh = async function (that) {
                    let data = await that.zipEntries[perName[0]].getData(new zip.TextWriter());

                    that.log({
                        type: "log",
                        succeed: true,
                        subject: "25%:第一阶段处理......",
                        sender: "geiFile module",
                    });
                    that.postMSG2Mesh({ type: "mesh", ext: perName[1], data: data });
                };
                mesh(that);
                this.setting.stepNo++;

                // console.log(data);
            }
            else if (j == "res" && perOne !== false) {
                for (let k in DR[j]) {
                    let perName = DR[j][k];
                    let that = this;
                    let res = async function (that, k) {
                        let data = await that.zipEntries[perName[0]].getData(new zip.TextWriter());

                        that.log({
                            type: "log",
                            succeed: true,
                            subject: "1:处理中......",
                            sender: "geiFile module",
                        });
                        that.postMSG2Res({ type: "data", ext: perName[1], data: data, index: parseInt(k) });
                    };
                    res(that, parseInt(k) + 1);
                    this.setting.stepNo++;
                    // console.log(data);
                }
            }
            else if (j == "str" && perOne !== false) {
                for (let k in DR[j]) {
                    let perName = DR[j][k];
                    let that = this;
                    let res = async function (that, k) {
                        let data = await that.zipEntries[perName[0]].getData(new zip.TextWriter());

                        that.log({
                            type: "log",
                            succeed: true,
                            subject: "extra 1:处理中......",
                            sender: "geiFile module",
                        });
                        that.postMSG2Str({ type: "data", ext: perName[1], data: data, index: parseInt(k) });
                    };
                    res(that, parseInt(k) + 1);
                    this.setting.stepNo++;
                    // console.log(data);
                }
            }
        }
        await zipReader.close();
    }
    //zip 
    async loadExtraMidasZip(url) {
        const zipReader = new zip.ZipReader(new zip.HttpReader(url, { useXHR: true }));
        this.log({
            type: "log",
            succeed: true,
            subject: "20%:传输文件完成",
            sender: "geiFile module",
        });
        this.zipEntries = await zipReader.getEntries();
        // console.log(this.zipEntries);
        // const dataWriter = new zip.TextWriter(zip.getMimeType(this.zipEntries [0].filename));
        let filenames = [];
        for (let i in this.zipEntries) {
            filenames.push(this.zipEntries[i].filename);
        }
        let DR = this.fileNameAnalysis(filenames);//判断类型，重要
        //console.log("***********************", DR);
        for (let j in DR) {
            let perOne = DR[j];// perOne = array index of entries
            if (j == "mct" && perOne !== false) {
                let perName = DR[j][0];
                //console.log(perName);
                let that = this;
                let mesh = async function (that) {
                    let data = await that.zipEntries[perName[0]].getData(new zip.TextWriter());

                    that.log({
                        type: "log",
                        succeed: true,
                        subject: "extra:第一阶段处理......",
                        sender: "geiFile module",
                    });
                    that.postMSG2Mesh({ type: "mesh", ext: perName[1], data: data });
                };
                mesh(that);
                this.setting.stepNo++;

                // console.log(data);
            }

        }
        await zipReader.close();
    }
    fileNameAnalysis(list) {
        this.zipFilenames = {
            mesh: false,
            res: false,
            str: false,
        };
        this.zipFilenames.mesh = this.getfirstEXTFile(list, ["inp", "msh"]);
        this.zipFilenames.res = this.getfirstEXTFile(list, ["dat", "res"]);
        this.zipFilenames.str = this.getfirstEXTFile(list, ["str"]);
        // console.log(this.zipFilenames);
        return this.zipFilenames;
    }
    getfirstEXTFile(filenames, Exts) {
        let list = [];
        for (let i in filenames) {
            let perFile = filenames[i].toLowerCase();
            let getExt = perFile.split(".");
            if (getExt.length > 1) {
                for (let j in Exts) {
                    if (getExt[getExt.length - 1] == Exts[j]) {
                        // return [i,j];
                        list.push([i, Exts[j]]);
                    }
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else
            return false;
    }

    // this function will be rewrite by worker fork class
    postMSG2Mesh(data, that = false) {
        if (that == false)
            that = this;
        that.worker.getMeshFile.do(data);
    }
    // this function will be rewrite by worker fork class
    postMSG2Res(data, that = false) {
        if (that == false)
            that = this;
        that.worker.getResFile.do(data);
    }
    // this function will be rewrite by worker fork class
    postMSG2Str(data, that = false) {
        if (that == false)
            that = this;
        that.worker.getStrFile.do(data);
    }
    postMSG2Midas(data, that = false) {
        if (that == false)
            that = this;
        that.worker.getMidasFile.do(data);
    }
    postMSG2MidasMGT(data, that = false) {
        if (that == false)
            that = this;
        that.worker.getMidasMGT.do(data);
    }


}
export { GetFile };