class ResGID {
    constructor(data) {
        this.data = {
            type: "res",
            res: {
            }
        };
        this.MSG = {};
        this.succeed = false;
        this.init(data);
        return this;
    }
    init(data) {


        this.succeed = this.getRES(data);
    }
    getData() {

    }
    getRES(text) {
        let a = 1;
        if (text.data) {
            let msh_file_lines = text.data.split("\n");

            let flag_Result = false, flag_name = false, flag_ComponentNames = false, flag_value = false, flag_No;
            let ComponentNames = [];
            let nameOfRes = false;
            let Value_Vector = false;
            for (var i in msh_file_lines) {
                let perLine = msh_file_lines[i].split("\r");
                let perLineArray = perLine[0].split(/\s+/);
                if (perLineArray.length > 1) {
                    perLineArray.splice(0, 1);
                    perLine = perLineArray;
                }
                if (perLine[0] == "Result") {//new first site name of new CM
                    flag_Result = true, flag_name = false, flag_ComponentNames = false, flag_value = false;

                    nameOfRes = perLine[1].replace(/\"/g, "");
                    flag_No = perLine[4];
                    if (perLine[5] == "Vector") {
                        Value_Vector = true;
                    }
                    else { Value_Vector = false; }

                    if (typeof this.data.res[flag_No] == "undefined") {
                        this.data.res[flag_No] = {};
                        if (typeof this.data.res[flag_No][nameOfRes] == "undefined") {
                            this.data.res[flag_No][nameOfRes] = {};

                        }
                        this.data.res[flag_No][nameOfRes].type = perLine[5];
                    }
                    else {
                        if (typeof this.data.res[flag_No][nameOfRes] == "undefined") {
                            this.data.res[flag_No][nameOfRes] = {};

                        }
                        this.data.res[flag_No][nameOfRes].type = perLine[5];
                    }
                    continue;
                }
                if (flag_Result && perLine[0] == "ComponentNames") {//new line for sub of site names
                    flag_ComponentNames = true;
                    // if (typeof this.data.res[flag_No][nameOfRes] == "undefined")
                    {
                        // this.data.res[flag_No][nameOfRes] = {};
                        ComponentNames = [];
                        for (let i = 1; i < perLine.length; i++) {
                            let temp = perLine[i].replace(/\"/g, "");//sub site of cm
                            ComponentNames.push(temp);
                            this.data.res[flag_No][nameOfRes][temp] = [];//按照u,v,w排列[] 
                        }
                        if (this.data.res[flag_No][nameOfRes].type == "Vector") {
                            this.data.res[flag_No][nameOfRes]["M"] = [];
                        }
                    }
                    continue;
                }
                if (flag_ComponentNames && perLine[0] == "Values") {// begin sub site vaules (next line)
                    flag_value = true;                              // flag of sub site vuales
                    continue;
                }
                if (perLine[0] == "" && perLine[1] == "end" && perLine[2] == "Values") {
                    flag_Result = false, flag_name = false, flag_ComponentNames = false, flag_value = false, flag_No = false;
                    nameOfRes = false;
                    continue;
                }


                if (flag_value) {// push sub site vules to array
                    let perValue = msh_file_lines[i].trim().split(/\s+/);
                    let M = 0;
                    for (let j in ComponentNames) {
                        let kind = ComponentNames[j];

                        if (typeof this.data.res[flag_No][nameOfRes][kind] == "undefined") {
                            this.data.res[flag_No][nameOfRes][kind] = [];
                        }
                        this.data.res[flag_No][nameOfRes][kind][perValue[0]] = parseFloat(perValue[parseInt(j) + a]);
                        M += parseFloat(perValue[parseInt(j) + a]) * parseFloat(perValue[parseInt(j) + a]);
                    }
                    if (this.data.res[flag_No][nameOfRes].type == "Vector") {
                        if (typeof this.data.res[flag_No][nameOfRes]["M"] == "undefined") {
                            this.data.res[flag_No][nameOfRes]["M"] = [];
                        }
                        this.data.res[flag_No][nameOfRes]["M"][perValue[0]] = Math.sqrt(M);
                    }
                }
            }
            return true;
        }
        else {
            return false;
        }
    }




}
export { ResGID };