class Info {

    constructor(parent, callbacks = false) {
        this.data = {};
        this.setting = {
            constRate: 0.1,
        };
        this.parent = parent;
        if (callbacks) {
            this.callbacks = callbacks;
            // this.afterDraw = callbacks.afterDraw;

        }
        this.indexOfAfterDraw = 0;
        this.countOfAfterDraw = 100;
    }

    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callbacks["log"] != "undefined") {
            that.callbacks.log(msg), this.parent;
        }
        else {
            console.log("getFile : ", msg);
        }
    }
    clean() {
        this.data = {};
    }
    afterDraw() {
        this.indexOfAfterDraw = 0;
        this.countOfAfterDraw = 100;
        // this.callbacks.afterDraw();
    }
    /////////////////////////////////////////////////////////////////////////////////////////
    //base
    setRES(data, sumCount = false) {
        if (sumCount === false && typeof this.parent.setting.stepNo != "undefined") {
            sumCount = this.parent.setting.stepNo;
        }
        this.countOfAfterDraw = sumCount;
        // console.log("RES");
        // this.data.res = data.data;
        if (typeof this.data.res == "undefined") {
            this.data.res = {
                type: false,
                res: {},
            };
        }
        if (typeof data.data.type != "undefined")
            this.data.res.type = data.data.type;
        for (let i in data.data.res) {
            let perTime = data.data.res[i];
            if (typeof this.data.res.res[i] == "undefined") {
                this.data.res.res[i] = perTime;
            }
            else {
                for (let j in perTime) {
                    let perSite = perTime[j];
                    this.data.res.res[i][j] = perSite;
                }
            }
        }
        if (this.countOfAfterDraw == this.indexOfAfterDraw)
            this.afterDraw();
        else {
            this.indexOfAfterDraw++;
        }
    }
    //base
    setSource(data, sumCount = false) {
        if (sumCount === false && typeof this.parent.setting.stepNo != "undefined") {
            sumCount = this.parent.setting.stepNo;
        }
        this.countOfAfterDraw = sumCount;
        // console.log("source");
        this.data.source = data.data;
        // this.afterDraw();
        if (this.countOfAfterDraw == this.indexOfAfterDraw)
            this.afterDraw();
        else {
            this.indexOfAfterDraw++;
        }
    }
    setDrawTree(data, sumCount = false) {
        if (sumCount === false && typeof this.parent.setting.stepNo != "undefined") {
            sumCount = this.parent.setting.stepNo;
        }
        this.countOfAfterDraw = sumCount;
        this.data.drawTree = data;
        // console.log("draw");
        if (this.countOfAfterDraw == this.indexOfAfterDraw)
            this.afterDraw();
        else {
            this.indexOfAfterDraw++;
        }
    }
    setExtraStr(data, sumCount = false) {
        if (sumCount === false && typeof this.parent.setting.stepNo != "undefined") {
            sumCount = this.parent.setting.stepNo;
        }
        this.data.str = data;
        // console.log("str");
        if (this.countOfAfterDraw == this.indexOfAfterDraw)
            this.afterDraw();
        else {
            this.indexOfAfterDraw++;
        }
    }
    setExtraMidas(data, drawTree = false) {
        this.data.midas = data.data;
        if (drawTree)
            this.data.drawTree = data.data.drawTree;
        // console.log("midas");
    }
    setMidasMGT(data, drawTree = false) {
        this.data.source = data.data.source;
        if (drawTree)
            this.data.drawTree = data.data.drawTree;
        // console.log("midas");
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // tree

    //output a tree of list
    getOriginTreeOfCM() { }

    // one point infomation
    getPointInfo(index) { }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // abaqus only
    //part of assmebly(Nset ,Elset , surface ,boundery ,load,)
    abaqusShowPointsForNset() { }
    abaqusShowNset() { }
    abaqusCleanAssembly() { }
    abaqusShowSurface() { }
    abaqusShowElset(name, show = true, cover = true) { }
    abaqusShowLoad(kind = "loads", name, step = 1) { }
    abaqusShowBoundary(kind, name, step = 1, all = false, show = true) { }
    // abaqus only 
    // output  
    abaqusGetInstanceAll() {
        let value = {};
        for (let i in this.data.source.instance) {
            let perPart = this.data.source.instance[i].part;
            let partkind;
            let elType;
            for (let j in this.data.source.part[perPart]) {
                let element = this.data.source.part[perPart].element;
                for (let k in element) {
                    elType = k;
                }
            }
            switch (elType) {
            case "C3D8R":
                partkind = "part";
                break;
            case "FC3D8":
                partkind = "part";
                break;
            case "S4":
                partkind = "shell";
                break;
            case "CPE3":
                partkind = "shell";
                break;
            case "B31":
                partkind = "line";
                break;
            case "T3D2":
                partkind = "line";
                break;
            }
            value[i] = {
                eltype: elType,
                partkind: partkind,
                name: i,
            };
        }
        return value;
    }
    abaqusGetPartAll() {
        let value = {};
        let partkind;
        let elType;
        for (let i in this.data.source.part) {
            let element = this.data.source.part[i].element;
            for (let k in element) {
                elType = k;
            }
            switch (elType) {
            case "C3D8R":
                partkind = "part";
                break;
            case "FC3D8":
                partkind = "part";
                break;
            case "S4R":
                partkind = "shell";
                break;
            case "S4":
                partkind = "shell";
                break;
            case "CPE3":
                partkind = "shell";
                break;
            case "B31":
                partkind = "line";
                break;
            case "T3D2":
                partkind = "line";
                break;
            }
            value[i] = {
                eltype: elType,
                partkind: partkind,
                name: i,
            };
        }

        return value;
    }
    abaqusGetMaterialAll() {
        return this.data.source.materials;
    }
    abaqusGetSectionForInstance() {
        let value = {};
        for (let i in this.data.source.instance) {
            let perPart = this.data.source.instance[i].part;
            let per = this.data.source.part[perPart];
            if (Object.keys(per.Section).length > 0) {
                value[i] = per.Section;
            }
        }
        return value;
    }
    abaqusGetSectionForPart() {
        let value = {};
        for (let i in this.data.source.part) {
            let per = this.data.source.part[i];
            if (Object.keys(per.Section).length > 0) {
                value[i] = per.Section;

            }
        }
        return value;
    }
    abaqusGetStepAll() {
        let value = {};
        for (let i in this.data.source.step) {
            let per = this.data.source.step[i];
            value[i] = {
                name: per.name,
                step: per.step,
            };
        }
        return value;
    }
    abaqusGetLoads() {
        let value = {};
        let loadsOfStep = {};
        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];

            let stepB = {};
            stepB.stepname = perStep.name;

            if (typeof perStep.loads != "undefined") {
                stepB["loads"] = perStep.loads ? perStep.loads : false;

            }
            if (typeof perStep.INTERACTIONS != "undefined") {
                for (let i in perStep.INTERACTIONS) {
                    if (typeof perStep.INTERACTIONS[i].Sfilm != "undefined")
                        stepB["INTERACTIONS"] = perStep.INTERACTIONS;
                }
            }
            if (Object.keys(stepB).length > 1) {
                value[i] = stepB;
            }

        }
        if (Object.keys(value).length > 0)
            return value;
        else
            return false;
    }
    abaqusGetBoundary() {
        let flag_New = false;
        let value = {};
        let boundaryOfStep = {};
        if (Object.keys(this.data.source.boundary).length > 0) {
            value.global = this.data.source.boundary;
        }
        else {
            // value.global=false;
        }
        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];
            let stepB = {};
            stepB.stepname = perStep.name;
            if (typeof perStep.boundary != "undefined") {
                for (let i in perStep.boundary) {
                    stepB[i] = perStep.boundary[i];
                    if (typeof perStep.boundary[i].Boundary != "undefined") {
                        if (typeof perStep.boundary[i].Boundary.op != "undefined")
                            if (perStep.boundary[i].Boundary.op.toLowerCase() == "new")
                                flag_New = true;
                    }

                }
            }
            // if (typeof perStep.INTERACTIONS != 'undefined') {
            //     for (let i in perStep.INTERACTIONS) {
            //         if (typeof perStep.INTERACTIONS[i].Sfilm != 'undefined')
            //             stepB[i] = perStep.INTERACTIONS[i];
            //     }
            // }
            if (Object.keys(stepB).length > 1) {
                boundaryOfStep[i] = stepB;
            }

        }
        if (Object.keys(boundaryOfStep).length > 0) {
            value.step = boundaryOfStep;
        }
        else {
            // value.step = false;
        }
        if (flag_New) {
            delete value.global;
        }

        if (Object.keys(value).length > 0)
            return value;
        else
            return false;
    }
    abaqusGetFluidBoundaryDirction() {
        let dir;
        // let boundaryOfStep = thisINP.getBoundary();
        // for (let step_i of boundaryOfStep) {
        //     let perStep = boundaryOfStep[step_i];
        //     for (let b_i of perStep) {
        //         let perBoundary = perStep[b_i];
        //         if (typeof perBoundary['Fluid Boundary'] != 'undefined') {
        //             let content = perBoundary.content;
        //             let x = 0, y = 0, z = 0;
        //             for (let perDir of content) {
        //                 let perDirContent = perDir.split(',');
        //                 if (perDirContent[1] == 'VELX') {
        //                     x = perDirContent[2];
        //                 }
        //                 else if (perDirContent[1] == 'VELY') {
        //                     y = perDirContent[2];
        //                 }
        //                 else if (perDirContent[1] == 'VELZ') {
        //                     z = perDirContent[2];
        //                 }
        //             }
        //             dir = [x, y, z];
        //         }
        //     }
        // }

        for (let i in this.data.source.step) {
            let perStep = this.data.source.step[i];
            if (typeof perStep.boundary != "undefined") {
                for (let i in perStep.boundary) {
                    let perBoundary = perStep.boundary[i];
                    if (typeof perBoundary["Fluid Boundary"] != "undefined") {
                        let content = perBoundary["Fluid Boundary"].content;
                        let x = 0, y = 0, z = 0;
                        for (let perDir of content) {
                            let perDirContent = perDir.split(",");
                            if (perDirContent[1] == "VELX") {
                                x = perDirContent[2];
                            }
                            else if (perDirContent[1] == "VELY") {
                                y = perDirContent[2];
                            }
                            else if (perDirContent[1] == "VELZ") {
                                z = perDirContent[2];
                            }
                        }
                        dir = [x, y, z];
                    }

                }
            }
        }
        return dir;
    }
    abaqusGetSurface() {
        return this.data.source.assembly.surface;

    }
    abaqusGetNset() {
        return this.data.source.assembly.Nset;

    }
    abaqusGetElset() {
        return this.data.source.assembly.Elset;

    }

    //////////////////////////////////////////////////////////////////////////////////////
    // str 
    //////////////////////////////////////////////////////////////////////////////////////
    //是否是midas扩展
    getIsMidas() {
        if (typeof this.data.midas != "undefined") {
            return true;
        }
        else
            return false;
    }
    //是否是梁单元str扩展
    getIsStr() {
        if (typeof this.data.str != "undefined") {
            return true;
        }
        else
            return false;
    }
    //获取str的site列表：Fx,Fy
    getSiteCMOfStr(site) {
        if (site.indexOf("str.") != -1) {
            site = site.split("str.")[1];
            return site;
        }
        else
            return false;
    }
    //获取site list  

    getSiteListOfStr() {
        if (this.data.str)
            if (this.data.str.site) {
                let list = [];
                for (let i of this.data.str.site) {
                    list.push("str." + i);
                }
                return list;
            }
        return false;
    }
    getInstanceOfStr() {
        let data = this.data.str.data;
        let instances = [];
        for (let i in data) {
            instances.push(i);
        }
        if (instances.length > 0) return instances;
        else return false;
    }
    //MM of 全局
    getMMOfStrALL(site) {
        if (site.indexOf("str.") != -1)
            site = site.split("str.")[1];
        let data = this.data.str.data;
        let listOfMM = [];
        for (let i in data) {
            let mm = this.getMMOfStrOfOneInstance(site, i);
            if (mm)
                listOfMM.push(mm);
        }
        let max = false, min = false;
        for (let j of listOfMM) {
            if (max === false) {
                max = j.max;
                min = j.min;
            }
            else {
                if (max < j.max)
                    max = j.max;
                if (min > j.min)
                    min = j.min;
            }
        }
        if (max !== false && min !== false) {
            return { max: parseFloat(max), min: parseFloat(min) };
        }
        else
            return false;
    }
    // 单个instance的MM
    getMMOfStrOfOneInstance(site, oneInstance) {
        if (site.indexOf("str.") != -1)
            site = site.split("str.")[1];
        if (this.data.str.data[oneInstance].data[site]) {
            let max = false, min = false;
            for (let i in this.data.str.data[oneInstance].data[site]) {
                let values = this.data.str.data[oneInstance].data[site][i];
                // values[0] = parseFloat(values[0]);
                // values[1] = parseFloat(values[1]);
                if (max === false) {
                    if (values[0] >= values[1]) {
                        max = values[0];
                        min = values[1];
                    }
                    else {
                        max = values[1];
                        min = values[0];
                    }
                }
                if (min > values[0]) min = values[0];
                if (min > values[1]) min = values[1];

                if (max < values[0]) max = values[0];
                if (max < values[1]) max = values[1];
            }
            return { max: max, min: min };
        }
        return false;
    }

    //获取str全局的最大值
    getMaxOfAbsOfStr(site) {
        if (site.indexOf("str.") != -1)
            site = site.split("str.")[1];
        let mm = this.getMMOfStrALL(site);
        if (mm)
            return mm.max > Math.abs(mm.min) ? mm.max : Math.abs(mm.min);
        return false;
    }
    //获取str最佳显示比例
    getBestTransRateOfStr(site, rateOfInut = false) {
        if (site.indexOf("str.") != -1)
            site = site.split("str.")[1];
        let A = this.getMaxOfAbsOfStr(site);
        let B;
        if (typeof this.setting.NodeMaxSize == "undefined") {
            B = this.getNodeMaxSize();
            this.setting.NodeMaxSize = B;
        }
        else {
            B = this.setting.NodeMaxSize;
        }
        let rate = this.setting.constRate;
        if (rateOfInut) {
            rate = rateOfInut;
        }
        if (typeof this.setting.bestTransRate != "undefined") {
            rate = this.setting.bestTransRate;
        }
        return parseFloat(rate * B / A);
    }
    //获取str最佳显示单位长度
    getBestTransRateValueOfStr(site, rateOfInut = false) {
        if (site.indexOf("str.") != -1)
            site = site.split("str.")[1];
        let B;
        if (typeof this.setting.NodeMaxSize == "undefined") {
            B = this.getNodeMaxSize();
            this.setting.NodeMaxSize = B;
        }
        else {
            B = this.setting.NodeMaxSize;
        }
        let rate = this.setting.constRate;
        return B * rate;

    }
    //获取str的all值
    getSiteAllValuesOfStrOfLines(nameOfSiteCM, instanceName = false) {
        let all = false;
        for (let i in this.data.str.data) {
            all = this.data.str.data[i];//目前只有一个，可以用
        }
        if (all === false)
            return false;

        if (nameOfSiteCM.indexOf("str.") != -1)
            nameOfSiteCM = nameOfSiteCM.split("str.")[1];
        let data = all.data[nameOfSiteCM];

        return data;
    }
    //获取str的单元的值
    getSiteValuesOfStrOfLines(instanceName, nameOfSiteCM, elementsIndex) {
        if (typeof this.data.str.data[instanceName] == "undefined")
            return false;
        let data = this.data.str.data[instanceName].data[nameOfSiteCM];
        let cm = [];
        for (let i of elementsIndex) {
            let perElement = data[i];
            cm.push(parseFloat(perElement[0]), parseFloat(perElement[1]));
        }
        return cm;
    }

    //获取一个点的str值
    getStrOnePointValue(nodeID) {
        if (typeof this.data.str == "undefined")
            return false;
        let elements = {};//元素：元素内index
        let mesh = this.data.source.mesh;
        let str = this.data.str.data;
        let instances = [];
        for (let i in str) {
            instances.push(i);
        }
        for (let i of instances) {
            let perInstanceElement = mesh[i].elements.elements;
            for (let j in perInstanceElement) {
                let perElement = perInstanceElement[j];
                for (let k in perElement) {
                    let ID = perElement[k];
                    if (ID == nodeID) {
                        elements[j] = k;
                    }
                }
            }
        }

        let values = {};
        for (let i in elements) {
            let index = elements[i];
            for (let j in str) {//instance
                let perStr = str[j].data;
                for (let k in perStr) {//site
                    if (typeof values[k] == "undefined") {
                        values[k] = [];
                    }
                    if (typeof perStr[k][i][index] != "undefined") {
                        values[k].push(perStr[k][i][index]);
                    }
                }
            }
        }
        // console.log(values);
        return values;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // for draw 
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    //get the best transform rate 
    getBestTransRate() {
        let A = this.getCMTransMaxSize();
        let B;
        if (typeof this.setting.NodeMaxSize == "undefined") {
            B = this.getNodeMaxSize();
            this.setting.NodeMaxSize = B;
        }
        else {
            B = this.setting.NodeMaxSize;
        }
        let rate = this.setting.constRate;
        if (typeof this.setting.bestTransRate != "undefined") {
            rate = this.setting.bestTransRate;
        }
        return parseInt(rate * B / A);
    }
    //get max value of CM pressures of UVW ,abs()
    getCMTransMaxSize(level = "1") {
        let MM = this.getCMTransSize(level);
        if (MM === false)
            return false;
        let x = Math.abs(MM.max.x - MM.min.x);
        let y = Math.abs(MM.max.y - MM.min.y);
        let z = Math.abs(MM.max.z - MM.min.z);
        return x >= y ? x : (y >= z ? y : z);
    }
    // get CM pressures UVW max and min size range
    getCMTransSize(level = "1") {
        if (Object.keys(this.data.res.res).length == 0)
            return { max: 0, min: 0 };
        let max = {
            x: false,
            y: false,
            z: false,
        };
        let min = {
            x: false,
            y: false,
            z: false,
        };
        let uvw = this.getDefaultTrans();
        if (uvw == false) {
            return false;
        }

        if (this.data.source.type == "msh") {
            let current = this.data.res.res[level][uvw[0]];
            uvw[1][0] = uvw[1][0].split(".")[1];
            uvw[1][1] = uvw[1][1].split(".")[1];
            if (typeof uvw[1][2] != "undefined")
                uvw[1][2] = uvw[1][2].split(".")[1];
            for (let i in current[uvw[1][0]]) {
                let x = current[uvw[1][0]][i];
                let y = current[uvw[1][1]][i];
                let z = 0;
                if (typeof uvw[1][2] != "undefined") {
                    z = current[uvw[1][2]][i];
                }
                // let xyz = current[i];
                if (max.x === false) {
                    max.x = x;
                    max.y = y;
                    max.z = z;

                    min.x = x;
                    min.y = y;
                    min.z = z;
                }
                if (max.x < x) max.x = x;
                if (max.y < y) max.y = y;
                if (max.z < z) max.z = z;

                if (min.x > x) min.x = x;
                if (min.y > y) min.y = y;
                if (min.z > z) min.z = z;
            }
        }
        else if (this.data.source.type == "inp") {
            let current = this.data.res.res[level];
            let perPressure = current[uvw[1][0]];
            for (let i in perPressure) {
                for (let j in perPressure[i]) {
                    let x = current[uvw[1][0]][i][j];
                    let y = current[uvw[1][1]][i][j];
                    let z = 0;
                    if (typeof uvw[1][2] != "undefined") {
                        z = current[uvw[1][2]][i][j];
                    }
                    // let xyz = current[i];
                    if (max.x === false) {
                        max.x = x;
                        max.y = y;
                        max.z = z;

                        min.x = x;
                        min.y = y;
                        min.z = z;
                    }
                    if (max.x < x) max.x = x;
                    if (max.y < y) max.y = y;
                    if (max.z < z) max.z = z;

                    if (min.x > x) min.x = x;
                    if (min.y > y) min.y = y;
                    if (min.z > z) min.z = z;
                }
            }

        }
        // else if (this.meshType == 'abaqus') {

        // }
        return { max: max, min: min };
    }
    //get max and min value of parts
    getNodeMMSize() {
        let max = {
            x: false,
            y: false,
            z: false,
        };
        let min = {
            x: false,
            y: false,
            z: false,
        };
        if (this.data.source.type == "msh") {
            for (let perNode_i in this.data.source.nodes) {
                let perNode = this.data.source.nodes[perNode_i];
                for (let i in perNode) {
                    let xyz = perNode[i];
                    if (max.x === false) {
                        max.x = xyz[0];
                        max.y = xyz[1];
                        max.z = xyz[2];

                        min.x = xyz[0];
                        min.y = xyz[1];
                        min.z = xyz[2];
                    }
                    if (max.x < xyz[0]) max.x = xyz[0];
                    if (max.y < xyz[1]) max.y = xyz[1];
                    if (max.z < xyz[2]) max.z = xyz[2];

                    if (min.x > xyz[0]) min.x = xyz[0];
                    if (min.y > xyz[1]) min.y = xyz[1];
                    if (min.z > xyz[2]) min.z = xyz[2];
                }
            }
        }
        //inp MM size has some problem ,becase of instances have martix frome part ,so here is sample fix to part ,not fix instance 
        else if (this.data.source.type == "inp") {
            for (let perPart_i in this.data.source.part) {
                let perNode = this.data.source.part[perPart_i]["nodes"];
                for (let i in perNode) {
                    let xyz = perNode[i];
                    if (max.x === false) {
                        max.x = xyz[0];
                        max.y = xyz[1];
                        max.z = xyz[2];

                        min.x = xyz[0];
                        min.y = xyz[1];
                        min.z = xyz[2];
                    }
                    if (max.x < xyz[0]) max.x = xyz[0];
                    if (max.y < xyz[1]) max.y = xyz[1];
                    if (max.z < xyz[2]) max.z = xyz[2];

                    if (min.x > xyz[0]) min.x = xyz[0];
                    if (min.y > xyz[1]) min.y = xyz[1];
                    if (min.z > xyz[2]) min.z = xyz[2];
                }
            }
        }
        return { max: max, min: min };
    }
    //get max value of inp part between of max to min
    getNodeMaxSize() {
        let MM = this.getNodeMMSize();
        let x = Math.abs(MM.max.x - MM.min.x);
        let y = Math.abs(MM.max.y - MM.min.y);
        let z = Math.abs(MM.max.z - MM.min.z);
        // return x >= y ? x : (y >= z ? y : z);
        let three = (x + y + z) / 3.;
        let max = x >= y ? x : (y >= z ? y : z);
        if (max > three * 2) {
            return three / 3;
        }
        else {
            return max;
        }
    }

    // input is "aaa.bbb"
    getGlobalCMMM(nameOfPressure) {
        let max = false;
        let min = false;

        if (this.data.res.type == "res") {
            nameOfPressure = nameOfPressure.split(".");
            for (let i in this.data.res.res) {
                let perCMSite = this.data.res.res[i][nameOfPressure[0]][nameOfPressure[1]];
                for (let j in perCMSite) {
                    let v = perCMSite[j];
                    if (max === false) {
                        max = v;
                        min = v;
                    }
                    if (max < v) max = v;
                    if (min > v) min = v;
                }
            }
        }
        else if (this.data.res.type == "dat") {
            for (let i in this.data.res.res) {
                let perCMSite = this.data.res.res[i][nameOfPressure];
                for (let j in perCMSite) {
                    let perPart = perCMSite[j];
                    for (let k in perPart) {
                        let v = perPart[k];
                        if (max === false) {
                            max = v;
                            min = v;
                        }
                        if (max < v) max = v;
                        if (min > v) min = v;
                    }
                }
            }
        }
        return { max: max, min: min };
    }
    getCurrentLevelCMMM(nameOfPressure, level = "1") {
        let max = false;
        let min = false;

        if (this.data.res.type == "res") {
            nameOfPressure = nameOfPressure.split(".");
            let perCMSite = this.data.res.res[level][nameOfPressure[0]][nameOfPressure[1]];
            for (let j in perCMSite) {
                let v = perCMSite[j];
                if (max === false) {
                    max = v;
                    min = v;
                }
                if (max < v) max = v;
                if (min > v) min = v;
            }

        }
        else if (this.data.res.type == "dat") {
            let perCMSite = this.data.res.res[level][nameOfPressure];
            for (let j in perCMSite) {
                let perPart = perCMSite[j];
                for (let k in perPart) {
                    let v = perPart[k];
                    if (max === false) {
                        max = v;
                        min = v;
                    }
                    if (max < v) max = v;
                    if (min > v) min = v;
                }
            }

        }
        return { max: max, min: min };
    }
    getResTimeList() {
        let list = [];
        for (let i in this.data.res.res) {
            list.push(i);
        }
        return list.length > 1 ? list : [1];
    }

    getSiteTimeList() {
        let list = [];
        let res = this.data.res.res;
        for (let i in res) {
            list.push(i);
        }
        return list;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // default UVW
    getDefaultTrans() {
        if (this.data.res.type == "res")
            return this.resGetUVW();
        else if (this.data.res.type == "dat")
            return this.datGetUVW();
    }
    datGetUVW() {
        let pressures = this.data.res.res[1];
        let UVW = [];
        for (let i in pressures) {
            if (i == "U.U1") {
                UVW[0] = i;
            }
            if (i == "U.U2") {
                UVW[1] = i;
            }
            if (i == "U.U3") {
                UVW[2] = i;
            }
            if (i == "V.V1" && typeof UVW[0] == "undefined") {
                UVW[0] = i;
            }
            if (i == "V.V2" && typeof UVW[1] == "undefined") {
                UVW[1] = i;
            }
            if (i == "V.V3" && typeof UVW[2] == "undefined") {
                UVW[2] = i;
            }
        }
        return [1, UVW];
    }
    resGetUVW() {
        let RES = this.data.res.res;
        for (let perTimeI in RES) {
            let oneRES = RES[perTimeI];
            for (let i in oneRES) {
                let field = oneRES[i];
                if (i == "U") {
                    for (let j in field) {
                        if (j != "type") {
                            let perTimeField = field[j];
                            let names = [];
                            for (let k in perTimeField) {
                                if (k !== "M") {
                                    names.push("U." + k);
                                }
                            }
                            return ["U", names];
                        }
                    }
                }
                else if (field.type == "Vector") {
                    for (let j in field) {
                        if (j != "type") {
                            let perTimeField = field;
                            if (this.data.source.dimension == 2) {
                                if (typeof perTimeField.U1 != "undefined" && typeof perTimeField.U2 != "undefined") {
                                    return [i, [i + ".U1", i + ".U2"]];
                                }
                                else if (typeof perTimeField.u != "undefined" && typeof perTimeField.v != "undefined") {
                                    return [i, [i + ".u", i + ".v"]];
                                }
                            }
                            else {

                                if (typeof perTimeField.U1 != "undefined" && typeof perTimeField.U2 != "undefined" && typeof perTimeField.U3 != "undefined") {
                                    return [i, [i + ".U1", i + ".U2", i + ".U3"]];
                                }
                                else if (typeof perTimeField.u != "undefined" && typeof perTimeField.v != "undefined" && typeof perTimeField.w != "undefined") {
                                    return [i, [i + ".u", i + ".v", i + ".w"]];
                                }
                            }
                        }
                    }
                }
            }
            // break;
        }
        return false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // default UVW
    getSiteValuesOfTriangles(instanceName, nameOfSiteCM, level) {
        // return this.data.drawTree[instanceName].triangles.pressures[this.setting.currentCMLevel][nameOfSiteCM];
        let drawTree = this.data.drawTree;
        for (let i in drawTree) {
            if (i == instanceName) {
                let index = this.data.drawTree[instanceName].triangles.index;
                let values = this.getSiteValues(index, nameOfSiteCM, instanceName, level);
                return values;
            }
        }
    }
    getSiteValuesOfLines(instanceName, nameOfSiteCM, level) {
        let drawTree = this.data.drawTree;
        for (let i in drawTree) {
            if (i == instanceName) {
                let index = this.data.drawTree[instanceName].lines.index;
                let values = this.getSiteValues(index, nameOfSiteCM, instanceName, level);
                return values;
            }
        }
    }
    getSiteValuesOfPoints(instanceName, nameOfSiteCM, level) {
        let drawTree = this.data.drawTree;
        for (let i in drawTree) {
            if (i == instanceName) {
                let index = this.data.drawTree[instanceName].points.index;
                let values = this.getSiteValues(index, nameOfSiteCM, instanceName, level);
                return values;
            }
        }
    }

    getSiteValues(index, nameOfCM, instanceName, level) {
        let type = this.data.res.type;
        let list = [];
        let pressures;
        if (type == "dat") {
            if (typeof this.data.res.res[level][nameOfCM] != "undefined") {
                if (typeof this.data.res.res[level][nameOfCM][instanceName] != "undefined") {
                    pressures = this.data.res.res[level][nameOfCM][instanceName];
                }
                else {
                    return list;
                }
            }
            else {
                return list;
            }
        }
        else if (type == "res") {
            nameOfCM = nameOfCM.split(".");
            pressures = this.data.res.res[level][nameOfCM[0]][nameOfCM[1]];
        }
        if (instanceName == "liner-1") {
            let abc = 1;
        }
        // if (typeof pressures == "undefined")
        //     return list;
        // console.log(instanceName);
        // if (instanceName == "liner-1") {
        //     let abc = 1;
        // }
        for (let i of index) {

            if (typeof pressures != "undefined") {
                // if (isNaN(pressures[i]))
                //     debugger;
                list.push(pressures[i]);
            }
            else {
                list.push(-9999.9999);
            }
        }
        return list;
    }

    //返回site的所有的site值
    getSiteAllValues(nameOfCM, instanceName, level = 1) {
        let type = this.data.res.type;
        let pressures;
        if (type == "dat") {
            pressures = this.data.res.res[level][nameOfCM][instanceName];
        }
        else if (type == "res") {
            nameOfCM = nameOfCM.split(".");
            pressures = this.data.res.res[level][nameOfCM[0]][nameOfCM[1]];
        }

        return pressures;
    }


    //获取onepoint的uvw，已知instancename
    getOnePointUVW(instanceName, level, nameOfCM, index) {
        let type = this.data.res.type;

        let u, v, w;
        if (type == "dat") {
            // if (nameOfCM.length == 0) {
            //     return false;
            // }
            u = this.data.res.res[level][nameOfCM[0]][instanceName];
            v = this.data.res.res[level][nameOfCM[1]][instanceName];
            w = this.data.res.res[level][nameOfCM[2]][instanceName];

        }
        else if (type == "res") {
            let nameOfCM_u = nameOfCM[0].split(".");
            u = this.data.res.res[level][nameOfCM_u[0]][nameOfCM_u[1]];
            let nameOfCM_v = nameOfCM[1].split(".");
            v = this.data.res.res[level][nameOfCM_v[0]][nameOfCM_v[1]];
            let nameOfCM_w = nameOfCM[2].split(".");
            w = this.data.res.res[level][nameOfCM_w[0]][nameOfCM_w[1]];
        }
        // if (index == 704) {
        //     let abc = 1;
        //     console.log(index,[u[index], v[index], w[index]]);
        // }
        // if (index == 145) {
        //     let abc = 1;
        //     console.log(index,[u[index], v[index], w[index]]);
        // }
        return [u[index], v[index], w[index]];
    }
    getAbaqusPartnameOfInstance(name) {
        return this.data.source.instance[name].part;
    }
    getOnePointXYZ(instanceName, index) {
        // let type = this.data.res.type;
        let type = this.data.source.type;
        let nodes;
        if (type == "inp") {
            nodes = this.data.source.part[this.getAbaqusPartnameOfInstance(instanceName)].nodes;
        }
        else if (type == "msh") {

            nodes = this.data.source.nodes[instanceName];
        }

        return nodes[index];
    }
    getOnePointPressure(instanceName, level = 1, nameOfCM, index) {
        let type = this.data.res.type;
        if (nameOfCM === false)
            return false;

        let pressures;
        if (type == "dat") {
            if (nameOfCM) {
                pressures = this.data.res.res[level][nameOfCM][instanceName];
            }
            else {
                return 0.1;
            }
        }
        else if (type == "res") {
            if (nameOfCM) {
                let nameOfCM_u = nameOfCM.split(".");
                pressures = this.data.res.res[level][nameOfCM_u[0]][nameOfCM_u[1]];
            }
            else {
                return 0.5;
            }
        }

        if (pressures)
            if (typeof pressures[index] !== "undefined") {
                return pressures[index];
            }
        return false;
    }

    getOnePointXYZOfAll(ID = false) {
        if (ID) {

            let nodes = this.data.source.nodes;
            for (let i in nodes) {
                let perInstance = nodes[i];
                if (typeof perInstance[ID] != "undefined") {
                    return perInstance[ID];
                }
            }
        }
        return false;
    }
    getNameListOfCM() {
        let res = this.data.res.res;
        let type = this.data.res.type;
        let list = [];
        if (Object.keys(res).length == 0) {
            return list;
        }
        else {
            if (typeof type != "undefined" && type == "res") {
                for (let i in res) {
                    let perTimer = res[i];
                    for (let j in perTimer) {
                        for (let k in perTimer[j]) {
                            if (k !== "type") {
                                list.push(j + "." + k);
                            }
                        }
                    }
                    return list;
                }
            }
            else if (typeof type != "undefined" && type == "dat") {
                for (let i in res) {
                    let perTimer = res[i];
                    for (let j in perTimer) {
                        list.push(j);

                    }
                    return list;
                }
            }

        }
    }
}
export { Info };