// version:1.4
import {
    Mesh,
    // Vector3,
    Scene,
    FontLoader,
    // LineBasicMaterial,
    MeshBasicMaterial,
    DoubleSide,
    ShapeBufferGeometry,
    OrthographicCamera,
    Sprite,
    CanvasTexture,
    SpriteMaterial,
    WebGLRenderer

} from "three";

import { Lut } from "three/examples/jsm/math/Lut.js";
import { font_HV } from "../font/font_HV";
import { CanvasLUT } from "./cavasLUT";

// import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
// let that;
class LUT_text {
    constructor(input, kind = false) {
        let container = input.DIV;
        let parent = input.parent;
        this.canvas = container;
        let that = this;
        this.unit = " ";
        this.container = " ";//document.getElementById(container);
        if (!this.container) {
            console("PLS check LUT input values");
            return;
        }
        this.parent = parent;
        if (typeof input.fontColor != "undefined")
            this.textColor = input.fontColor;
        else
            this.textColor = 0x000000;

        if (typeof input.bgColor != "undefined")
            this.bgColor = input.bgColor;
        else
            this.bgColor = false;

        this.kind = "default";
        if (kind == false || kind == "default") {
            this.kind = "default";
        }
        else if (kind == "curve") {
            this.kind = "curve";
        }
        this.pngCurve = {//带曲线的sprite png
            // left up
            cm_png_location: {
                scale: {
                    x: 1.4,
                    y: 2
                },
                translate: {
                    x: -0.3,
                    y: 0.
                },
                y: {
                    y2: -0.98,
                    y1: .65,
                }
            },


        };
        this.inputValue = {
            text: [
                {
                    msg: "",
                    color: this.textColor,
                    position: [1, 1, 1],
                }
            ],
            UI: "uiScene"
        };
        this.value = {
            //left up
            scale: {
                x: 1,
                y: 1
            },
            translate: {
                x: -0.5,
                y: 0.5
            },
            y: {
                y2: -0.5,
                y1: .5,
            }
        };

        this.WH = 256;
        this.distance = 2000;
        this.objet_text = [];//text mesh group
        this.object_font = {};//font object
        this.lut;
        this.uiScene;
        this.sprite;
        this.orthoCamera;
        const loader = new FontLoader();
        let blob = new Blob([JSON.stringify(font_HV)], { type: "application/json" });
        let url = URL.createObjectURL(blob);
        loader.load(url, function (load_font) {
            that.object_font = load_font;
        });
        this.init_UI();
        this.init_lut(this.value);
        return this;
    }
    //////////////////////////////////////////////////////////////
    // init 
    //default init
    init_UI() {
        this.canvaslut = new CanvasLUT(this.canvas);
        this.canvaslut.setWH(32);
        if (this.textColor)
            this.setTextColor(this.textColor);
        if (this.bgColor)
            this.setBgColor(this.bgColor);

            this.hide()

    }
    //default LUT init
    init_lut() {
        this.lut = new Lut();

    }

    render() {
        // this.renderer.render(this.uiScene, this.orthoCamera);
    }
    // png curve init

    initPngCurve() {

    }
    ////////////////////////////////////////////////////////////////
    // get 
    get_uiScene() {
        // return this.uiScene;
    }
    get_orthoCamera() {
        // return this.orthoCamera;
    }
    get_sprite() {
        // return this.sprite;
    }
    get_lut() {
        return this.lut;
    }

    setMaxMinOfGlobal(MM) {
        this.max = MM.max;
        this.min = MM.min;
        this.lut.setMax(this.max);
        this.lut.setMin(this.min);
    }
    getMaxMin(json) {
        this.max = false;
        this.min = false;
        for (let i in json) {
            // if (i > 6648) {
            //     let abc = 1;
            // }
            if (this.max === false) {
                this.max = json[i];
                this.min = json[i];
            }
            if (this.max < json[i]) {
                this.max = json[i];
            }
            if (this.min > json[i]) {
                this.min = json[i];
            }
            // console.log(i, this.max, this.min, json[i])

        }
    }
    //1、set
    setMaxMin(json, unit = false) {
        if (unit)
            this.unit = unit;
        this.getMaxMin(json);
        this.lut.setColorMap("rainbow");
        this.lut.setMax(this.max);
        this.lut.setMin(this.min);
    }
    setTitle(tile = "") {
        this.title = tile;
    }
    setUnit(unit = "") {
        this.unit = unit;
    }
    setLUTSignEnable(value = true) {
        // if (this.kind == "default") this.sprite.visible = value;
        // else if (this.kind == "curve") this.pngCurve.cm_png.visible = value;
    }
    /////////////////////////////////////////////////////////////////////////////////////
    // clean and update text


    //2、entry 
    // 20220407 rewrite
    update_text(title = false, unit = false) {
        this.canvaslut.clean();
        this.canvaslut.unit = unit || this.unit;
        this.canvaslut.draw(title || "", 12, this.max, this.min);

    }
    updateTextDefault() {

    }
    updateTextPngCurve() {


    }
    format(ss) {
        let ll = 6;

        ss = parseFloat(ss).toExponential();
        // return ss.split('e')[0].slice(0, 5) + "E" + ss.split('e')[1];
        let a = ss.split("e")[0].slice(0, ll);
        let b = "E" + ss.split("e")[1];
        if (a.length < 8) {
            if (a.match(/\./) ? false : true) {
                a = a + ".";
            }
            let len = a.length;
            for (let i = 0; i <= ll - len; i++) {
                a = a + "0";
            }

        }
        return a + b;
        // return ss;
    }

    clean_and_update(value = "") {
        this.update_text(value);

    }
    clean() {
        if (this.canvaslut) {
            this.canvaslut.clean();
        }

    }
    add(input) {

    }
    setTextColor(color) {
        this.canvaslut.setColorOfText(color);
        // this.update_text();
    }
    setBgColor(color) {
        this.canvaslut.setBgColor(color);
        // this.update_text();
    }
    show(){
        this.canvaslut.show();
    }
    hide(){
        this.canvaslut.hide();
    }
}






export { LUT_text };