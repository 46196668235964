import { MeshOrigin } from "./meshOrigin";
class MeshGID extends MeshOrigin {
    constructor(data, RGB = false) {
        super(data);
        this.data = {
            source: {
                nodes: {},
                mesh: {},
                type: "msh",

            },
            drawTree: {
            }
        };
        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;
        if (RGB === true) {
            this.defaultColorR = -9.;
            this.defaultColorG = -9.;
            this.defaultColorB = -9.;
        }
        this.init(data);
        return this;
    }
    log(msg) {

        if (typeof this.parent != "undefined") {
            this.parent.log(msg, this.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }
    init(data) {
        let succeed = this.getFileMesh(data);

        // if (succeed)
        // succeed =
        this.calculateMesh();

        this.succeed = succeed;
    }

    getFileMesh(text) {
        // this.parent.log("......数据优化;");
        if (text.data) {
            let msh_file_lines = text.data.split("\n");
            ////consolee.log(msh_file_lines)//array
            // let points = [];
            let elements = false;
            let nodes = false;
            // let elementsName = false;
            // let nodesName = false;
            let meshName = false, meshType = false, groups = false;
            let lastNodesName = false;
            // let meshs = [];//标志位，vertex
            let flag_vertex = false, flag_polygon = false;
            // let countGrammar = 0;
            let dimension;
            let elementType;
            let countOfNode;


            for (let i in msh_file_lines) {
                let perLine = msh_file_lines[i].split("\r");
                let perLineArray = perLine[0].split(/\s+/);
                let perLineFirst;
                if (perLineArray.length > 1) perLineFirst = perLineArray[1];

                if (perLineFirst == "Mesh") {
                    meshName = perLineArray[2].replace(/\"/g, "");
                    dimension = perLineArray[4];
                    meshType = perLineArray[6] + " " + perLineArray[7] + " " + perLineArray[8];
                    elementType = perLineArray[6];
                    countOfNode = perLineArray[8];
                    this.data.source.nodes[meshName] = [];//{};

                    this.data.source.dimension = dimension;
                    this.data.source.mesh[meshName] = {
                        meshName: meshName,
                        elements: {
                            elements: [],// {},
                            meshType: meshType,
                            groups: {},
                            dimension: dimension,
                            countOfNode: countOfNode,
                            elementType: elementType,
                        },
                        nodes: this.data.source.nodes[meshName],
                        meshType: meshType,
                        dimension: dimension,
                    };
                    if (lastNodesName == false) lastNodesName = meshName;
                    elements = this.data.source.mesh[meshName].elements.elements;
                    groups = this.data.source.mesh[meshName].elements.groups;
                    nodes = this.data.source.nodes[meshName];

                }

                if (perLineFirst == "Coordinates") {
                    flag_vertex = true;
                }
                if (perLineFirst == "End" && perLineArray[2] == "coordinates") {
                    //if (Object.keys(nodes).length > 0)
                    if (nodes.length > 0) {
                        lastNodesName = meshName;
                        this.data.source.mesh[meshName].nodesLength = nodes.length;
                    }
                    flag_vertex = false;
                }
                if (perLineFirst == "Elements") {
                    flag_polygon = true;
                }
                if (perLineFirst == "End" && perLineArray[2] == "elements") {
                    if (Object.keys(nodes).length == 0 && lastNodesName) {
                        this.data.source.mesh[meshName].nodes = this.data.source.nodes[lastNodesName];
                        // let abc = 1;
                    }
                    flag_polygon = false;
                }
                if (flag_vertex && perLineFirst != "Coordinates") {
                    if (dimension == "3") {
                        let temp_line_vertex_array = msh_file_lines[i].trim().split(/\s+/);
                        if (temp_line_vertex_array.length == 5) {
                            temp_line_vertex_array.splice(0, 1);
                        }
                        //consolee.log(temp_line_vertex_array)
                        if (temp_line_vertex_array.length == 4) {
                            nodes[parseInt(temp_line_vertex_array[0])] = [parseFloat(temp_line_vertex_array[1]), parseFloat(temp_line_vertex_array[2]), parseFloat(temp_line_vertex_array[3])];
                        }
                    }
                    else if (dimension == "2") {
                        let temp_line_vertex_array = msh_file_lines[i].trim().split(/\s+/);
                        if (temp_line_vertex_array.length == 4) {
                            temp_line_vertex_array.splice(0, 1);
                        }
                        //consolee.log(temp_line_vertex_array)
                        if (temp_line_vertex_array.length == 3) {
                            nodes[parseInt(temp_line_vertex_array[0])] = [parseFloat(temp_line_vertex_array[1]), parseFloat(temp_line_vertex_array[2]), 0];
                        }
                    }
                }

                if (flag_polygon && perLineFirst != "Elements") {
                    let temp_line_poly_array = msh_file_lines[i].trim().split(/\s+/);
                    if (temp_line_poly_array.length == 10 && meshType == "Hexahedra Nnode 8") { //8 point
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4]),
                                parseInt(temp_line_poly_array[5]), parseInt(temp_line_poly_array[6]), parseInt(temp_line_poly_array[7]), parseInt(temp_line_poly_array[8]),
                            ];
                        if ("undefined" == typeof groups[temp_line_poly_array[9]])
                            groups[temp_line_poly_array[9]] = [];
                        groups[temp_line_poly_array[9]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Quadrilateral Nnode 4") { // 四边形
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4])
                            ];
                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Triangle Nnode 3") { // Triangle Nnode 3
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3])
                            ];
                        if ("undefined" == typeof groups[temp_line_poly_array[4]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Point Nnode 1") {
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1])
                            ];


                        if ("undefined" == typeof groups[temp_line_poly_array[2]])
                            groups[temp_line_poly_array[2]] = [];
                        groups[temp_line_poly_array[2]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Linear Nnode 2") {
                        elements[temp_line_poly_array[0]] = [parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2])];


                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }
                    else if (meshType == "Quadrilateral Nnode 9" && dimension == 2) { // 二阶 四边形
                        elements[temp_line_poly_array[0]] =
                            [
                                parseInt(temp_line_poly_array[1]), parseInt(temp_line_poly_array[2]), parseInt(temp_line_poly_array[3]), parseInt(temp_line_poly_array[4])
                            ];
                        if ("undefined" == typeof groups[temp_line_poly_array[5]])
                            groups[temp_line_poly_array[5]] = [];
                        groups[temp_line_poly_array[5]].push(temp_line_poly_array[0]);
                    }

                }//end of if elements

            }//end of for
        }//end of if text
        return true;
    }
    calculateMesh() {
        this.data.drawTree.Dimension_2OR3 = this.data.source.dimension;

        for (let i in this.data.source.mesh) {
            let perMesh = this.data.source.mesh[i];
            if (perMesh.meshType == "Hexahedra Nnode 8") {
                this.Hexahedra_N_8(perMesh);
            }
            if (perMesh.meshType == "Quadrilateral Nnode 4") {
                this.Quadrilateral_N_4(perMesh);
            }
            if (perMesh.meshType == "Quadrilateral Nnode 9") {
                this.Quadrilateral_N_4(perMesh);
            }
            if (perMesh.meshType == "Triangle Nnode 3") {
                this.Triangle_N_3(perMesh);
            }
            if (perMesh.meshType == "Linear Nnode 2") {
                this.Line_N_2(perMesh);
            }
            if (perMesh.meshType == "Point Nnode 1") {
                this.Point_N_1(perMesh);
            }
        }
        // this.calculateNetwork();

    }
    // calculateNetwork() {
    //     for (let i in this.data.drawTree) {
    //         if (i == "Dimension_2OR3") {
    //             continue;
    //         }
    //         let perMesh = this.data.drawTree[i];
    //         for (let j in perMesh.network.list) {
    //             perMesh.network.index.push(perMesh.network.list[j].index);
    //             perMesh.network.xyz.push(perMesh.network.list[j].xyz);
    //         }
    //         perMesh.network.list = [];
    //     }
    // }
    Point_N_1(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let nodes = perMesh.nodes;
        //init
        if (typeof this.data.drawTree[meshName] == "undefined") {
            this.data.drawTree[meshName] = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let mesh = this.data.drawTree[meshName];

        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            mesh.points.index.push(perOne[0]);
            mesh.points.xyz.push(
                nodes[perOne[0]][0], nodes[perOne[0]][1], nodes[perOne[0]][2],
            );
        }
    }
    Line_N_2(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let nodes = perMesh.nodes;
        //init
        if (typeof this.data.drawTree[meshName] == "undefined") {
            this.data.drawTree[meshName] = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let mesh = this.data.drawTree[meshName];

        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            mesh.lines.index.push(perOne[0], perOne[1]);

            mesh.lines.xyz.push(
                nodes[perOne[0]][0], nodes[perOne[0]][1], nodes[perOne[0]][2],
                nodes[perOne[1]][0], nodes[perOne[1]][1], nodes[perOne[1]][2],
            );
            mesh.lines.color.push(this.colors.line[0], this.colors.line[1], this.colors.line[2], this.colors.line[0], this.colors.line[1], this.colors.line[2]);
            mesh.lines.cm.push(
                this.defaultColorR, this.defaultColorG, this.defaultColorB,
                this.defaultColorR, this.defaultColorG, this.defaultColorB,
            );

            // mesh.shellPoints.index[points[0]] = nodes[points[0]];
            if (typeof mesh.shellPoints.index == "undefined") {
                mesh.shellPoints.index = {};
            }
            if (typeof mesh.shellPoints.index[perOne[0]] == "undefined") {
                mesh.shellPoints.index[perOne[0]] = [nodes[perOne[0]][0], nodes[perOne[0]][1], nodes[perOne[0]][2]];
            }
            if (typeof mesh.shellPoints.index[perOne[1]] == "undefined") {
                mesh.shellPoints.index[perOne[1]] = [nodes[perOne[1]][0], nodes[perOne[1]][1], nodes[perOne[1]][2]];
            }
            if (typeof mesh.elementsIndex == "undefined") {
                mesh.elementsIndex = [];
            }
            mesh.elementsIndex.push(i);
        }
    }
    Triangle_N_3(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let nodes = perMesh.nodes;
        let fases = {};
        //init
        if (typeof this.data.drawTree[meshName] == "undefined") {
            this.data.drawTree[meshName] = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let mesh = this.data.drawTree[meshName];

        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            let points = perOne;//.points;

            // let lines = [
            //     points[0], points[1], points[1], points[2], points[2], points[0],

            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }

            let top1 = [points[0], points[1], points[2]];

            if (typeof fases[top1.join("_")] == "undefined") {
                fases[top1.join("_")] = {
                    meshName: meshName,
                    points: top1,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                    enable: true,
                    index: 0
                };
            }
            else {
                fases[top1.join("_")].enable = false;
            }
        }
        // output
        let frameLinesTemp = {};
        for (let i in fases) {
            let perOne = fases[i];
            if (perOne.enable) {
                let points = perOne.points;

                mesh.triangles.index.push(points[0], points[1], points[2]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1,
                );
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                //{},can overwrite 
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
            }
        }
    }
    Hexahedra_N_8(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let nodes = perMesh.nodes;
        let cubeFace = {};
        //init
        if (typeof this.data.drawTree[meshName] == "undefined") {
            this.data.drawTree[meshName] = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let mesh = this.data.drawTree[meshName];
        // compute
        for (let i in elements.elements) {
            let points = elements.elements[i];
            let e1 = points[0];//element 的点序列，矩形体
            let e2 = points[1];
            let e3 = points[2];
            let e4 = points[3];
            let e5 = points[4];
            let e6 = points[5];
            let e7 = points[6];
            let e8 = points[7];

            let box = [
                [e5, e1, e2, e6],//f
                [e8, e4, e3, e7],//b
                [e6, e2, e3, e7],//U
                [e5, e1, e4, e8],//D
                [e8, e5, e6, e7],//L
                [e4, e1, e2, e3],//R
            ];
            // let lines = [
            //     points[4], points[5], points[5], points[1], points[1], points[0], points[0], points[4],
            //     points[4], points[7], points[5], points[6], points[1], points[2], points[0], points[3],
            //     points[7], points[6], points[6], points[2], points[2], points[3], points[3], points[7],

            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }
            mesh.network.push({
                index: points,
                // xyz: [nodes[e1], nodes[e2], nodes[e3], nodes[e4], nodes[e5], nodes[e6], nodes[e7], nodes[e8]],
                face: "6"
            });

            for (let side of box) {
                let per = JSON.parse(JSON.stringify(side)).sort().join("-");
                if (typeof cubeFace[per] == "undefined") {
                    cubeFace[per] = {
                        points: side,
                        enable: true,
                    };
                }
                else {
                    cubeFace[per].enable = false;
                }
            }
        }
        // output
        let frameLinesTemp = {};
        for (let i in cubeFace) {
            let perFace = cubeFace[i];
            if (perFace.enable) {
                let points = perFace.points;
                mesh.triangles.index.push(points[0], points[1], points[3], points[1], points[2], points[3]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                    nodes[points[3]][0], nodes[points[3]][1], nodes[points[3]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1,

                    0, 0,
                    1, 0,
                    1, 1);
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,

                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];
                mesh.shellPoints.index[points[3]] = nodes[points[3]];
                // mesh.triangles.pressure.push();

                let per;
                per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[0], points[1]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[1], points[2]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[2], points[3]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[2], points[3]],
                        enable: true,
                    };
                }
                per = JSON.parse(JSON.stringify([points[3], points[0]])).sort().join("-");
                if (typeof frameLinesTemp[per] == "undefined") {
                    frameLinesTemp[per] = {
                        points: [points[3], points[0]],
                        enable: true,
                    };
                }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
            }
        }

    }


    Quadrilateral_N_4(perMesh) {
        let elements = perMesh.elements;
        let meshName = perMesh.meshName;
        let nodes = perMesh.nodes;
        let fases = {};
        //init
        if (typeof this.data.drawTree[meshName] == "undefined") {
            this.data.drawTree[meshName] = JSON.parse(JSON.stringify(this.PLTFR));
        }
        let mesh = this.data.drawTree[meshName];
        let frameLinesTemp = {};
        for (let i in elements.elements) {
            let perOne = elements.elements[i];
            let points = perOne;//.points;

            // let lines = [
            //     points[0], points[1], points[1], points[2], points[2], points[3], points[3], points[0],

            // ];
            // for (let line_i = 0; line_i < lines.length; line_i += 2) {
            //     let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
            //     if (typeof mesh.network.list[perStore] == "undefined") {
            //         mesh.network.list[perStore] = {
            //             index: [lines[line_i], lines[line_i + 1]],
            //             xyz: [
            //                 nodes[lines[line_i]], nodes[lines[line_i + 1]]
            //             ]
            //         };
            //     }
            // }


            let top1 = [points[0], points[1], points[3]];
            let top2 = [points[1], points[2], points[3]];

            if (typeof fases[top1.join("_")] == "undefined") {
                fases[top1.join("_")] = {
                    meshName: meshName,
                    points: top1,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1,
                    ],
                    enable: true,
                    index: 0
                };
            }
            else {
                fases[top1.join("_")].enable = false;
            }
            if (typeof fases[top2.join("_")] == "undefined") {
                fases[top2.join("_")] = {
                    meshName: meshName,
                    points: top2,
                    uv: [
                        0, 0,
                        1, 0,
                        1, 1
                    ],
                    enable: true,
                    index: 1
                };
            }
            else fases[top2.join("_")].enable = false;

            if (typeof frameLinesTemp[[points[0], points[1]].sort().join("_")] == "undefined") {
                frameLinesTemp[[points[0], points[1]].sort().join("_")] = {
                    points: [points[0], points[1]],
                    enable: true,
                };
            }
            if (typeof frameLinesTemp[[points[1], points[2]].sort().join("_")] == "undefined") {
                frameLinesTemp[[points[1], points[2]].sort().join("_")] = {
                    points: [points[1], points[2]],
                    enable: true,
                };
            }
            if (typeof frameLinesTemp[[points[2], points[3]].sort().join("_")] == "undefined") {
                frameLinesTemp[[points[2], points[3]].sort().join("_")] = {
                    points: [points[2], points[3]],
                    enable: true,
                };
            }
            if (typeof frameLinesTemp[[points[3], points[0]].sort().join("_")] == "undefined") {
                frameLinesTemp[[points[3], points[0]].sort().join("_")] = {
                    points: [points[3], points[0]],
                    enable: true,
                };
            }
        }
        // output

        for (let i in fases) {
            let perOne = fases[i];
            if (perOne.enable) {
                let points = perOne.points;

                mesh.triangles.index.push(points[0], points[1], points[2]);
                mesh.triangles.xyz.push(
                    nodes[points[0]][0], nodes[points[0]][1], nodes[points[0]][2],
                    nodes[points[1]][0], nodes[points[1]][1], nodes[points[1]][2],
                    nodes[points[2]][0], nodes[points[2]][1], nodes[points[2]][2],
                );
                mesh.triangles.uv.push(
                    0, 0,
                    1, 0,
                    1, 1,
                );
                mesh.triangles.normal.push(
                    0, 0, 1,
                    0, 0, 1,
                    0, 0, 1,
                );
                mesh.triangles.cm.push(
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                    this.defaultColorR, this.defaultColorG, this.defaultColorB,
                );
                mesh.shellPoints.index[points[0]] = nodes[points[0]];
                mesh.shellPoints.index[points[1]] = nodes[points[1]];
                mesh.shellPoints.index[points[2]] = nodes[points[2]];

                // let per;
                // per = JSON.parse(JSON.stringify([points[0], points[1]])).sort().join("-");
                // if (typeof frameLinesTemp[per] == "undefined") {
                //     frameLinesTemp[per] = {
                //         points: [points[0], points[1]],
                //         enable: true,
                //     };
                // }
                // per = JSON.parse(JSON.stringify([points[1], points[2]])).sort().join("-");
                // if (typeof frameLinesTemp[per] == "undefined") {
                //     frameLinesTemp[per] = {
                //         points: [points[1], points[2]],
                //         enable: true,
                //     };
                // }
                // per = JSON.parse(JSON.stringify([points[2], points[0]])).sort().join("-");
                // if (typeof frameLinesTemp[per] == "undefined") {
                //     frameLinesTemp[per] = {
                //         points: [points[2], points[0]],
                //         enable: true,
                //     };
                // }
            }
        }
        for (let i in frameLinesTemp) {
            let perLine = frameLinesTemp[i];
            if (perLine.enable) {
                mesh.frameLines.index.push(perLine.points[0], perLine.points[1]);
                mesh.frameLines.xyz.push(
                    nodes[perLine.points[0]][0], nodes[perLine.points[0]][1], nodes[perLine.points[0]][2],
                    nodes[perLine.points[1]][0], nodes[perLine.points[1]][1], nodes[perLine.points[1]][2],
                );
            }
        }
    }
}
export { MeshGID };