/* eslint-disable indent */
import {
  Vector2,
  Color,
  MeshStandardMaterial,
  MeshBasicMaterial,
  LineBasicMaterial,
  ShaderMaterial,
  MeshPhongMaterial,
  // Line,
  // Group,
  // FrontSide,
  DoubleSide,
  PointsMaterial,
} from "three";
class DefineMaterial {

  constructor() {
  }

  Point() {
    return new PointsMaterial({
      color: 0xff8800,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -2.5,
    });
  }
  //外框线
  FameLine() {
    return new LineBasicMaterial({
      color: 0x000000,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -1.5,
    });
  }
  //画梁等的材质，白色
  Line() {
    // return new LineBasicMaterial({
    //   vertexColors: true,
    //   // color: 0xffffff,
    //   polygonOffset: true,
    //   polygonOffsetFactor: -1.1,
    //   polygonOffsetUnits: -1.5,
    // });
    return new ShaderMaterial({
      clipping: true,
      side: DoubleSide,
      uniforms: {
        clippingPlanes: {
          needsUpdate: true,
          value: []
        },
        time: { value: 1.0 },
        val: { value: [1.0, 0.0, 0.3] },
        n: { value: 12 },
        resolution: { value: new Vector2() },
      },
      vertexShader:
        " #if NUM_CLIPPING_PLANES > 0        \n\
       varying vec3 vClipPosition;          \n\
                       uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ];                \n\
              #endif    \n\
                 attribute vec4 color;  \n\
                 attribute vec2 cm;  \n\
                 varying vec2 val;  \n\
                 varying vec2 vuv;    \n\
                 varying vec3 vposition;  \n\
                 void main() {   \n\
                  vec3 transformed = vec3( position );                \n\
                  vec4 mvPosition = vec4( transformed, 1.0 );              \n\
                  #ifdef USE_INSTANCING            \n\
                    mvPosition = instanceMatrix * mvPosition;            \n\
                  #endif            \n\
                  mvPosition = modelViewMatrix * mvPosition;        \n\
                  val=cm;  \n\
                  vposition = position;  \n\
                  vuv = uv;  \n\
                  // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );        \n\
                    #if NUM_CLIPPING_PLANES > 0        \n\
                        vClipPosition = - mvPosition.xyz;   \n\
                    #endif  \n\
                    gl_Position = projectionMatrix * mvPosition;        \n\
                   }"

      ,
      fragmentShader:
        "  #if NUM_CLIPPING_PLANES > 0   \n\
        varying vec3 vClipPosition; \n\
        uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ]; \n\
      #endif  \n \
      precision mediump float;  \n\
      precision mediump int;     \n\
      uniform int n;  \n\
      varying vec2 val;  \n\
      varying vec2 vuv;  \n\
      varying vec3 vposition;  \n\
       void main() {  \n\
       #if NUM_CLIPPING_PLANES > 0  \n\
        vec4 plane;  \n\
        #pragma unroll_loop_start  \n\
        for ( int i = 0; i < UNION_CLIPPING_PLANES; i ++ ) {  \n\
          plane = clippingPlanes[ i ];  \n\
          if ( dot( vClipPosition, plane.xyz ) > plane.w ) discard;  \n\
        }  \n\
        #pragma unroll_loop_end  \n\
        #if UNION_CLIPPING_PLANES < NUM_CLIPPING_PLANES  \n\
          bool clipped = true;  \n\
          #pragma unroll_loop_start  \n\
          for ( int i = UNION_CLIPPING_PLANES; i < NUM_CLIPPING_PLANES; i ++ ) {  \n\
            plane = clippingPlanes[ i ];  \n\
            clipped = ( dot( vClipPosition, plane.xyz ) > plane.w ) && clipped;  \n\
          }  \n\
          #pragma unroll_loop_end  \n\
          if ( clipped ) discard;  \n\
        #endif  \n\
      #endif  \n\
          float w1 = (1.0 - vuv.x);  \n\
          float w2 = (vuv.x - 0.);  \n\
          float w3 =0.;  \n\
          float v = w1*val[0] + w2*val[1];  \n\
           float dv = 1.0 / float(n);  \n\
          float nv = float(v/dv);  \n\
          float vv = float(nv)*dv;  \n\
               if( vv <= 0.08333333333333333) {  \n\
              gl_FragColor = vec4(0.0, 0, 1.0, 1.0);  \n\
           }  \n\
          else if ( vv > 0.08333333333333333 && vv <= 0.16666666666666666) {  \n\
               gl_FragColor = vec4(0.0, 0.3607843137254902, 1.0 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.16666666666666666 && vv <= 0.250) {  \n\
               gl_FragColor = vec4(0.0, 0.7254901960784313, 1.0 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.250 && vv <= 0.3333333333333333) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.9058823529411765 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.3333333333333333 && vv <= 0.41666666666666663) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.5450980392156862 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.41666666666666663 && vv <= 0.49999999999999994) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.1803921568627451 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.49999999999999994 && vv <= 0.5833333333333333) {  \n\
               gl_FragColor = vec4( 0.1803921568627451 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.5833333333333333 && vv <= 0.6666666666666666) {  \n\
               gl_FragColor = vec4( 0.5450980392156862 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.6666666666666666 && vv <= 0.75) {  \n\
               gl_FragColor = vec4( 0.9058823529411765 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.75 && vv <= 0.8333333333333334) {  \n\
               gl_FragColor = vec4( 1. ,.7254901960784313,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.8333333333333334 && vv <= 0.9166666666666667) {  \n\
               gl_FragColor = vec4( 1. ,.3607843137254902,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.9166666666666667 && vv <= 1.0) {  \n\
            gl_FragColor = vec4( 1. ,.0,0., 1.0);  \n\
         }  \n\
          else{  \n\
            gl_FragColor = vec4( .0 ,.0, 1., 1.0);  \n\
          }  \n\
       } "

      ,
      polygonOffset: true,
      polygonOffsetFactor: 3.5,
      polygonOffsetUnits: 5.5,
    });
  }
  Triangle() {
    return new MeshPhongMaterial({
      vertexColors: true,
      // color: 0xff0000,
      side: DoubleSide,
      // side: FrontSide,
      polygonOffset: true,
      polygonOffsetFactor: 1.5,
      polygonOffsetUnits: 1.5,
    });
  }
  TriangleShader() {
    return new ShaderMaterial({
      clipping: true,
      side: DoubleSide,
      uniforms: {
        time: { value: 1.0 },
        val: { value: [1.0, 0.0, 0.3] },
        resolution: { value: new Vector2() },
      },
      vertexShader:
        " attribute vec4 color;" +
        " attribute vec3 cm;" +
        " varying vec3 val;" +
        " varying vec2 vuv;  " +
        " varying vec3 vposition;" +
        " void main() { " +
        "  val=cm;" +
        "     vposition = position;" +
        "    vuv = uv;" +
        "    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );" +
        "   }",
      fragmentShader:
        "varying vec3 val;" +
        "varying vec2 vuv;" +
        "varying vec3 vposition;" +
        "void main() {" +
        "float w1 = (1.0 - vuv.x);" +
        "float w2 = (vuv.x - vuv.y);" +
        "float w3 = vuv.y;" +
        "float v = w1*val[0] + w2*val[1] + w3*val[2];" +
        " if( v <= 0.25 ) {" +
        "     gl_FragColor = vec4(0.0, v/0.25, 1.0, 1.0);" +
        " }else if ( v > 0.25 && v <= 0.5) {" +
        "     gl_FragColor = vec4(0.0, 1.0, 1.0-(v-0.25)/0.25 , 1.0);" +
        " }else if ( v > 0.5 && v <= 0.75) {" +
        "      gl_FragColor = vec4( (v-0.5)/0.25, 1.0, 0.0, 1.0);" +
        " }else if ( v > 0.75 && v <= 1.){" +
        "      gl_FragColor = vec4( 1.0, 1.0-(v-0.75)/0.25, 0.0, 1.0);" +
        "  }" +
        "else {" +
        "      gl_FragColor = vec4( 0.,0.0 ,1., 1.0);" +
        "}" +
        " } ",

      polygonOffset: true,
      polygonOffsetFactor: 1.5,
      polygonOffsetUnits: 1.5,
    });
  }
  TriangleShaderStair() {
    return new ShaderMaterial({
      clipping: true,
      side: DoubleSide,
      // side: FrontSide,
      uniforms: {
        clippingPlanes: {
          needsUpdate: true,
          value: []
        },
        time: { value: 1.0 },
        val: { value: [1.0, 0.0, 0.3] },
        n: { value: 12 },
        resolution: { value: new Vector2() },
      },
      vertexShader:
        " #if NUM_CLIPPING_PLANES > 0        \n\
       varying vec3 vClipPosition;          \n\
                       uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ];                \n\
              #endif    \n\
                 attribute vec4 color;  \n\
                 attribute vec3 cm;  \n\
                 varying vec3 val;  \n\
                 varying vec2 vuv;    \n\
                 varying vec3 vposition;  \n\
                 void main() {   \n\
                  vec3 transformed = vec3( position );                \n\
                  vec4 mvPosition = vec4( transformed, 1.0 );              \n\
                  #ifdef USE_INSTANCING            \n\
                    mvPosition = instanceMatrix * mvPosition;            \n\
                  #endif            \n\
                  mvPosition = modelViewMatrix * mvPosition;        \n\
                  val=cm;  \n\
                  vposition = position;  \n\
                  vuv = uv;  \n\
                  // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );        \n\
                    #if NUM_CLIPPING_PLANES > 0        \n\
                        vClipPosition = - mvPosition.xyz;   \n\
                    #endif  \n\
                    gl_Position = projectionMatrix * mvPosition;        \n\
                   }"

      ,
      fragmentShader:
        "  #if NUM_CLIPPING_PLANES > 0   \n\
        varying vec3 vClipPosition; \n\
        uniform vec4 clippingPlanes[ NUM_CLIPPING_PLANES ]; \n\
      #endif  \n \
      precision mediump float;  \n\
      precision mediump int;     \n\
      uniform int n;  \n\
      varying vec3 val;  \n\
      varying vec2 vuv;  \n\
      varying vec3 vposition;  \n\
       void main() {  \n\
       #if NUM_CLIPPING_PLANES > 0  \n\
        vec4 plane;  \n\
        #pragma unroll_loop_start  \n\
        for ( int i = 0; i < UNION_CLIPPING_PLANES; i ++ ) {  \n\
          plane = clippingPlanes[ i ];  \n\
          if ( dot( vClipPosition, plane.xyz ) > plane.w ) discard;  \n\
        }  \n\
        #pragma unroll_loop_end  \n\
        #if UNION_CLIPPING_PLANES < NUM_CLIPPING_PLANES  \n\
          bool clipped = true;  \n\
          #pragma unroll_loop_start  \n\
          for ( int i = UNION_CLIPPING_PLANES; i < NUM_CLIPPING_PLANES; i ++ ) {  \n\
            plane = clippingPlanes[ i ];  \n\
            clipped = ( dot( vClipPosition, plane.xyz ) > plane.w ) && clipped;  \n\
          }  \n\
          #pragma unroll_loop_end  \n\
          if ( clipped ) discard;  \n\
        #endif  \n\
      #endif  \n\
          float w1 = (1.0 - vuv.x);  \n\
          float w2 = (vuv.x - vuv.y);  \n\
          float w3 = vuv.y;  \n\
          float v = w1*val[0] + w2*val[1] + w3*val[2];  \n\
           float dv = 1.0 / float(n);  \n\
          float nv = float(v/dv);  \n\
          float vv = float(nv)*dv;  \n\
           if( vv <= 0.08333333333333333) {  \n\
              gl_FragColor = vec4(0.0, 0, 1.0, 1.0);  \n\
           }  \n\
          else if ( vv > 0.08333333333333333 && vv <= 0.16666666666666666) {  \n\
               gl_FragColor = vec4(0.0, 0.3607843137254902, 1.0 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.16666666666666666 && vv <= 0.250) {  \n\
               gl_FragColor = vec4(0.0, 0.7254901960784313, 1.0 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.250 && vv <= 0.3333333333333333) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.9058823529411765 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.3333333333333333 && vv <= 0.41666666666666663) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.5450980392156862 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.41666666666666663 && vv <= 0.49999999999999994) {  \n\
               gl_FragColor = vec4(0.0, 1., 0.1803921568627451 , 1.0);  \n\
          }  \n\
          else if ( vv > 0.49999999999999994 && vv <= 0.5833333333333333) {  \n\
               gl_FragColor = vec4( 0.1803921568627451 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.5833333333333333 && vv <= 0.6666666666666666) {  \n\
               gl_FragColor = vec4( 0.5450980392156862 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.6666666666666666 && vv <= 0.75) {  \n\
               gl_FragColor = vec4( 0.9058823529411765 ,1.,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.75 && vv <= 0.8333333333333334) {  \n\
               gl_FragColor = vec4( 1. ,.7254901960784313,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.8333333333333334 && vv <= 0.9166666666666667) {  \n\
               gl_FragColor = vec4( 1. ,.3607843137254902,0., 1.0);  \n\
          }  \n\
          else if ( vv > 0.9166666666666667 && vv <= 1.0) {  \n\
            gl_FragColor = vec4( 1. ,.0,0., 1.0);  \n\
         }  \n\
          else{  \n\
            gl_FragColor = vec4( .0 ,.0, 1., 1.0);  \n\
          }  \n\
          if(val[0]==-9. && val[1]==-9. && val[2]==-9.){ gl_FragColor = vec4( 1.0 ,1.0, 1.0, 1.0);  }\n\
       } "

      ,
      polygonOffset: true,
      polygonOffsetFactor: 3.5,
      polygonOffsetUnits: 5.5,
    });
  }
  LineClipping() {
    return new LineBasicMaterial({
      color: 0x000000,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -5.5,
    });
  }
  PolyOfClipping() {
    return new MeshBasicMaterial({
      // color: this.color.planeColor,
      vertexColors: true,
      // color: 0xff0000,
      side: DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: 1.5,
      polygonOffsetUnits: 1.5,
      wireframe: false,
    });
  }
  Elset() {
    return new MeshBasicMaterial({
      color: 0xff8800,
      side: DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: 1.0,
      polygonOffsetUnits: 1.0,
      wireframe: false,
    });
  }
  Nset() {
    return new PointsMaterial({
      color: 0xff8800,
      //  size: 1,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -2.5,
    });
  }
  PointBox() {
    return new MeshStandardMaterial({
      color: 0x000,
      //  size: 1,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -2.5,
    });
  }
  PointBoxLight() {
    return new MeshStandardMaterial({
      color: 0xffffff,
      //  size: 1,
      polygonOffset: true,
      polygonOffsetFactor: -1.1,
      polygonOffsetUnits: -2.5,
    });
  }



}
export { DefineMaterial };