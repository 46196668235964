class ResAbaqus {
    constructor(data) {
        this.data = {
            type: "dat",
            res: {

            },
            nameList: {},
            resKey: {
                // data input json
                // file: "data",
                // title: "Computation algorithm: EXTRAPOLATE_COMPUTE_AVERAGE",
                pressure_kind: {
                    //   "U.Magnitude": 2,
                    //   "U.U1": 3,
                    //   "U.U2": 4,
                    //   "U.U3": 5,
                    //   "S.Mises": 6,
                    //   "S.S11": 7,
                    //   "S.S22": 8,
                    //   "S.S33": 9,
                    //   "S.S12": 10,
                    //   "S.S13": 11,
                    //   "S.S23": 12,
                },
                pressure_title: {
                    //   "U.Magnitude": "U.Magnitude",
                    //   "U.U1": "U.U1",
                    //   "U.U2": "U.U",
                    //   "U.U3": "U.U3",
                    //   "S.Mises": "S.Mises",
                    //   "S.S11": "S.S11",
                    //   "S.S22": "S.S22",
                    //   "S.S33": "S.S33",
                    //   "S.S12": "S.S12",
                    //   "S.S13": "S.S13",
                    //   "S.S23": "S.S23",
                },
                part_split: {
                    title: ["for region: ", "for part: "],
                    split: [
                        [":", 1],
                        [" ", 1],
                        [".Region", 0],
                    ], //分割顺序与特征与保留部分
                },
                Pt_Data: {
                    start_line: "------------------",
                    end_line: "",
                },
                transform: {
                    x: false,
                    y: false,
                    z: false,
                    array: [],
                },
            }
        };
        this.MSG = {};
        this.succeed = false;
        this.init(data);
        return this;
    }
    init(data) {
        this.succeed = this.getRES(data);
    }
    getData() {

    }
    getRES(data) {
        if (data.data) {
            this.data.res[data.index] = this.data_parse(data.data, this.data.resKey);
        }
        return true;
    }
    data_parse(text, pressures) {
        let lines = text.split("\r\n");
        let Part_name = false;//=region name
        let flage_region = false;
        let flag_data = false;

        let region_line_title = pressures.part_split.title;
        // let region_line_split = pressures.part_split.split;

        let pressure_I = false;

        let json_data_parse = {};//本函数内的总json
        let kindOfPressureOfInstance = {};
        let pressureNameList = {};

        for (var per_line of lines) {//per line 
            if (per_line.includes(region_line_title[0]) || per_line.includes(region_line_title[1])) {//数据开始 for regio
                flage_region = true;//开始
                Part_name = this.get_region_name(per_line, region_line_title);
                if (Part_name.indexOf(".region_") != -1) {
                    Part_name = Part_name.split(".region_")[0];
                    // kindOfPressureOfInstance = {
                    //     pressure_kind: {},
                    //     pressure_title: {},
                    // };
                    // pressureNameList = {};
                }
                // console.log('=====',Part_name)
                pressure_I = true;
                continue;
            }
            if (flage_region) {
                if (per_line.length == 0 && pressure_I == true) {
                    pressure_I = 2;
                    continue;
                }

                if (pressure_I === 2) {
                    kindOfPressureOfInstance = {
                        pressure_kind: {},
                        pressure_title: {},
                    };
                    pressureNameList = {};
                    if (per_line.indexOf("Node Label") != -1) {
                        per_line = per_line.replace("Node Label", "Node-Label");
                    }
                    let name_line = per_line.split(/\s+/);
                    let startI = 0;
                    let realI = 0;
                    for (let name_i = 0; name_i < name_line.length; name_i++, startI++) {
                        if (startI > 1) {
                            let name = name_line[name_i];
                            // if (Part_name == "liner-1" && name == "S.Mises") {
                            //     let abc = 1;
                            // }
                            // if (Part_name == "zhameng1-1" && name == "LE.Max.") {
                            //     let abc = 1;
                            // }
                            if (name.substr(name.length - 1, 1) == ".") {

                                kindOfPressureOfInstance.pressure_kind[name + name_line[name_i + 1]] = name_i;
                                kindOfPressureOfInstance.pressure_title[name + name_line[name_i + 1]] = name + name_line[name_i + 1];
                                // pressureNameList[name + name_line[name_i + 1]] = name_i;
                                pressureNameList[name_i - realI++] = name + name_line[name_i + 1];
                                name_i++;
                            }
                            else {
                                pressureNameList[name_i] = name;
                                kindOfPressureOfInstance.pressure_kind[name] = name_i;
                                kindOfPressureOfInstance.pressure_title[name] = name;

                            }
                            if (name == "U.U1" || name == "U.U2" || name == "U.U3" || name == "V.V1" || name == "V.V2" || name == "V.V3") {
                                pressure_I = 4;
                            }
                        }
                    }
                    // this.setPressuresTranArray(kindOfPressureOfInstance.pressure_title);
                    pressure_I++;
                    // console.log(Part_name, kindOfPressureOfInstance.pressure_kind, pressureNameList);
                    continue;
                }
                if (pressure_I === 3 && !per_line.includes(pressures.Pt_Data.start_line)) {

                    kindOfPressureOfInstance = {
                        pressure_kind: {},
                        pressure_title: {},
                    };
                    per_line = per_line.replace(/\s+/g, "");
                    let name_line = per_line.split("@");
                    let startI = 0;
                    // for (let name_i = 0; name_i < name_line.length; name_i++, startI++) 
                    let name_i = 1;
                    for (let i in pressureNameList) {

                        let name = pressureNameList[i];
                        kindOfPressureOfInstance.pressure_kind[name] = i;
                        // kindOfPressureOfInstance.pressure_kind[name + "@" + name_line[name_i++]] = i;
                        // kindOfPressureOfInstance.pressure_title[name] = name;
                        if (name == "S.Mises") {
                            let abc = 1;
                        }

                    }

                    pressure_I = false;
                    continue;
                }
                // else {
                //     pressure_I = false;
                // }
            }
            // if (pressure_I) pressure_I++;

            if (flage_region) {
                if (per_line.includes(pressures.Pt_Data.start_line)) {
                    flag_data = true;
                    continue;
                }
                if (flag_data && per_line === pressures.Pt_Data.end_line) {
                    flag_data = false;
                    flage_region = false;
                    Part_name = false;
                    continue;
                }
                if (flag_data) {//开始数据处理
                    let data_line = per_line.split(/\s+/);
                    let per_vertex = data_line[1]; //vertec 

                    for (let key in kindOfPressureOfInstance.pressure_kind) {//pressure kind of per line 
                        if (key == "LE.Max.Prin@Loc3") {
                            let abc = 1;
                        }
                        let output_name = key;// pressure name
                        let output_name_index = kindOfPressureOfInstance.pressure_kind[key]; // No.

                        if (typeof json_data_parse[key] == "undefined") {//per pressure
                            json_data_parse[key] = {};
                        }
                        if (typeof json_data_parse[key][Part_name] == "undefined") {//the "Part_name" in here is Intance name ,not real part name 
                            json_data_parse[key][Part_name] = [];
                        }
                        let v = parseFloat(data_line[output_name_index]);               //float 
                        json_data_parse[key][Part_name][per_vertex] = v;
                    }//end of kind of presures
                }//end of data
            }// end of part of instance 
        }
        return json_data_parse;
    }
    get_region_name(line, region_line_title) {
        // let temp_1 = line.split("\r")[0];//去除 \r
        let name;
        if (line.includes(region_line_title[0])) {
            name = line.split(region_line_title[0])[1];
        }
        else if (line.includes(region_line_title[1])) {
            name = line.split(region_line_title[1])[1];
        }

        name = name.toLowerCase();
        return name;
    }
    setPressuresTranArray(pressures) {
        for (let i in pressures) {
            if (i == "U.U1") {
                this.setPressuresTransX(i);
                this.setPressuresTransPush(i);
            }
            if (i == "U.U2") {
                this.setPressuresTransY(i);
                this.setPressuresTransPush(i);
            }
            if (i == "U.U3") {
                this.setPressuresTransZ(i);
                this.setPressuresTransPush(i);
            }
            if (i == "V.V1" && this.data.resKey.transform.x != "U.U1") {
                this.setPressuresTransX(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V1") {
                this.setPressuresTransPush(i);
            }
            if (i == "V.V2" && this.data.resKey.transform.x != "U.U2") {
                this.setPressuresTransY(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V2") {
                this.setPressuresTransPush(i);
            }
            if (i == "V.V3" && this.data.resKey.transform.x != "U.U3") {
                this.setPressuresTransZ(i);
                this.setPressuresTransPush(i);
            }
            else if (i == "V.V3") {
                this.setPressuresTransPush(i);
            }
        }
    }
    setPressuresTransPush(pressure) {
        this.data.resKey.transform.array.push(pressure);
    }
    setPressuresTransX(pressure) {
        this.data.resKey.transform.x = pressure;
    }
    setPressuresTransY(pressure) {
        this.data.resKey.transform.y = pressure;
    }
    setPressuresTransZ(pressure) {
        this.data.resKey.transform.z = pressure;
    }

}
export { ResAbaqus };