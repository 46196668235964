
class Octree {
    constructor(bounds, depth = 6, maxChild = 1, maxDepth = 10) {
        this.bounds = bounds;
        this.setting = {
            w: false,
            h: false,
            d: false,
            depth: depth,
            maxChild: maxChild,
            maxDepth: maxDepth,
        };
        this.leaves = false;
        this.children = {
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            q6: false,
            q7: false,
            q0: false,
        };
    }
    ///////////////////////////////////////////////////
    // base of octree


    addLeaf(idx, array, depth) {
        var isLeaf = this.children.q0 === false;
        if (isLeaf) {
            // TODO: this memory could be recycled to avoid GC
            if (this.leaves === false) {
                this.leaves = [idx];
            } else {
                this.leaves.push(idx);
            }
            if (this.leaves.length > this.setting.maxChild && depth < this.setting.maxDepth) {
                this.subDivide();
                for (var i = 0; i < this.leaves.length; ++i) {
                    this.addLeaf(this.leaves[i], array, depth + 1);
                }
                this.removeAllLeaves();
            }
        } else {
            var x = array[idx][0],
                y = array[idx][1],
                z = array[idx][2];
            var bounds = this.bounds;
            var quadIdx = 0; // assume NW
            if (x > bounds.x) {
                quadIdx += 1; // nope, we are in E part
            }
            if (y > bounds.y) {
                quadIdx += 2; // Somewhere south.
            }
            if (z > bounds.z) {
                quadIdx += 4; // Somewhere far
            }

            var child = this.getChild(this, quadIdx);
            child.addLeaf(idx, array, depth + 1);
        }
    }
    removeAllLeaves() {
        this.leaves = false;
    }

    subDivide() {
        var bounds = this.bounds;
        var quarter = bounds.half / 2;

        this.children.q0 = new Octree(new Bounds3(bounds.x - quarter, bounds.y - quarter, bounds.z - quarter, quarter));
        this.children.q1 = new Octree(new Bounds3(bounds.x + quarter, bounds.y - quarter, bounds.z - quarter, quarter));
        this.children.q2 = new Octree(new Bounds3(bounds.x - quarter, bounds.y + quarter, bounds.z - quarter, quarter));
        this.children.q3 = new Octree(new Bounds3(bounds.x + quarter, bounds.y + quarter, bounds.z - quarter, quarter));
        this.children.q4 = new Octree(new Bounds3(bounds.x - quarter, bounds.y - quarter, bounds.z + quarter, quarter));
        this.children.q5 = new Octree(new Bounds3(bounds.x + quarter, bounds.y - quarter, bounds.z + quarter, quarter));
        this.children.q6 = new Octree(new Bounds3(bounds.x - quarter, bounds.y + quarter, bounds.z + quarter, quarter));
        this.children.q7 = new Octree(new Bounds3(bounds.x + quarter, bounds.y + quarter, bounds.z + quarter, quarter));
    }
    getLeafs() {

    }
    getChild(node, idx) {
        if (idx === 0) return node.children.q0;
        if (idx === 1) return node.children.q1;
        if (idx === 2) return node.children.q2;
        if (idx === 3) return node.children.q3;
        if (idx === 4) return node.children.q4;
        if (idx === 5) return node.children.q5;
        if (idx === 6) return node.children.q6;
        if (idx === 7) return node.children.q7;
    }
    getChilren() {
        return this.children;
    }

    intersectCheck(candidate, rayOrigin, rayDirection, near, far) {
        var half = candidate.half;
        var t1 = (candidate.x - half - rayOrigin.x) / rayDirection.x,
            t2 = (candidate.x + half - rayOrigin.x) / rayDirection.x,
            t3 = (candidate.y + half - rayOrigin.y) / rayDirection.y,
            t4 = (candidate.y - half - rayOrigin.y) / rayDirection.y,
            t5 = (candidate.z - half - rayOrigin.z) / rayDirection.z,
            t6 = (candidate.z + half - rayOrigin.z) / rayDirection.z,
            tmax = Math.min(Math.min(Math.max(t1, t2), Math.max(t3, t4)), Math.max(t5, t6)),
            tmin;

        if (tmax < 0) return false;

        tmin = Math.max(Math.max(Math.min(t1, t2), Math.min(t3, t4)), Math.min(t5, t6));
        return tmin <= tmax && tmin <= far;
    }
    farEnough(x, y, z, rayOrigin, rayDirection, near, far) {
        var dist = (x - rayOrigin.x) * (x - rayOrigin.x) +
            (y - rayOrigin.y) * (y - rayOrigin.y) +
            (z - rayOrigin.z) * (z - rayOrigin.z);
        return near <= dist && dist <= far;
    }
    ///////////////////////////////////////////////////
    // pickup of octree
    query(sourceArray, rayOrigin, rayDirection) {
        let results = [];
        this.doQuery(sourceArray, results, rayOrigin, rayDirection);
        return results;
    }

    doQuery(sourceArray, results, rayOrigin, rayDirection, near, far) {
        if (near === undefined) near = 0;
        if (far === undefined) far = Number.POSITIVE_INFINITY;
        // we save as squar, to avoid expensive sqrt() operation
        near *= near;
        far *= far;

        if (!this.intersectCheck(this.bounds, rayOrigin, rayDirection, near, far)) return;
        var items = this.leaves;
        // var needsCheck = typeof this.farEnough === "function";
        if (items) {
            for (var i = 0; i < items.length; ++i) {
                var idx = items[i];
                // if (needsCheck) {
                //     if (this.farEnough(sourceArray[idx][0], sourceArray[idx][1], sourceArray[idx][2]), rayOrigin, rayDirection, near, far) {
                //         results.push(idx);
                //     }
                // } else
                {
                    results.push(idx);
                }
            }
        }

        if (!this.children.q0) return;

        this.children.q0.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q1.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q2.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q3.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q4.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q5.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q6.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
        this.children.q7.doQuery(sourceArray, results, rayOrigin, rayDirection, near, far);
    }

    getFrameLines(lines = []) {
        let x = this.bounds.x;
        let y = this.bounds.y;
        let z = this.bounds.z;
        let half = this.bounds.half;
        lines.push(x + half, y + half, z + half);//1
        lines.push(x + half, y + half, z - half);//2

        lines.push(x + half, y + half, z - half);//2
        lines.push(x + half, y - half, z - half);//3

        lines.push(x + half, y - half, z - half);//3
        lines.push(x + half, y - half, z + half);//4

        lines.push(x + half, y - half, z + half);//4
        lines.push(x + half, y + half, z + half);//1


        lines.push(x - half, y + half, z + half);//5
        lines.push(x - half, y + half, z - half);//6

        lines.push(x - half, y + half, z - half);//6
        lines.push(x - half, y - half, z - half);//7

        lines.push(x - half, y - half, z - half);//7
        lines.push(x - half, y - half, z + half);//8

        lines.push(x - half, y - half, z + half);//8
        lines.push(x - half, y + half, z + half);//5

        lines.push(x + half, y + half, z + half);//1
        lines.push(x - half, y + half, z + half);//5

        lines.push(x + half, y + half, z - half);//2
        lines.push(x - half, y + half, z - half);//6

        lines.push(x + half, y - half, z - half);//3
        lines.push(x - half, y - half, z - half);//7

        lines.push(x + half, y - half, z + half);//4
        lines.push(x - half, y - half, z + half);//8

        var isLeaf = this.children.q0 === false;
        if (!isLeaf)
            for (let i in this.children) {
                let perOne = this.children[i];
                perOne.getFrameLines(lines);
            }
        return lines;
    }
}

class Bounds3 {
    constructor(x, y, z, half) {
        this.x = typeof x === "number" ? x : 0;
        this.y = typeof y === "number" ? y : 0;
        this.z = typeof z === "number" ? z : 0;
        this.half = typeof half === "number" ? half : 0;
    }

    contains(x, y, z) {
        var half = this.half;
        return this.x - half <= x && x < this.x + half &&
            this.y - half <= y && y < this.y + half &&
            this.z - half <= z && z < this.z + half;
    }
}


export { Octree, Bounds3 };