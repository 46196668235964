import { MeshOrigin } from "./meshOrigin";
class MidasMCT extends MeshOrigin {
    constructor(data) {
        super(data);
        this.data = {
            source: {
                nodes: [],
                elements: [],
                sections: [
                    // {
                    //     OPOLY:[
                    //         [x,y,x,y],
                    //         [x,y,x,y],
                    //     ],
                    //     IPOLY:[
                    //         [x,y,x,y],
                    //         [x,y,x,y],
                    //     ]
                    // }
                ],
                type: "midas",
            }
        };
        this.defaultColorR = 0.5;
        this.defaultColorG = 0.5;
        this.defaultColorB = 0.5;
        this.init(data);
        return this;
    }
    log(msg) {

        if (typeof this.parent != "undefined") {
            this.parent.log(msg, this.parent);
        }
        else {
            console.log("MESH : ", msg);
        }
    }
    init(data) {
        let succeed = this.getFileMesh(data);



        this.succeed = succeed;
    }
    getFileMesh(text) {
        // this.parent.log("......数据优化;");
        if (text.data) {
            let msh_file_lines = text.data.split("\n");

            let nodes = this.data.source.nodes;
            let elements = this.data.source.elements;
            let sections = this.data.source.sections;


            let flageElements = false;
            let flageNodes = false;
            let flagSections = false;
            let flagSectionsSub = false;
            let OPOLY = false, IPOLY = false;
            let sectionIndex = 0;
            let lineEnterOf = false;

            for (let i in msh_file_lines) {
                let perLine = msh_file_lines[i].split("\r")[0];
                let perLineIsValues = perLine.split(/\s+/);

                if (perLineIsValues[0] == ";") {
                    continue;
                }
                let arrayOfBysemicolon = perLine.split(";")[0];

                if (perLine == "*NODE    ; Nodes" || perLine == "; iNO, X, Y, Z") {
                    flageNodes = true;
                    flageElements = false;
                    flagSections = false;
                    flagSectionsSub = false;
                    continue;
                }

                if (perLine == "*ELEMENT    ; Elements" || perLine == "; iNO, X, Y, Z") {
                    flageNodes = false;
                    flageElements = true;
                    flagSections = false;
                    flagSectionsSub = false;
                    continue;
                }

                if (arrayOfBysemicolon == "*SECT-PSCVALUE    ") {
                    flageNodes = false;
                    flageElements = false;
                    flagSections = true;
                    flagSectionsSub = false;
                    continue;
                }

                if (flageNodes) {
                    if (perLine == "") {
                        flageNodes = false;
                        flageElements = false;
                        flagSections = false;
                        flagSectionsSub = false;
                        continue;
                    }
                    perLine = perLine.replace(/\s+/g, "");
                    let perArray = perLine.split(",");
                    nodes[perArray[0]] = [parseFloat (perArray[1]), parseFloat(perArray[2]), parseFloat(perArray[3])];
                }
                else if (flageElements) {
                    if (perLine == "") {
                        flageNodes = false;
                        flageElements = false;
                        flagSections = false;
                        flagSectionsSub = false;
                        continue;
                    }
                    perLine = perLine.replace(/\s+/g, "");
                    let perArray = perLine.split(",");
                    elements[perArray[0]] =
                    {
                        type: perArray[1],
                        iMAT: perArray[2],
                        iPRO: perArray[3],
                        iNs: perArray[1] == "BEAM" ? [perArray[4], perArray[5]] : [],
                    };
                }
                else if (flagSections) {
                    if (perLine == "") {
                        flageNodes = false;
                        flageElements = false;
                        flagSections = false;
                        flagSectionsSub = false;
                        continue;
                    }
                    perLine = perLine.replace(/\s+/g, "");
                    let perArray = perLine.split(",");
                    if (perLine.indexOf("SECT=") != -1) {
                        let perArray = perLine.split(",")[0].split("SECT=");
                        flagSectionsSub = perArray[1];
                        OPOLY = false;
                        IPOLY = false;
                        continue;
                    }
                    if (flagSectionsSub) {
                        if (typeof sections[flagSectionsSub] == "undefined")
                            sections[flagSectionsSub] = {
                                OPOLY: [],
                                IPOLY: [],
                            };

                        if (perLine.indexOf("OPOLY=") != -1) {
                            if (perLine.indexOf("OPOLY=YES") != -1) {
                                let perArray1 = perLine.split("OPOLY=YES,")[1].split(",");
                                sectionIndex = 0;
                                sections[flagSectionsSub].OPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {
                                    sections[flagSectionsSub].OPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            } else if (perLine.indexOf("OPOLY=NO") != -1) {
                                let perArray1 = perLine.split("OPOLY=NO,")[1].split(",");
                                sectionIndex = 1;
                                sections[flagSectionsSub].OPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {
                                    sections[flagSectionsSub].OPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            }
                            else {
                                let perArray1 = perLine.split("OPOLY=")[1].split(",");
                                sectionIndex = 0;
                                sections[flagSectionsSub].OPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {//从1开始
                                    sections[flagSectionsSub].OPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            }
                            OPOLY = true;
                            IPOLY = false;
                            lineEnterOf = true;
                            continue;
                        }
                        else if (perLine.indexOf("IPOLY=") != -1) {
                            if (perLine.indexOf("IPOLY=YES") != -1) {
                                let perArray1 = perLine.split("IPOLY=YES,")[1].split(",");
                                sectionIndex = 0;
                                sections[flagSectionsSub].IPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {
                                    sections[flagSectionsSub].IPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            }
                            else if (perLine.indexOf("IPOLY=NO") != -1) {
                                let perArray1 = perLine.split("IPOLY=NO,")[1].split(",");
                                sectionIndex = 1;
                                sections[flagSectionsSub].IPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {
                                    sections[flagSectionsSub].IPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            }
                            else {
                                let perArray1 = perLine.split("IPOLY=")[1].split(",");
                                sectionIndex = 0;
                                sections[flagSectionsSub].IPOLY[sectionIndex] = [];
                                for (let pushi = 0; pushi < perArray1.length ; pushi++) {
                                    sections[flagSectionsSub].IPOLY[sectionIndex].push(parseFloat(perArray1[pushi]));
                                }
                            }
                            OPOLY = false;
                            IPOLY = true;
                            lineEnterOf = true;
                            continue;
                        }

                        if (lineEnterOf) {
                            //从0开始
                            if (OPOLY) {
                                for (let i of perArray) {
                                    sections[flagSectionsSub].OPOLY[sectionIndex].push(parseFloat(i));
                                }
                            }
                            if (IPOLY) {
                                for (let i of perArray) {
                                    sections[flagSectionsSub].IPOLY[sectionIndex].push(parseFloat(i));
                                }
                            }

                        }
                    }

                }

            }//end of for
            return true;
        }//end of if text
        return false;
    }

}
export { MidasMCT };