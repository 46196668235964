import {
    BufferGeometry,
    Mesh,
    Vector3,
    Object3D,
    Box3,
    // Color,
    // MeshStandardMaterial,
    MeshBasicMaterial,
    // LineBasicMaterial,
    // MeshPhongMaterial,
    // Line,
    // Line3,
    // Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    // Ray,
    Float32BufferAttribute,
    // LineLoop,
    // FileLoader,
    // ObjectLoader,
    Matrix4,
    // EdgesGeometry, Box3,
    // ArrowHelper,
    Points,
    SphereGeometry,
    BoxGeometry
} from "three";
import { DefineMaterial } from "./material";// no worker 
import { Clipping } from "./clipping";// worker ready
import { rayPickup } from "./raypickup";//worker ready 
class Draw {

    constructor(parent, callback = false) {
        this.data = {
            meshTree: new Group(),
            extra: {
                str: false,
                midas: false,
                points: false,
            },
            pickup: {
                midas: []
            },

        };
        this.data.meshTree.name = "meshTree";
        this.callback = callback;
        if (typeof parent.parent.LUT != "undefined") {
            this.LUT = parent.parent.LUT;
        }
        else {
            this.LUT = false;
        }
        this.rayPickup = new rayPickup();
        this.setting = {
            status: "CM",//default=”CM“，other :str/midas ...
            numberOfstatus: 1,// 类似上一行，判断extra的云图
            bestTransRate: 1,
            DefaultTransSite: false,
            enableOriginFrameline: false,//when trans rate applate ,the origin frame lins enable or disable
            enableFrameline: true,  //show or hide FrameLine 
            enabletrans: false,     //transform of site of CM  visible
            transDir: "all",//all ,u,v,w
            transRate: 1,           // rate of transform of site of CM
            // siteOfCM: [],           // xxx
            globalSiteEnable: false,    // enanble or disable the global site of mulit site
            multiObjectOfCM: false,//xxx,multi cm ,not here ,maybe in threeApp CMS-[]
            currentCMLevel: 1,  //default =1 ,one site cm ,if there have multi site of CM ,the value will be change by functon

            currentCMSite: false,   //index of site cms ,default =false ,the main is that here is not multi site 
            showOriginFrame: false,//show origin frame line when transfrom done
            // globalMaxMinOfCM: true,
            siteTimeList: [],//

            strRate: 2,
            rateOfStr: 1,
            pointRadius: 1,
            pointFlag: false,
            pointColor: 0xffffff,
            pointIDS: [],

            shellPoints: false,

        };
        this.timer = {
            visible: false,
            timer: false,
            timerIndex: 0,//for timer ++
        };
        // this.memeryDB = {};
        this.parent = parent;
        this.scene = parent.parent.worldBox;
        // this.render = parent.parent.render;
        this.callWorker = {};
        this.material = new DefineMaterial();
        this.init(callback);
        return this;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of data
    render(that) {
        this.parent.parent.render(that);
    }
    init(callback) {

        if (callback) {
            for (let i in callback) {
                this.callWorker[i] = callback[i];
            }
        }
    }
    log(msg) {
        if (typeof this.callWorker["log"] != "undefined") {
            this.callWorker.log(msg, this.parent);
        }
        else {
            console.log("message form draw :", msg);
        }
    }

    destroy() { }
    updateData(data) {
        this.data.drawTree = data.data;
        if (typeof this.data.namesOfCM == "undefined")
            this.data.namesOfCM = data.namesOfCM;
        else {

            for (let i of data.namesOfCM) {
                let alreadyHas = false;
                for (let j of this.data.namesOfCM) {
                    if (i == j) {
                        alreadyHas = true;
                    }
                }
                if (alreadyHas === false) {
                    this.data.namesOfCM.push(i);
                }
            }
        }
    }
    getNameListOfCM() {
        return this.data.namesOfCM;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of draw str
    getMeshTreeByName(name) {
        let obj;
        this.data.meshTree.traverse(function (child) {
            if (child.name == name)
                obj = child;
        });
        return obj || false;
    }
    setStrRate(value = 1) {
        this.setting.strRate = value;
    }
    //增加比率调节,20221116
    setRateOfStr(value = false) {
        if (value) {
            this.setting.rateOfStr = value;
        }
    }
    drawStr(that) {
        // 清空children
        let strOBJ = this.data.extra.str;
        strOBJ.traverse(function (child) {
            strOBJ.remove(child);
        });
        //// 获取str的MM，取中间值，
        let yOfMzMy = false;
        let MM = this.parent.info.getMMOfStrALL(this.setting.currentCMSite);
        // let MxMM = { max: MM.max, min: MM.min };
        // if (this.setting.currentCMSite == "str.Mx" || this.setting.currentCMSite == "str.mx") {
        //     let max = MM.max;
        //     let min = MM.min;

        //     MM.max = max - min;
        //     MM.min = min - min;
        // }
        if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
            let max = MM.max;
            let min = MM.min;
            MM.max = -min;
            MM.min = -max;
        }
        if (this.setting.currentCMSite == "str.Fy" || this.setting.currentCMSite == "str.fy" || this.setting.currentCMSite == "str.mz" || this.setting.currentCMSite == "str.Mz") {
            yOfMzMy = true;
        }
        let MMM = (MM.max + MM.min) / 2;

        let MMR = (MM.max - MM.min);

        let bestRate = this.parent.info.getBestTransRateOfStr(this.setting.currentCMSite);

        let rate = this.setting.rateOfStr;//增加比率调节，20221116
        let bestValueOfRate = this.parent.info.getBestTransRateValueOfStr(this.setting.currentCMSite) * this.setting.strRate * rate;
        //// 
        // str instance循环
        let instancesOfStr = this.parent.info.getInstanceOfStr();
        for (let strName of instancesOfStr) {

            let perInstance = this.data.drawTree[strName];
            if (typeof perInstance != "undefined") {
                let realPositions = this.getMeshTreeByName(strName).children[0].geometry.attributes.position.array;//临时，children可能有多个child
                let realPressures = this.data.drawTree[strName].lines.pressures;//临时，children可能有多个child
                let originPositions = this.data.drawTree[strName].lines.xyz;//临时，children可能有多个child
                let elements = perInstance.elementsIndex;
                let uvs = [], normals = [], cms = [], positions = [];

                for (let ei in elements) {
                    // let perElement = elements[ei];
                    let positionI = ei * 6;
                    let pressureI = ei * 2;
                    // let perRealPositions = realPositions[posiitonI];
                    // let perPressure = realPressures[pressureI];
                    // let perOriginPositions = originPositions[positionI];
                    ////
                    // elements for 
                    //  triangles=[];
                    let dirX = originPositions[positionI + 0] - originPositions[positionI + 3];
                    let dirY = originPositions[positionI + 1] - originPositions[positionI + 4];
                    let dirZ = originPositions[positionI + 2] - originPositions[positionI + 5];

                    let MMA = - realPressures[pressureI + 0];
                    let MMB = - realPressures[pressureI + 1];

                    // let flageMMA = MMA > MMM ? true : false;
                    // let flageMMB = MMB > MMM ? true : false;

                    let x1 = 0., y1 = 0., z1 = 0., x2 = 0., y2 = 0., z2 = 0.;
                    let x3 = 0., y3 = 0., z3 = 0., x4 = 0., y4 = 0., z4 = 0.;

                    // let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);

                    // let distanceMMA = (((MMA - MM.min) / MMR) * 2 - 1) * bestValueOfRate;
                    // let distanceMMB = (((MMB - MM.min) / MMR) * 2 - 1) * bestValueOfRate;
                    let distanceMMA = 0;
                    let distanceMMB = 0;
                    // if (this.setting.currentCMSite == "str.Mx" || this.setting.currentCMSite == "str.mx") {
                    //     distanceMMA = ((MMA-MxMM.min) / MMR) * bestValueOfRate;
                    // }
                    // else 
                    {
                        distanceMMA = (MMA / MMR) * bestValueOfRate;
                    }


                    // if (this.setting.currentCMSite == "str.Mx" || this.setting.currentCMSite == "str.mx") {
                    //     distanceMMB = ((MMB-MxMM.min) / MMR) * bestValueOfRate;
                    // }
                    // else
                    {
                        distanceMMB = (MMB / MMR) * bestValueOfRate;
                    }



                    // if (distanceMMA > bestValueOfRate || distanceMMB > bestValueOfRate) {
                    //     console.log(ei, distanceMMA, distanceMMB, bestValueOfRate);
                    // }
                    if (dirX == 0 && dirZ != 0) {//Z轴,X轴画图形
                        if (yOfMzMy) {
                            x1 = realPositions[positionI + 0];
                            y1 = realPositions[positionI + 1];
                            z1 = realPositions[positionI + 2];

                            x3 = realPositions[positionI + 0];
                            y3 = realPositions[positionI + 1] - distanceMMA;
                            z3 = realPositions[positionI + 2];

                            x2 = realPositions[positionI + 3];
                            y2 = realPositions[positionI + 4];
                            z2 = realPositions[positionI + 5];

                            x4 = realPositions[positionI + 3];
                            y4 = realPositions[positionI + 4] - distanceMMB;
                            z4 = realPositions[positionI + 5];
                        }
                        else {

                            x1 = realPositions[positionI + 0];
                            y1 = realPositions[positionI + 1];
                            z1 = realPositions[positionI + 2];

                            x3 = realPositions[positionI + 0] + distanceMMA;
                            y3 = realPositions[positionI + 1];
                            z3 = realPositions[positionI + 2];

                            x2 = realPositions[positionI + 3];
                            y2 = realPositions[positionI + 4];
                            z2 = realPositions[positionI + 5];

                            x4 = realPositions[positionI + 3] + distanceMMB;
                            y4 = realPositions[positionI + 4];
                            z4 = realPositions[positionI + 5];
                        }
                    }
                    // else if (dirZ == 0 && dirY == 0) {//X轴，Z轴画图形
                    else if (dirX != 0 && dirZ == 0) {//X轴，Z轴画图形
                        if (yOfMzMy) {
                            x1 = realPositions[positionI + 0];
                            y1 = realPositions[positionI + 1];
                            z1 = realPositions[positionI + 2];

                            x3 = realPositions[positionI + 0];
                            y3 = realPositions[positionI + 1] - distanceMMA;
                            z3 = realPositions[positionI + 2];

                            x2 = realPositions[positionI + 3];
                            y2 = realPositions[positionI + 4];
                            z2 = realPositions[positionI + 5];

                            x4 = realPositions[positionI + 3];
                            y4 = realPositions[positionI + 4] - distanceMMB;
                            z4 = realPositions[positionI + 5];
                        }
                        else {

                            x1 = realPositions[positionI + 0];
                            y1 = realPositions[positionI + 1];
                            z1 = realPositions[positionI + 2];

                            x3 = realPositions[positionI + 0];
                            y3 = realPositions[positionI + 1];
                            z3 = realPositions[positionI + 2] + distanceMMA;

                            x2 = realPositions[positionI + 3];
                            y2 = realPositions[positionI + 4];
                            z2 = realPositions[positionI + 5];

                            x4 = realPositions[positionI + 3];
                            y4 = realPositions[positionI + 4];
                            z4 = realPositions[positionI + 5] + distanceMMB;
                        }
                    }
                    else {//Y轴,Z轴画图形


                        x1 = realPositions[positionI + 0];
                        y1 = realPositions[positionI + 1];
                        z1 = realPositions[positionI + 2];

                        x3 = realPositions[positionI + 0];
                        y3 = realPositions[positionI + 1];
                        z3 = realPositions[positionI + 2] + distanceMMA;

                        x2 = realPositions[positionI + 3];
                        y2 = realPositions[positionI + 4];
                        z2 = realPositions[positionI + 5];

                        x4 = realPositions[positionI + 3];
                        y4 = realPositions[positionI + 4];
                        z4 = realPositions[positionI + 5] + distanceMMB;

                    }
                    //// 
                    //获取value 和dir
                    // 默认：Z轴向上情况
                    //  计算 X：两点X不同，Y=y，Z=z,    判断方向（点的），      dir=vec3(1,0,0) （-1，0，0）
                    //   如果：X相同，y=y,z=z ,         判断方向（点的），      dir =vec3(0,0,1) （0，0，-1）

                    ////
                    // 获取坐标
                    // a=第一点 ,b=第二点
                    // a(xyz),b(xyz)以position的
                    //  计算c点，a点的dir上的距离，距离=a的value*best
                    //  计算d点，b点的dir上的距离，距离=b的value*best

                    // push 画三角形（abc，bdc),
                    positions.push(x1, y1, z1, x2, y2, z2, x3, y3, z3);
                    positions.push(x2, y2, z2, x4, y4, z4, x3, y3, z3);
                    uvs.push(0, 0, 1, 0, 1, 1);
                    uvs.push(0, 0, 1, 0, 1, 1);
                    // push cm ，a=c(aba),b=d(bba)
                    if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
                        cms.push((-realPressures[pressureI + 0] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((-realPressures[pressureI + 0] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((-realPressures[pressureI + 0] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);

                        cms.push((-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 1] - MM.min) / MMR, (-realPressures[pressureI + 0] - MM.min) / MMR);
                    }
                    else {
                        cms.push((realPressures[pressureI + 0] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((realPressures[pressureI + 0] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((realPressures[pressureI + 0] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);

                        cms.push((realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);
                        cms.push((realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 1] - MM.min) / MMR, (realPressures[pressureI + 0] - MM.min) / MMR);
                    }
                    // cms.push(realPressures[perInstance + 1], realPressures[perInstance + 1], realPressures[perInstance + 0]);
                }



                // add to group
                let geometry = new BufferGeometry();
                geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));
                // geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
                geometry.setAttribute("cm", new Float32BufferAttribute(cms, 3));
                geometry.setAttribute("uv", new Float32BufferAttribute(new Float32Array(uvs), 2));
                // geometry.setAttribute("normal", new Float32BufferAttribute(new Float32Array(normals), 3));

                let mesh = new Mesh(geometry, this.material.TriangleShaderStair());
                strOBJ.add(mesh);
            }
        }
        strOBJ.visible = true;

    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of draw
    draw(data, trans = false) {
        this.updateData(data);

        this.scene.add(this.data.meshTree);
        for (let meshName in data.data) {
            if (meshName == "Dimension_2OR3") {
                continue;
            }
            let perMesh = data.data[meshName];
            let newGroup = new Group();
            newGroup.name = meshName;
            this.data.meshTree.add(newGroup);
            if (typeof perMesh.type != "undefined" && perMesh.type == "PLTFR") {
                let matrix = [];
                for (let i in perMesh) {
                    let perPLTFR = perMesh[i];
                    let mesh = false;
                    if (perMesh.matrix !== false && matrix.length == 0) {
                        matrix = this.defineMatrix(perMesh.matrix);
                        perMesh.matrix4 = matrix;
                    }
                    if (i == "triangles") {
                        mesh = this.drawTriangle(perPLTFR, this.material.TriangleShaderStair(), trans);
                    }
                    else if (i == "points") {
                        mesh = this.drawPoints(perPLTFR, this.material.Point(), trans);
                    }
                    else if (i == "lines") {
                        mesh = this.drawLines(perPLTFR, this.material.Line(), trans);
                    }
                    else if (i == "frameLines") {
                        mesh = this.drawFrameLines(perPLTFR, this.material.FameLine(), trans);
                    }
                    if (mesh) {
                        newGroup.add(mesh);
                        mesh.name = i;
                        if (typeof matrix.elements != "undefined") {
                            mesh.applyMatrix4(matrix);
                        }
                    }
                }
            }
        }
        if (this.parent.setting.center) {
            this.center();
        }
        this.afterDraw();
        if (this.parent.info.getIsStr()) {
            this.data.extra.str = new Group();
            this.data.extra.str.name = "str";
            this.data.extra.str.visible = false;
            this.data.meshTree.add(this.data.extra.str);
            this.setting.numberOfstatus++;
        }
        if (this.parent.info.getIsMidas()) {
            this.data.extra.midas = new Group();
            this.data.extra.midas.name = "midas";
            this.data.extra.midas.visible = false;
            this.data.meshTree.add(this.data.extra.midas);
            this.setting.numberOfstatus++;
        }
    }
    afterDraw() {
        this.callback.afterDraw(this.parent, "draw");
    }
    defineMatrix(data) {
        let Trans = data.inp.transform;
        let Rotate = data.inp.rotate;
        let xyz1 = data.inp.xyz1;
        let xyz2 = data.inp.xyz2;
        let object = new Object3D();
        const position_obj = new Vector3(0, 0, 0);
        let A1 = [position_obj.x, position_obj.y, position_obj.z];

        let B_axia, axis;
        if (Rotate) {
            B_axia = [xyz2[0] - xyz1[0], xyz2[1] - xyz1[1], xyz2[2] - xyz1[2]];
            axis = new Vector3(B_axia[0], B_axia[1], B_axia[2]);
            axis.normalize();
        }

        if (Trans) {
            if (xyz1) {
                if (Trans[0] == xyz1[0] && Trans[1] == xyz1[1] && Trans[2] == xyz1[2]) {
                    //console.log("世界坐标==与形心坐标")
                    A1 = false;
                } else {
                    const temp_a1 = this.xyz_decrease_xyz(A1, xyz2);
                    //console.log("世界坐标！=与形心坐标")
                    let A1_temp = new Matrix4();
                    A1_temp.makeTranslation(temp_a1[0], temp_a1[1], temp_a1[2]);
                    A1_temp.multiply(Object.matrix);
                    object.applyMatrix4(A1_temp);
                }
            }
        }
        if (Rotate) {
            //console.log("旋转:", Rotate);
            object.rotateOnWorldAxis(axis, Rotate * Math.PI / 180.0);//世界坐标 等价与 上面五行
        }
        if (Trans) {
            object.position.set(Trans[0], Trans[1], Trans[2]);//需要确定，是相对位移，还是绝对位移
        }
        object.updateMatrix();
        let matrix = JSON.parse(JSON.stringify(object.matrix));
        return matrix;
    }
    drawPoints(data, material) {
        let geometry = new BufferGeometry();
        let vertices = new Float32Array(data.xyz);
        if (vertices.length == 0) {
            return false;
        }
        // let colors = new Float32Array(data.color);
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        // geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));
        let mesh = new Points(geometry, material);
        return mesh;
    }
    drawLines(data, material) {
        let geometry = new BufferGeometry();
        let vertices = new Float32Array(data.xyz);
        let colors = new Float32Array(data.color);
        if (vertices.length == 0) {
            return false;
        }
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));
        // child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 2));
        let mesh = new LineSegments(geometry, material);
        return mesh;
    }
    drawTriangle(data, material) {
        let geometry = new BufferGeometry();
        let vertices = new Float32Array(data.xyz);
        if (vertices.length == 0) {
            return false;
        }
        // let colors = new Float32Array(colors);
        let cm = new Float32Array(data.cm);
        let uv = new Float32Array(data.uv);
        let normal = new Float32Array(data.normal);
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        // geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
        geometry.setAttribute("cm", new Float32BufferAttribute(cm, 3));
        geometry.setAttribute("uv", new Float32BufferAttribute(new Float32Array(uv), 2));
        geometry.setAttribute("normal", new Float32BufferAttribute(new Float32Array(normal), 3));
        if (data.indices)
            geometry.setIndex(data.indices);
        let mesh = new Mesh(geometry, material);
        return mesh;
    }
    drawFrameLines(data, material) {
        let geometry = new BufferGeometry();
        let vertices = new Float32Array(data.xyz);
        if (vertices.length == 0) {
            return false;
        }
        // let colors = new Float32Array(data.color);
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        // geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));
        let mesh = new LineSegments(geometry, material);
        return mesh;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of ani 
    //upgrde for all point line triangle and frame line
    center() {
        var box3 = new Box3();
        // 计算层级模型group的包围盒
        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
        box3.expandByObject(this.data.meshTree);
        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
        var center = new Vector3();
        box3.getCenter(center);
        // console.log('查看几何体中心坐标', center);

        // 重新设置模型的位置，使之居中。
        this.data.meshTree.position.x = this.data.meshTree.position.x - center.x;
        this.data.meshTree.position.y = this.data.meshTree.position.y - center.y;
        this.data.meshTree.position.z = this.data.meshTree.position.z - center.z;
    }
    show() {
        this.doTrans(this);
        if (this.setting.currentCMSite)
            this.updateColors(this.setting.currentCMSite);

        //str
        if (this.setting.status == "str") {
            this.drawStr(this);
        }
        else {
            if (this.data.extra.str) {
                this.data.extra.str.visible = false;
            }
        }

        //midas
        if (this.setting.status == "midas") {
            this.drawMidas(this);
        }
        else {
            if (this.data.extra.midas) {
                this.data.extra.midas.visible = false;
            }
        }

        //info points 
        if (this.setting.pointFlag) {
            this.drawInfoPoints(this.setting.pointIDS);
        }
        else {
            this.cleanInfoPoints();
        }

        //有shellpoint
        if (this.setting.shellPoints) {
            this.drawShellPointsMerge(this.setting.shellPoints.pointsArray, this.setting.shellPoints.color, this.setting.shellPoints.radius);
        }

        // this.render();
        this.render();
        // this.setting.pointRadius = this.parent.info.getNodeMaxSize() * .01;

    }
    //frame line disable or enanble 
    showFrameLines(value = true, objectName = false) {
        if (objectName) {
            for (let i in this.data.meshTree) {
                if (i == objectName) {
                    let perGroup = this.data.meshTree[i].group;
                    let frameLinesObject = perGroup.getObjectByName("frameLines");
                    frameLinesObject.visible = value;
                    return true;
                }
            }
        }
        else
            for (let i in this.data.meshTree.children) {
                let perOne = this.data.meshTree.children[i];
                for (let j in perOne.children) {
                    if (perOne.children[j].name == "frameLines")
                        perOne.children[j].visible = value;
                }
            }
    }
    //clean all ( point line triangle and frame line) from the worldBox
    clean() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.scene.remove(this.data.meshTree);
        // let allInstance = this.data.meshTree;
        // for (let instanceName in allInstance) {
        //     //get the name of group of CM 
        //     let instance = allInstance[instanceName].group;
        //     if (this.scene) {
        //         this.scene.remove(instance);
        //     }
        // }
        this.data.meshTree = new Group();
    }
    getResTimeList() {
        return this.parent.getResTimeList();
    }
    // play ani by second 
    play(s = 0.5) {
        if (this.timer.timer === false) {
            if (this.parent.getGlobalCMMMVisable()) {
                let that = this;
                let siteOfCM = this.setting.currentCMSite;
                let timerIndexList = that.getResTimeList();
                this.timer.timer = setInterval(function () {

                    if (that.setting.enabletrans) {
                        that.doTrans(that);
                    }
                    that.updateColors(siteOfCM);
                    if (that.parent.parent.needsUpdate === false)
                        that.render(that.parent.parent);
                    // if (that.timer.visible == false) {
                    //     clearInterval(that.timer.timer);
                    // }
                    if (that.timer.timerIndex >= timerIndexList.length - 1) {
                        that.timer.timerIndex = 0;
                        that.setting.currentCMLevel = timerIndexList[that.timer.timerIndex];
                    }
                    else {
                        that.timer.timerIndex++;
                        that.setting.currentCMLevel = timerIndexList[that.timer.timerIndex];
                    }
                }, s * 1000);
            }
        }
    }
    //stop ani 
    stop() {
        clearInterval(this.timer.timer);
        this.timer.timer = false;
    }

    //set one value for transfrom rate 
    setTransRate(value = false) {
        if (value === false) {
            value = this.parent.getBestTransRate();
            this.setting.bestTransRate = value;
        }
        this.setting.transRate = value;
    }
    getTransRate() {
        return this.setting.transRate;
    }
    getTransEnable() {
        return this.setting.enabletrans;
    }
    setTransEnable(value = false) {
        this.setting.enabletrans = value;
        if (this.setting.transRate == 1) this.setTransRate();
    }

    doTrans(that = false) {
        if (that === false) {
            that = this;
        }
        if (that.setting.DefaultTransSite == false) {
            that.setting.DefaultTransSite = that.parent.getDefaultTrans();
        }

        if (that.setting.DefaultTransSite[1].length !== 3)
            this.setting.enabletrans = false;
        let allInstance = this.data.meshTree.children;
        let allOriginInstance = this.data.drawTree;
        let enableTrane = this.setting.enabletrans;



        let transRate = this.setting.transRate || 1.0;
        for (let i in allInstance) {
            //get the name of group of CM 
            let instance = allInstance[i];
            let instanceName = instance.name;
            let dataOrigin = allOriginInstance[instanceName];
            for (let child_i in instance.children) {
                let child = instance.children[child_i];
                let childName = child.name;// get the child object name :
                let visible = child.visible;
                //check visible 
                if (visible === true) {
                    let position = [];
                    if (childName == "triangles") {
                        position = this.doTransTriangles(dataOrigin.triangles, that.setting.DefaultTransSite[1], enableTrane, transRate, instanceName);
                    }
                    else if (childName == "lines") {
                        position = this.doTransLines(dataOrigin.lines, that.setting.DefaultTransSite[1], enableTrane, transRate, instanceName);
                    }
                    else if (childName == "points") {
                        position = this.doTransPoints(dataOrigin.points, that.setting.DefaultTransSite[1], enableTrane, transRate, instanceName);
                    }
                    else if (childName == "frameLines") {
                        position = this.doTransFrame(dataOrigin.frameLines, that.setting.DefaultTransSite[1], enableTrane, transRate, instanceName);
                    }
                    if (position.length > 0) {
                        child.geometry.setAttribute("position", new BufferAttribute(new Float32Array(position), 3));
                    }
                }
            }
        }

    }
    updatePointsPositionForTrans(instanceName, points) {
        if (this.setting.DefaultTransSite == false) {
            this.setting.DefaultTransSite = this.parent.getDefaultTrans();
        }
        if (this.setting.DefaultTransSite[1].length !== 3)
            this.setting.enabletrans = false;
        let enableTrane = this.setting.enabletrans;
        if (enableTrane) {
            let transRate = this.setting.transRate;

            let site = this.setting.DefaultTransSite[1];
            for (let i in points) {
                let perPoint = points[i];
                let x = perPoint[0], y = perPoint[1], z = perPoint[2];
                if (enableTrane) {
                    let uvw = this.getOnePointUVW(instanceName, this.setting.currentCMLevel, site, i);
                    if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                        if (this.setting.currentCMSite) {
                            let dir = this.setting.currentCMSite.split(".")[1];
                            if (typeof dir != "undefined") {
                                if (dir == "u") {
                                    x += uvw[0] * transRate;
                                }
                                else if (dir == "v") {
                                    y += uvw[1] * transRate;
                                }
                                else if (dir == "w") {
                                    if (site.length == 3) z += uvw[2] * transRate;
                                }
                            }
                        }
                    }
                    else {
                        x += uvw[0] * transRate;
                        y += uvw[1] * transRate;
                        if (site.length == 3) z += uvw[2] * transRate;
                    }
                    // x += uvw[0] * transRate;
                    // y += uvw[1] * transRate;
                    // z += uvw[2] * transRate;
                }
                points[i] = [x, y, z];
            }
        }
        return points;
    }
    getOnePointUVW(instanceName, level, site, index) {
        return this.parent.getOnePointUVW(instanceName, level, site, index);
    }
    setTransformationDir(value = "all") {
        if (value == "all" || value == "u" || value == "v" || value == "w")
            this.setting.transDir = value;
        else {
            this.log({
                type: "error",
                subject: "设置变形方向错误,参数必须是(all,u,v,w)之一,目前值是" + value,
                sender: "CM.Draw.doTransFrame"
            });
        }
    }
    doTransTriangles(data, site, enableTrane, transRate, instanceName) {
        let posiiton = [];
        let xyz = data.xyz;
        let index = data.index;
        // let index = data.index;
        for (let i = 0; i < xyz.length - 1; i += 3) {
            let x = 0, y = 0, z = 0;
            if (enableTrane) {
                let no = index[i / 3];
                let uvw = this.getOnePointUVW(instanceName, this.setting.currentCMLevel, site, no);
                if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                    if (this.setting.currentCMSite) {
                        let dir = this.setting.currentCMSite.split(".")[1];
                        if (typeof dir != "undefined") {
                            if (dir == "u") {
                                if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                                    x = uvw[0] * transRate;
                                }
                                else {
                                    x = 0;
                                }
                            }
                            else if (dir == "v") {

                                if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                                    y = uvw[1] * transRate;
                                }
                                else {
                                    y = 0;
                                }
                            }
                            else if (dir == "w") {

                                if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                                    if (site.length == 3) z = uvw[2] * transRate;
                                }
                                else {
                                    z = 0;
                                }
                            }
                        }
                    }
                }
                else {
                    // x = uvw[0] * transRate;
                    // y = uvw[1] * transRate;
                    // if (site.length == 3) z = uvw[2] * transRate;

                    if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                        x = uvw[0] * transRate;
                    }
                    else {
                        x = 0;
                    }


                    if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                        y = uvw[1] * transRate;
                    }
                    else {
                        y = 0;
                    }


                    if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                        if (site.length == 3) z = uvw[2] * transRate;
                    }
                    else {
                        z = 0;
                    }

                }
            }
            posiiton.push(xyz[i + 0] + x, xyz[i + 1] + y, xyz[i + 2] + z);
        }
        return posiiton;
    }
    doTransLines(data, site, enableTrane, transRate, instanceName) {
        return this.doTransTriangles(data, site, enableTrane, transRate, instanceName);
    }
    doTransPoints(data, site, enableTrane, transRate, instanceName) {
        return this.doTransTriangles(data, site, enableTrane, transRate, instanceName);
    }
    doTransFrame(data, site, enableTrane, transRate, instanceName) {
        return this.doTransTriangles(data, site, enableTrane, transRate, instanceName);
    }
    // doTransFrame(data, site, enableTrane, transRate, instanceName) {
    //     if (enableTrane === false) {
    //         return data.xyz;
    //     }
    //     let posiiton = [];
    //     let xyz = data.xyz;
    //     let index = data.index;

    //     let res = this.parent.getRES();
    //     if (res === false) {
    //         this.log({
    //             type: "error",
    //             subject: "获取RES失败",
    //             sender: "CM.Draw.doTransFrame"
    //         });
    //         return [];
    //     }
    //     let site1 = site[0].split(".");
    //     let site2 = site[1].split(".");
    //     let site3 = false;
    //     if (site.length == 3) {
    //         site3 = site[2].split(".");
    //     }

    //     let ii = 0;
    //     for (let i of index) {
    //         let x = 0, y = 0, z = 0;
    //         if (enableTrane) {
    //             if (res.type == "dat") {
    //                 x = res.res[this.setting.currentCMLevel][site[0]][instanceName][i] * transRate;
    //                 y = res.res[this.setting.currentCMLevel][site[1]][instanceName][i] * transRate;
    //                 if (site.length == 3) {
    //                     z = res.res[this.setting.currentCMLevel][site[2]][instanceName][i] * transRate;
    //                 }
    //             }
    //             else if (res.type == "res") {
    //                 x = res.res[this.setting.currentCMLevel][site1[0]][site1[1]][i] * transRate;
    //                 y = res.res[this.setting.currentCMLevel][site2[0]][site2[1]][i] * transRate;
    //                 if (site.length == 3) {
    //                     z = res.res[this.setting.currentCMLevel][site3[0]][site3[1]][i] * transRate;
    //                 }
    //             }
    //         }
    //         posiiton.push(xyz[ii++] + x, xyz[ii++] + y, xyz[ii++] + z);
    //     }
    //     return posiiton;
    // }

    setOriginFramelinesEnable(value = true) {
        this.setting.enableOriginFrameline = value;
        this.showOriginFramelines();
    }
    showOriginFramelines() {
        let allInstance = this.data.meshTree;
        if (this.setting.enableOriginFrameline) {

            if (typeof allInstance["originFrameLines"] != "undefined") {

                allInstance["originFrameLines"].group.visible = true;
            }
            else {
                allInstance["originFrameLines"] = {};
                allInstance["originFrameLines"].group = new Group();
                this.scene.add(allInstance["originFrameLines"].group);
                let instance = allInstance["originFrameLines"].group;
                //



            }
        }
        else {
            if (typeof allInstance["originFrameLines"] != "undefined") {
                allInstance["originFrameLines"].group.visible = false;
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Part of color
    getMaxMinOfCM(nameOfSiteCM) {
        // if (typeof this.setting.MM == "undefined" || this.setting.MM === false) 
        {
            this.setting.MM = this.parent.getMaxMinOfCM(nameOfSiteCM, this.setting.globalSiteEnable, this.setting.currentCMLevel, this.setting.multiObjectOfCM);
            return this.setting.MM;
        }
        // else {
        //     return this.setting.MM;
        // }
    }
    cleanMaxMinxOfCM() {
        this.setting.MM = false;
    }
    setGlobalCMEnable(value = false) {
        this.setting.globalSiteEnable = value;
        if (value === false)
            this.setting.MM = false;
    }


    updateMaxMinOfCM(MM) {
        this.setting.MM = MM;
    }
    //update color for lines and points ,triangle don't need this function ,because update by updatePressures attribute of cm
    updateColorsOfTriangle(nameOfSiteCM, instanceName, child) {
        let MM = this.getMaxMinOfCM(nameOfSiteCM);
        let cm = [];
        let values = this.getSiteValuesOfTriangles(instanceName, nameOfSiteCM, this.data.drawTree[instanceName].triangles.index);
        for (let i = 0; i < values.length - 1;) {
            let perOne1 = values[i++];
            let perOne2 = values[i++];
            let perOne3 = values[i++];
            if (perOne1 == -9999.9999 && perOne2 == -9999.9999 && perOne3 == -9999.9999) {
                cm.push(-9., -9., -9., -9., -9., -9., -9., -9., -9.);
            }
            else {
                let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures3 = (perOne3 - MM["min"]) / (MM["max"] - MM["min"]);
                // if (isNaN(pressures1) || isNaN(pressures2) || isNaN(pressures3)) {
                //     debugger;
                // }
                cm.push(pressures1, pressures2, pressures3, pressures1, pressures2, pressures3, pressures1, pressures2, pressures3);
            }
        }
        child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 3));
    }
    getSiteValuesOfTriangles(instanceName, nameOfSiteCM, index) {
        // return this.data.drawTree[instanceName].triangles.pressures[this.setting.currentCMLevel][nameOfSiteCM];
        return this.parent.getSiteValuesOfTriangles(instanceName, nameOfSiteCM, this.setting.currentCMLevel);
    }
    //对应线的顶点着色
    // updateColorsOfLine(nameOfSiteCM, instanceName, child) {
    //     let values = this.getSiteValuesOfLines(instanceName, nameOfSiteCM, this.data.drawTree[instanceName].lines.index);
    //     child.geometry.setAttribute("pressure", new Float32BufferAttribute(new Float32Array(values), 3));
    // }  

    //对应于 线的shader
    updateColorsOfLine(nameOfSiteCM, instanceName, child) {
        let MM = this.getMaxMinOfCM(nameOfSiteCM);
        let cm = [];
        let values = this.getSiteValuesOfLines(instanceName, nameOfSiteCM, this.data.drawTree[instanceName].lines.index);
        for (let i = 0; i < values.length - 1;) {
            let perOne1 = values[i++];
            let perOne2 = values[i++];
            let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
            let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
            cm.push(pressures1, pressures2, pressures1, pressures2,);
        }
        child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 2));
    }

    getSiteValuesOfLines(instanceName, nameOfSiteCM, index) {
        // return this.data.drawTree[instanceName].lines.pressures[this.setting.currentCMLevel][nameOfSiteCM];
        return this.parent.getSiteValuesOfLines(instanceName, nameOfSiteCM, this.setting.currentCMLevel);
    }
    updateColorsOfPoint(nameOfSiteCM, instanceName, child) {
        let values = this.getSiteValuesOfPoints(instanceName, nameOfSiteCM, this.data.drawTree[instanceName].points.index);
        child.geometry.setAttribute("pressure", new Float32BufferAttribute(new Float32Array(values), 3));
    }
    getSiteValuesOfPoints(instanceName, nameOfSiteCM, index) {
        // return this.data.drawTree[instanceName].points.pressures[this.setting.currentCMLevel][nameOfSiteCM];
        return this.parent.getSiteValuesOfPoints(instanceName, nameOfSiteCM, this.setting.currentCMLevel);
    }
    //非shader 云图,point and old mode lines
    updateLutColor(child) {
        if (typeof this.parent.parent.LUT != "undefined") {
            this.LUT = this.parent.parent.LUT;
        }
        else {
            this.LUT = false;
        }
        if (this.LUT) {
            let lut = this.LUT.lut;
            let geometry = child.geometry;
            let pressures = geometry.attributes.pressure;
            let colors = geometry.attributes.color;
            if (pressures && pressures.count > 0) {
                for (let i = 0; i < pressures.array.length; i++) {
                    const colorValue = pressures.array[i];
                    const color = lut.getColor(colorValue);
                    if (color === "undefined") {
                        //////console.log('Unable to determine color for value:', colorValue);
                    } else {
                        colors.setXYZ(i, color.r, color.g, color.b);
                    }
                }
                colors.needsUpdate = true;
            }
        }
        else {
            this.log({
                type: "error",
                subject: "LUT is false ",
                sender: "CM.Draw.updateLutColor()"
            });
        }
    }
    //////////////////////////////////////
    //标准云图
    updateColorsOfCM(nameOfSiteCM, title = false, unit = false) {
        //forearch the CM object
        this.setting.currentCMSite = nameOfSiteCM;
        let allInstance = this.data.meshTree.children;
        for (let i in allInstance) {
            let instanceName = allInstance[i].name;
            //get the name of group of CM 
            let instance = allInstance[i].children;
            for (let child_i in instance) {
                let child = instance[child_i];
                let childName = child.name;// get the child object name :
                let visible = child.visible;
                //check visible 
                if (visible === false || childName == "frameLines") {
                    //  frame line ignore
                    continue;
                }
                else {
                    //check the kind of PLTFR  type ,and do different method
                    // triangle update cm
                    if (childName == "triangles") {
                        this.updateColorsOfTriangle(nameOfSiteCM, instanceName, child);
                        if (child.visible === false)
                            child.visible = true;
                    }
                    // line update color 
                    else if (childName == "lines") {
                        this.updateColorsOfLine(nameOfSiteCM, instanceName, child);
                        if (child.visible === false)//防止STR禁止 显示后的问题
                            child.visible = true;
                        // this.updateLutColor(child);
                    }
                    //  point  updat color
                    else if (childName == "points") {
                        this.updateColorsOfPoint(nameOfSiteCM, instanceName, child);
                        if (child.visible === false)
                            child.visible = true;
                        this.updateLutColor(child);
                    }
                }
            }
        }
        this.repairPoly();
        this.updateLutText(nameOfSiteCM, title, unit);
        this.render();
    }
    //////////////////////////////////////////////////////
    //str 云图
    updateColorsOfStr(nameOfSiteCM, title = false, unit = false) {
        //forearch the CM object

        this.setting.currentCMSite = "str." + nameOfSiteCM;
        let allInstance = this.data.meshTree.children;
        for (let i in allInstance) {
            let instanceName = allInstance[i].name;
            //get the name of group of CM 
            let instance = allInstance[i].children;
            for (let child_i in instance) {
                let child = instance[child_i];
                let childName = child.name;// get the child object name :
                let visible = child.visible;
                //check visible 
                if (visible === false || childName == "frameLines") {
                    //  frame line ignore
                    continue;
                }
                else {
                    //仅梁单元使用
                    if (childName == "lines") {
                        this.updateColorsOfStrOfLine(nameOfSiteCM, instanceName, child);
                    }
                }
            }
        }
        // this.repairPoly();
        this.updateLutTextOfStr(nameOfSiteCM, title, unit);
        this.render();
    }
    //获取序列化的linesegment的CM array
    getSiteValuesOfStrOfLines(instanceName, nameOfSiteCM, elementsIndex) {
        return this.parent.info.getSiteValuesOfStrOfLines(instanceName, nameOfSiteCM, elementsIndex);
    }
    //update  线的CM属性的shader
    updateColorsOfStrOfLine(nameOfSiteCM, instanceName, child) {

        let MM = this.parent.info.getMMOfStrALL(nameOfSiteCM);
        let cm = [];
        //这里需要element 的id
        let values = this.getSiteValuesOfStrOfLines(instanceName, nameOfSiteCM, this.data.drawTree[instanceName].elementsIndex);


        if (values === false) {//没有str，则不显示
            // child.visible = false;
            return;
        }
        this.data.drawTree[instanceName].lines.pressures = values;
        for (let i = 0; i < values.length - 1;) {
            let perOne1 = values[i++];
            let perOne2 = values[i++];
            let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
            let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
            cm.push(pressures1, pressures2, pressures1, pressures2);
        }

        console.log(values[579 * 2]);

        child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 2));
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update pressures for triangles,lines ,points ,they have diffent way (cm and pressure)

    /////////////////////////////////////////////////////
    //updateColors 总入口
    updateColors(nameOfSiteCM, title = false, unit = false) {
        let list = this.parent.getNameListOfCM();
        if (list.length == 0) {
            return;
        }

        let hasName = false;
        for (let i of list) {
            if (i == nameOfSiteCM) {
                hasName = true;
            }
        }

        // if (hasName === false) {
        //     return;
        // }
        if (this.setting.numberOfstatus > 1) {
            let typeOfSite = nameOfSiteCM.split(".");
            if (typeOfSite[0] == "str") {
                this.setting.status = "str";
                this.updateColorsOfStr(typeOfSite[1], title, unit);//更新 str云图入口
            }
            else if (typeOfSite[0] == "midas") {
                this.setting.status = "midas";
            }
            else {
                this.updateColorsOfCM(nameOfSiteCM, title, unit);
            }
        }
        else {
            this.updateColorsOfCM(nameOfSiteCM, title, unit);
        }

    }
    // CM lut
    updateLutText(nameOfSiteCM, title = false, unit = false) {
        if (typeof this.parent.parent.LUT != "undefined") {
            this.LUT = this.parent.parent.LUT;
        }
        else {
            this.LUT = false;
        }
        if (this.LUT) {
            this.LUT.setMaxMin(this.getMaxMinOfCM(nameOfSiteCM));
            this.LUT.update_text(title, unit);
            this.cleanMaxMinxOfCM();
        }
        else {
            this.log({
                type: "error",
                subject: "LUT is false ",
                sender: "CM.Draw.updateLutText()"
            });
        }
    }
    //str lut
    updateLutTextOfStr(nameOfSiteCM, title = false, unit = false) {
        if (typeof this.parent.parent.LUT != "undefined") {
            this.LUT = this.parent.parent.LUT;
        }
        else {
            this.LUT = false;
        }
        if (this.LUT) {
            this.LUT.setMaxMin(this.parent.info.getMMOfStrALL(nameOfSiteCM));
            this.LUT.update_text(title, unit);
            this.cleanMaxMinxOfCM();
        }
        else {
            this.log({
                type: "error",
                subject: "LUT is false ",
                sender: "CM.Draw.updateLutTextOfStr()"
            });
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ani level
    //get site of CM list
    getSiteTimeList() {
        if (this.setting.siteTimeList.length == 0) {
            this.setting.siteTimeList = this.parent.getSiteTimeList();
        }
        return this.setting.siteTimeList;
    }
    isItAni() {
        let list = this.getSiteTimeList();
        return list.length > 1 ? true : false;
    }
    // get current level of site 
    getCurrentLevel() {
        return this.setting.currentCMLevel;
    }
    getCurrentLevelByIndex() {
        let list = this.getSiteTimeList();
        let currentLevel = this.getCurrentLevel();
        for (let i in list) {
            if (list[i] == currentLevel) {
                return i;
            }
        }
        return false;
    }
    checkNoofLevel(no) {
        let drawTree = this.data.drawTree;
        for (let i in drawTree) {
            if (i != "Dimension_2OR3") {
                let perOne = drawTree[i];
                if (perOne.triangles) {
                    if (typeof perOne.triangles.pressures[no] != "undefined")
                        return true;
                }
                else if (perOne.lines) {
                    if (typeof perOne.lines.pressures[no] != "undefined")
                        return true;
                }
            }
        }
        return false;
    }
    setCurrentLevel(value) {
        if (value)
        // if (this.checkNoofLevel(value)) {

            this.setting.currentCMLevel = value;
        if (this.setting.currentCMSite)
            this.updateColors(this.setting.currentCMSite);
        // }
        // else {
        //     this.log({
        //         type: "error",
        //         subject: "value is illegal",
        //         sender: `CM.Draw.setCurrentLevel${value}`
        //     });
        // }
    }
    setCurrentLevelByIndex(value) {
        if (value) {
            let list = this.getSiteTimeList();
            if (parseInt(value) < list.length) {
                this.setCurrentLevel(list[parseInt(value)]);
            }
            else {
                this.log({
                    type: "error",
                    subject: "index out of range",
                    sender: `CM.Draw.setCurrentLevelByIndex${value}`
                });
            }
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // demension
    getDimension() {
        return this.data.drawTree.Dimension_2OR3;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //设置局部剪切平面
    //set local clippint 
    setLocalClipping(localPlane = false) { }
    //set locasl chilpping enable or disable 
    setEnableOfLocalClipping(value = true) { }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //设置global剪切平面
    //set local clippint 
    setGlobalClipping(localPlane = false) { }
    //set locasl chilpping enable or disable 
    setEnableOfGlobalClipping(value = true) { }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //调整材质后，受光验收异常，二次调整失效。
    recomputeVertexNormals() {
        // for(let i in this.geometry.part){
        //     let perGEO= this.geometry.part[i];
        //     perGEO.computeVertexNormals();
        // }
        this.mesh.inpGroup.traverse(function (child) {
            if (child.type == "mesh") {
                child.geometry.computeVertexNormals();
            }
        });
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // clipping 

    getCurrentCMSite() {
        return this.setting.currentCMSite;
    }
    //设置局部剪切平面
    setClipping(localPlane = false) {
        let allInstance = this.data.meshTree.children;
        this.setting.localPlane = localPlane;
        for (let i in allInstance) {
            let instance = allInstance[i].children;
            for (let child_i in instance) {
                let child = instance[child_i];
                let type = child.type;
                if (type == "Mesh ") {
                    if (localPlane)
                        child.material.clippingPlanes = [localPlane];
                    else
                        child.material.clippingPlanes = [];
                }
                else {
                    if (localPlane)
                        child.material.clippingPlanes = [localPlane];
                    else
                        child.material.clippingPlanes = [];
                }

            }
        }
    }

    repairPoly() {
        if (this.setting.localPlane) {
            if (typeof this.clipping == "undefined")
                this.clipping = new Clipping(this);
            else {
                this.clipping.repairPoly();
            }
            this.render();
        }
    }
    rotateX(angle) {
        this.data.meshTree.rotateX(angle * Math.PI / 180);
    }
    rotateY(angle) {
        this.data.meshTree.rotateY(angle * Math.PI / 180);
    }
    rotateZ(angle) {
        this.data.meshTree.rotateZ(angle * Math.PI) / 180;
    }
    //设置点球的半径
    setInfoPointRadius(R = false) {
        if (R) {
            this.setting.pointRadius = R;
        }
        else {
            this.setting.pointRadius = this.parent.info.getNodeMaxSize() * .051;
        }
    }
    setInfoPointCorle(color = 0xffffff) {
        this.setting.pointColor = color;

    }
    //清除点球
    cleanInfoPoints() {
        let points = this.data.extra.points;
        if (points) {
            points.traverse(function (child) {
                points.remove(child);
            });
        }
        this.setting.pointFlag = false;
        this.render();
    }
    // 
    setInfoPointIDS(ids) {
        this.setting.pointIDS = ids;
        this.drawInfoPoints(ids);
    }
    //画点球
    drawInfoPoints(ids) {
        let points = this.data.extra.points;
        if (points) {
            this.cleanInfoPoints();
        }
        else {
            points = new Group();
            this.data.extra.points = points;
            this.data.meshTree.add(points);
        }
        this.setting.pointFlag = true;
        let R = this.setting.pointRadius;
        let enableTrane = this.setting.enabletrans;
        let transRate = this.setting.transRate;
        if (this.setting.DefaultTransSite == false) {
            this.setting.DefaultTransSite = this.parent.getDefaultTrans();
        }
        for (let i of ids) {

            let xyz = this.parent.info.getOnePointXYZOfAll(i);
            let x = xyz[0], y = xyz[1], z = xyz[2];
            if (enableTrane) {

                let uvw = this.getOnePointUVW("", this.setting.currentCMLevel, this.setting.DefaultTransSite[1], i);
                // x = data.pressures[this.setting.currentCMLevel][site[0]][i / 3] * transRate;
                // y = data.pressures[this.setting.currentCMLevel][site[1]][i / 3] * transRate;
                // if (site.length == 3) {
                //     z = data.pressures[this.setting.currentCMLevel][site[2]][i / 3] * transRate;
                // }
                x += uvw[0] * transRate;
                y += uvw[1] * transRate;
                z += uvw[2] * transRate;



            }
            let geo = new SphereGeometry(R, 20, 20);
            let material = new MeshBasicMaterial({ color: this.setting.pointColor });//new THREE.MeshBasicMaterial( { color:0x2C590A, wireframe: false, opacity: 0.5 } );
            let onetPoint = new Mesh(geo, material);
            onetPoint.position.set(x, y, z);

            points.add(onetPoint);
        }

        this.render();
    }

    cleanShellPointsMerge() {
        if (this.data.extra.points) {
            this.data.meshTree.remove(this.data.extra.points);
            this.data.extra.points = false;
        }
    }
    //画许多点球
    drawShellPointsMerge(pointsArray = "all", color = 0xffffff, radius = false,) {
        let site = this.setting.DefaultTransSite[1];
        this.cleanShellPointsMerge();
        this.setting.shellPoints = {
            pointsArray: pointsArray,
            radius: radius,
            color: color
        };


        let percentOfHalfBoxForSphere = 0.003;
        let enableTrane = this.setting.enabletrans;
        let transRate = this.setting.transRate;
        if (this.setting.DefaultTransSite == false) {
            this.setting.DefaultTransSite = this.parent.getDefaultTrans();
        }


        let R = 2;
        let points = [];
        let data = this.data;
        // let matrix = [data.meshTree.matrix];
        let m4 = new Matrix4();
        const material = new MeshBasicMaterial({ color: color });
        let geometry = new BufferGeometry();
        let positions = [];
        let indexs = [];
        if (this.parent.sphereRadius) {
            R = this.parent.sphereRadius;
        }
        else if (radius === false) {
            R = this.parent.info.getNodeMaxSize() * percentOfHalfBoxForSphere;
        }
        else {
            R = radius;
        }



        if (pointsArray === "all" || pointsArray === false) {
            for (let i in data.drawTree) {
                if (i == "Dimension_2OR3") continue;
                let perMesh = data.drawTree[i];
                if (typeof perMesh.shellPoints.index == "undefined" || Object.keys(perMesh.shellPoints.index).length == 0)
                    continue;
                for (let j in perMesh.shellPoints.index) {
                    points[j] = perMesh.shellPoints.index[j];
                }
                //matrix 简单的连续了，没有分门别类的列车，每个instance可能会不同，暂时忽略，20200616
                // if (perMesh.shellPoints.matrix) {
                //     matrix.push(perMesh.shellPoints.matrix);
                // }
            }
        }
        else {
            for (let i in data.drawTree) {
                if (i == "Dimension_2OR3") continue;
                let perMesh = data.drawTree[i];
                if (typeof perMesh.shellPoints.index == "undefined" || Object.keys(perMesh.shellPoints.index).length == 0)
                    continue;
                for (let j in perMesh.shellPoints.index) {
                    for (let k of pointsArray) {
                        if (k == j)
                            points[j] = perMesh.shellPoints.index[j];
                    }
                }
                //matrix 简单的连续了，没有分门别类的列车，每个instance可能会不同，暂时忽略，20200616
                // if (perMesh.shellPoints.matrix) {
                //     matrix.push(perMesh.shellPoints.matrix);
                // }
            }
        }


        let inidex_i = 0;
        for (let i in points) {

            let perPoint = points[i];
            let x = perPoint[0], y = perPoint[1], z = perPoint[2];
            if (enableTrane) {
                let uvw = this.getOnePointUVW("", this.setting.currentCMLevel, this.setting.DefaultTransSite[1], i);
                // x += uvw[0] * transRate;
                // y += uvw[1] * transRate;
                // z += uvw[2] * transRate;
                if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                    if (this.setting.currentCMSite) {
                        let dir = this.setting.currentCMSite.split(".")[1];
                        if (typeof dir != "undefined") {
                            if (dir == "u") {
                                x += uvw[0] * transRate;
                            }
                            else if (dir == "v") {
                                y += uvw[1] * transRate;
                            }
                            else if (dir == "w") {
                                if (site.length == 3) z += uvw[2] * transRate;
                            }
                        }
                    }
                }
                else {
                    x += uvw[0] * transRate;
                    y += uvw[1] * transRate;
                    if (site.length == 3) z += uvw[2] * transRate;
                }
            }
            // let sphere = new SphereGeometry(R, this.parent.sphereSegment, this.parent.sphereSegment);
            let sphere = new BoxGeometry(R, R, R);
            m4.elements = [
                1, 0, 0, 0,
                0, 1, 0, 0,
                0, 0, 1, 0,
                x, y, z, 1];


            sphere.applyMatrix4(m4);

            // geometry.merge(sphere,m4);
            for (let pi of sphere.attributes.position.array) {
                positions.push(pi);
            }
            for (let ii of sphere.index.array) {
                indexs.push(ii + inidex_i * sphere.attributes.position.array.length / 3);
            }
            // console.log(sphere.index.array.length);
            // if (i == "1050") {
            //     console.log(i, sphere.attributes.position.array, sphere.index.array);
            // }
            inidex_i++;
        }

        geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));
        geometry.setIndex(indexs);
        let mesh = new Mesh(geometry, new MeshBasicMaterial({ color: color, side: DoubleSide }));
        mesh.name = "ShellPoints";
        this.data.extra.points = mesh;
        this.data.meshTree.add(mesh);


    }


    //
    getInstanceOfAll() {
        let list = [];
        let children = this.data.meshTree.children;
        for (let i of children) {
            if (i.name != "frame of clipping" && i.name != "triagnle of clipping")
                list.push(i.name);
        }
        return list;
    }
    hideAllInstance() {
        let children = this.data.meshTree.children;
        for (let i of children) {
            if (i.name != "frame of clipping" && i.name != "triagnle of clipping")
                i.visible = false;
        }
        this.render();
    }
    showAllInstance() {
        let children = this.data.meshTree.children;
        for (let i of children) {
            if (i.name != "frame of clipping" && i.name != "triagnle of clipping")
                i.visible = true;
        }
        this.render();

    }
    hideInstanceByName(name = false) {
        if (name) {
            let children = this.data.meshTree.children;
            for (let i of children) {
                if (i.name == name)
                    if (i.name != "frame of clipping" && i.name != "triagnle of clipping")
                        i.visible = false;
            }
            this.render();
        }
    }
    showInstanceByName(name = false) {
        if (name) {
            let children = this.data.meshTree.children;
            for (let i of children) {
                if (i.name == name)
                    if (i.name != "frame of clipping" && i.name != "triagnle of clipping")
                        i.visible = true;
            }
            this.render();
        }
    }
    updateColorByInstanceNameForOneValue(name, mm, oneValue, title = false, unit = false) {
        if (typeof mm.max == "undefined" || typeof mm.min == "undefined") {
            this.log({
                type: "error",
                subject: "mm's struct is {max : max ,min:min}",
                sender: "CM.Draw.updateColorByInstanceNameForOneValue"
            });
            return;
        }
        if (typeof oneValue == "undefined") {
            this.log({
                type: "error",
                subject: "the value muse be have",
                sender: "CM.Draw.updateColorByInstanceNameForOneValue"
            });
            return;
        }
        if (this.parent.parent) {
            if (this.parent.parent.LUT) {
                this.parent.parent.LUT.setMaxMin({ max: mm.max, min: mm.min });
                this.parent.parent.LUT.update_text(title, unit);
                if (unit) this.parent.parent.LUT.setUnit(unit);
            }
            else {
                this.log({
                    type: "error",
                    subject: "LUT error",
                    sender: "CM.Draw.updateColorByInstanceNameForOneValue"
                });
            }
            let oneCMValue = (oneValue - mm.min) / (mm.max - mm.min);
            for (let i in this.data.meshTree.children) {
                let perInstance = this.data.meshTree.children[i];

                if (name == perInstance.name) {
                    if (perInstance.children[0].name == "triangles") {
                        let cm = [];
                        let count = perInstance.children[0].geometry.attributes.cm.count * 3;
                        for (let j = 0; j <= count; j++) {
                            cm.push(oneCMValue);
                        }
                        perInstance.children[0].geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 3));
                    }
                }
            }
        }
        this.render();

    }
}
export { Draw };