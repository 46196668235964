import {
    Group,
    PlaneGeometry,
    MeshBasicMaterial,
    Mesh,
    Vector3,
    Ray,
    Triangle,
    Plane,
} from "three";
import { second } from "./appSecond";
// import { appCliping } from "./ClippingThree";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
class appShowMesh extends second {

    constructor(value = falsee) {
        super(value);
        // that = this;
        return this;
    }
    beforeInit() {
        super.beforeInit();
        this.settingOfClipping = {
            local: {
                localPlane: false,
                localClippingEnabled: false,
            },
            global: {
                globalPlane: false,
                globalClippingEnabled: false,
            },
            rayTriangle: false,
            rayTriangleA: false,
            rayTriangleB: false,
            rayTriangleC: false,
            planehelper: false,
        };
    }
    afterInit() {
        super.afterInit();
    }
    init() {
        super.init();
        // this.needsUpdate = true;
    }
    controlSwitch(event, that) {
        let code = false;
        if (typeof event.keyCode !== "undefined") code = event.keyCode;
        else code = keyCode;
        // console.log(code)
        if (typeof this.CMs[0].getCurrentLevel == "function") {

            this.CMLevel = this.CMs[0].getCurrentLevel();

            // if (event.ctrlKey == true) {
            //     switch (code) {

            //     }
            // }
            // else 
            {
                switch (code) {
                case 33: // page up
                    let nextL = this.getPreLevel(this.CMLevel[0], this.CMLevel[1]);
                    this.CM.showStep(nextL);
                    this.getFrameNo(nextL);
                    break;
                case 34: // page down
                    let preL = this.getNextLevel(this.CMLevel[0], this.CMLevel[1]);
                    this.CM.showStep(preL);
                    this.getFrameNo(preL);
                    break;
                case 87: // W
                    this.control.transform.setMode("translate");
                    this.render();
                    break;

                case 69: // E
                    this.control.transform.setMode("rotate");
                    this.render();
                    break;

                    // case 82: // R
                    //     this.control.transform.setMode("scale");
                    //     this.render();
                    //     break;
                }
            }
        }
    }
    initControls() {
        let that = this;
        super.initControls();

        this.control.transform = new TransformControls(this.perpCamera, this.renderer.domElement);
        that.control.transform.addEventListener("dragging-changed", function (event) {
            that.control.ctl.enabled = !event.value;
        });
        that.control.transform.addEventListener("mouseUp", function (event) {
            that.needsUpdate = false;
            if (typeof that.clipping != "undefined")
                if (that.clipping && that.clipping.enable) {
                    that.clipping.TransformRender(that);
                }
            that.render(that);
        });
        that.control.transform.addEventListener("mouseDown", function (event) {
            that.needsUpdate = true;
        });
        this.scene.add(this.control.transform);
    }

}


export { appShowMesh };