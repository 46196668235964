import { ResGID } from "./resGID";
import { ResAbaqus } from "./resAbaqus";
class ResAnalysis {

    constructor(callbacks = false) {
        this.fileAnalysis = {};
        this.md5OfFiles = {
            //"md5":filename,
        };
        this.filenames = {
            // filename:filename
        };
        this.callWorker = {};
        this.init(callbacks);
    }
    init(callback) {

        if (callback) {
            for (let i in callback) {
                this.callWorker[i] = callback[i];
            }
        }
        else {
            this.log("resAnalysis : can't find the callback or worker input");
        }
        this.log("resAnalysis Init.");
    }
    async do(data) {
        if (data.ext == "res") {
            this.fileAnalysis = new ResGID(data);
        }
        else if (data.ext == "dat") {
            this.fileAnalysis = new ResAbaqus(data);
        }
        else {
            this.log({
                type: "error",
                succeed: false,
                subject: "资源文件不在已经解析的范围内",
                sender: "res module",
            });
            return;
        }
        if (this.fileAnalysis.succeed)
            this.postMSG({
                type: "data",
                data: this.fileAnalysis.data,
                succeed: this.fileAnalysis.succeed,
                index: data.index,
                subject: { "res": data.ext }
            });
        else {
            this.log({
                type: "error",
                succeed: this.fileAnalysis.succeed,
                subject: "解析资源错误",
                data: this.fileAnalysis.MSG,
                sender: "res module",
            });
        }
    }
    postMSG(data) {
        // this.callWorker.onMSG({ type: "Mesh", content: "dfasdfasdf" }, this.callWorker.parent);
        this.callWorker.onMSG(data);
    }
    // this function will be rewrite by worker fork class
    log(msg, that = false) {
        if (that == false) {
            that = this;
        }
        if (typeof that.callWorker["log"] != "undefined") {
            that.callWorker.log(msg, that.callWorker.parent);
        }
        else {
            console.log("RES : ", msg);
        }
    }

}

export { ResAnalysis };