import {
    BufferGeometry,
    Mesh,
    Vector3,
    Object3D,
    Box3,
    // Color,
    // MeshStandardMaterial,
    MeshBasicMaterial,
    // LineBasicMaterial,
    // MeshPhongMaterial,
    // Line,
    // Line3,
    // Plane,
    Group,
    BufferAttribute,
    DoubleSide,
    LineSegments,
    // Ray,
    Float32BufferAttribute,
    // LineLoop,
    // FileLoader,
    // ObjectLoader,
    Matrix4,
    // EdgesGeometry, Box3,
    // ArrowHelper,
    Points,
    SphereGeometry,
    BoxGeometry,
    ShapeGeometry,
    Shape,
    Path
} from "three";
import { DefineMaterial } from "./material";// no worker 
import { Draw } from "./drawThree";
import { compile } from "vue";
// import { Clipping } from "./clipping";// worker ready
// import { rayPickup } from "./raypickup";//worker ready 
class DrawMidas extends Draw {

    constructor(parent, callback = false) {
        super(parent, callback);

    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of data
    init(callback) {
        if (callback) {
            for (let i in callback) {
                this.callWorker[i] = callback[i];
            }
        }
        this.data.midas = {
            triangle: {
                cmIndex: [],
                index: [],
                xyzs: [],

            },
            frameLine: {
                index: [],
                xyzs: []
            },

        };
        this.scene = this.parent.otherParent.Draw.data.meshTree;
        this.data.meshTree.name = "midas";
        this.data.meshTree.visible = this.parent.input.visible;
        this.scene.add(this.data.meshTree);

    }

    setData(data) {
        this.data.source = data.data.midas;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    //once draw all triangle 
    getTriangle(transEnable = false, transRate = false) {

        this.scene.remove(this.data.meshTree);
        this.data.meshTree = new Group();
        this.data.meshTree.name = "midas";
        this.data.meshTree.visible = this.parent.midasSetting.visible;
        this.scene.add(this.data.meshTree);
        console.log('this.parent.info.data', this.parent, this.parent.info)

        if (this.setting.DefaultTransSite === false) {
            this.setting.DefaultTransSite = this.parent.otherParent.Draw.setting.DefaultTransSite;
        }
        let moved = this.parent.info.data.midas.moved / 2;
        let elements = this.parent.info.data.midas.midas.struct.elements;
        let covers = this.parent.info.data.midas.midas.struct.covers;
        let frameLine = this.parent.info.data.midas.midas.struct.frameLine;
        let nodes = this.parent.info.data.midas.midas.source.nodes;

        this.data.pickup = this.getInstanceOfElement(elements, nodes, this.data.midas.triangle, transEnable, moved);
        this.getTriangleOfElemnet(elements, nodes, this.data.midas.triangle, transEnable, moved);
        this.getTriangleOfCover(covers, nodes, this.data.midas.triangle, transEnable, moved);
        this.getFrameLine(frameLine, nodes, this.data.midas.frameLine, transEnable, moved);
    }
    // triangle of per element 
    //问题，cm的值会跟随三角形而变化，不能采用index

    getTriangleOfElemnet(sourceData, nodes, target, transEnable, moved) {

        let site = this.setting.DefaultTransSite[1];
        let transRate = this.setting.transRate;
        let geometry = new BufferGeometry();
        let uvs = [], normals = [], cms = [], positions = [], index = [];
        for (let i in sourceData) {
            let perElement = sourceData[i];

            // if (i != 583) continue;

            for (let j = 0; j < perElement.index.length; j++) {
                let A = nodes[perElement.pointIndexOfNode[perElement.index[j]]];
                let one = perElement.points[perElement.index[j]];
                let x = 0, y = 0, z = 0;
                if (this.setting.enabletrans) {
                    let uvw = this.getOnePointUVW(false, this.setting.currentCMLevel, site, perElement.pointIndexOfNode[perElement.index[j]]);

                    if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                        let dir = this.parent.otherParent.Draw.setting.currentCMSite.split(".")[1];
                        if (typeof dir != "undefined") {
                            if (dir == "u") {
                                if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                                    x = uvw[0] * transRate;
                                }
                                else {
                                    x = 0;
                                }
                            }
                            else if (dir == "v") {

                                if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                                    y = uvw[1] * transRate;
                                }
                                else {
                                    y = 0;
                                }
                            }
                            else if (dir == "w") {

                                if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                                    if (site.length == 3) z = uvw[2] * transRate;
                                }
                                else {
                                    z = 0;
                                }
                            }
                        }
                    }
                    else {

                        if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                            x = uvw[0] * transRate;
                        }
                        else {
                            x = 0;
                        }


                        if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                            y = uvw[1] * transRate;
                        }
                        else {
                            y = 0;
                        }


                        if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                            if (site.length == 3) z = uvw[2] * transRate;
                        }
                        else {
                            z = 0;
                        }
                    }
                }

                if (one[2] == 0) {

                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);
                }
                else if (one[1] == 0) {

                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);
                }
                else
                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);

                cms.push(0.2, 0.2, 0.2);

            }
            for (let j = 0; j < perElement.index.length; j += 3) {
                uvs.push(0, 0, 1, 0, 1, 1);
            }
            // for (let j in perElement.index) {
            //     index.push(indexOfPoints + perElement.index[j]);
            // }
            // break;
        }
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));
        geometry.setAttribute("cm", new Float32BufferAttribute(cms, 3));
        geometry.setAttribute("uv", new Float32BufferAttribute(new Float32Array(uvs), 2));
        // geometry.setIndex(index);
        let mesh = new Mesh(geometry, this.material.TriangleShaderStair());
        mesh.name = "elements";
        this.data.meshTree.add(mesh);
    }

    getCMIndexOfTriangleOfElemnet() {
        let elements = this.parent.info.data.midas.midas.struct.elements;

        let list = [];
        for (let i in elements) {
            // let CMI = [];
            // let index = [];
            // let perElement = elements[i];
            // for (let j of perElement.cmIndex) {
            //     CMI.push(j);
            // }
            let perElement = elements[i];
            list[i] = {
                CMI: perElement.cmIndex,
                index: perElement.index,
            };
        }
        return list;
    }
    getCMIndexOfStrOfTriangleOfElemnet() {
        let elements = this.parent.info.data.midas.midas.struct.elements;
        let CMI = {};
        for (let i in elements) {
            let perElement = elements[i];
            CMI[i] = perElement.cmIndexOfStr;

        }
        return CMI;
    }
    //once draw all frame line 
    getFrameLine(sourceData, nodes, target, transEnable, moved) {
        let site = this.setting.DefaultTransSite[1];
        let transRate = this.setting.transRate;
        let geometry = new BufferGeometry();
        let positions = [], index = [];
        for (let i in sourceData) {
            let perElement = sourceData[i];
            let indexOfPoints = positions.length / 3;




            // for (let j in perElement.points) {
            //     let A = nodes[perElement.pointIndexOfNode[j]];
            //     let one = perElement.points[j];

            for (let j = 0; j < perElement.index.length; j++) {
                let A = nodes[perElement.pointIndexOfNode[perElement.index[j]]];
                let one = perElement.points[perElement.index[j]];

                let x = 0, y = 0, z = 0;
                if (this.setting.enabletrans) {
                    let uvw = this.getOnePointUVW(false, this.setting.currentCMLevel, site, perElement.pointIndexOfNode[perElement.index[j]]);

                    if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                        let dir = this.parent.otherParent.Draw.setting.currentCMSite.split(".")[1];
                        if (typeof dir != "undefined") {
                            if (dir == "u") {
                                if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                                    x = uvw[0] * transRate;
                                }
                                else {
                                    x = 0;
                                }
                            }
                            else if (dir == "v") {

                                if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                                    y = uvw[1] * transRate;
                                }
                                else {
                                    y = 0;
                                }
                            }
                            else if (dir == "w") {

                                if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                                    if (site.length == 3) z = uvw[2] * transRate;
                                }
                                else {
                                    z = 0;
                                }
                            }
                        }
                    }
                    else {

                        if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                            x = uvw[0] * transRate;
                        }
                        else {
                            x = 0;
                        }


                        if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                            y = uvw[1] * transRate;
                        }
                        else {
                            y = 0;
                        }


                        if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                            if (site.length == 3) z = uvw[2] * transRate;
                        }
                        else {
                            z = 0;
                        }
                    }
                }
                // console.log(i,j);
                // if (j == 32) {
                //     let abc = 1;
                // }


                if (one[2] == 0) {

                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);
                }
                else if (one[1] == 0) {

                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);
                }
                else
                    positions.push(A[0] + one[0] + x, A[1] + one[1] + y, A[2] + one[2] + z + moved);

            }
            // for (let j in perElement.index) {
            //     index.push(indexOfPoints + perElement.index[j]);
            // }

            // break;

        }



        geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));
        // geometry.setIndex(index);
        let mesh = new LineSegments(geometry, this.material.FameLine());
        mesh.name = "framelines";
        this.data.meshTree.add(mesh);
    }
    // triangle of per cover ,make by  three shape 
    getCMIndexOfTrianglCovereOfElemnet() {
        let elements = this.parent.info.data.midas.midas.struct.covers;
        let CMI = [];
        for (let i in elements) {
            let perElement = elements[i];
            if (perElement.inside[0].length == 0 && perElement.outside.length == 4)

                CMI.push(perElement.id, perElement.id, perElement.id, perElement.id, perElement.id, perElement.id);

        }
        return CMI;
    }
    getCMStrIndexOfTrianglCovereOfElemnet() {
        let elements = this.parent.info.data.midas.midas.struct.covers;
        let CMI = [];
        for (let i in elements) {
            let perElement = elements[i];
            if (perElement.inside[0].length == 0 && perElement.outside.length == 4)
                CMI.push(perElement.id, perElement.id, perElement.id, perElement.id, perElement.id, perElement.id);
        }
        return CMI;
    }
    getTriangleOfCover(sourceData, nodes, target, transEnable, moved) {
        let site = this.setting.DefaultTransSite[1];
        let transRate = this.setting.transRate;
        let geometry = new BufferGeometry();
        let uvs = [], normals = [], cms = [], positions = [], index = [];
        for (let i in sourceData) {
            let perElement = sourceData[i];
            if (perElement.inside[0].length == 0 && perElement.outside.length == 4) {

                let x = 0, y = 0, z = 0;

                if (this.setting.enabletrans) {
                    let uvw = this.getOnePointUVW(false, this.setting.currentCMLevel, site, perElement.id);

                    if (typeof this.parent.input.extraSetting !== "undefined" && typeof this.parent.input.extraSetting.transDir !== "undefined" && this.parent.input.extraSetting.transDir == 1) {
                        let dir = this.parent.otherParent.Draw.setting.currentCMSite.split(".")[1];
                        if (typeof dir != "undefined") {
                            if (dir == "u") {
                                if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                                    x = uvw[0] * transRate;
                                }
                                else {
                                    x = 0;
                                }
                            }
                            else if (dir == "v") {

                                if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                                    y = uvw[1] * transRate;
                                }
                                else {
                                    y = 0;
                                }
                            }
                            else if (dir == "w") {

                                if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                                    if (site.length == 3) z = uvw[2] * transRate;
                                }
                                else {
                                    z = 0;
                                }
                            }
                        }
                    }
                    else {

                        if (this.setting.transDir == "u" || this.setting.transDir == "all") {
                            x = uvw[0] * transRate;
                        }
                        else {
                            x = 0;
                        }


                        if (this.setting.transDir == "v" || this.setting.transDir == "all") {
                            y = uvw[1] * transRate;
                        }
                        else {
                            y = 0;
                        }


                        if (this.setting.transDir == "w" || this.setting.transDir == "all") {
                            if (site.length == 3) z = uvw[2] * transRate;
                        }
                        else {
                            z = 0;
                        }
                    }
                }

                let A = nodes[perElement.id];
                let a = perElement.outside[0];
                let b = perElement.outside[1];
                let c = perElement.outside[2];
                let d = perElement.outside[3];

                // if (a[2] == 0) {
                positions.push(A[0] + a[0] + x, A[1] + a[1] + y, A[2] + a[2] + z + moved);
                positions.push(A[0] + b[0] + x, A[1] + b[1] + y, A[2] + b[2] + z + moved);
                positions.push(A[0] + d[0] + x, A[1] + d[1] + y, A[2] + d[2] + z + moved);


                positions.push(A[0] + b[0] + x, A[1] + b[1] + y, A[2] + b[2] + z + moved);
                positions.push(A[0] + c[0] + x, A[1] + c[1] + y, A[2] + c[2] + z + moved);
                positions.push(A[0] + d[0] + x, A[1] + d[1] + y, A[2] + d[2] + z + moved);
                // }
                // else if (a[1] == 0) {

                //     positions.push(A[0] + a[0], A[1] + a[1], A[2] + a[2] + moved);
                // }
                // else
                //     positions.push(A[0] + a[0], A[1] + a[1], A[2] + a[2] + moved);


                cms.push(0.2, 0.2, 0.2);
                cms.push(0.2, 0.2, 0.2);
                cms.push(0.2, 0.2, 0.2);
                cms.push(0.2, 0.2, 0.2);
                cms.push(0.2, 0.2, 0.2);
                cms.push(0.2, 0.2, 0.2);

                uvs.push(0, 0, 1, 0, 1, 1);
                uvs.push(0, 0, 1, 0, 1, 1);
                uvs.push(0, 0, 1, 0, 1, 1);
                uvs.push(0, 0, 1, 0, 1, 1);
                uvs.push(0, 0, 1, 0, 1, 1);
                uvs.push(0, 0, 1, 0, 1, 1);
            }
        }
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));
        geometry.setAttribute("cm", new Float32BufferAttribute(cms, 3));
        geometry.setAttribute("uv", new Float32BufferAttribute(new Float32Array(uvs), 2));
        // geometry.setIndex(index);
        let mesh = new Mesh(geometry, this.material.TriangleShaderStair());
        mesh.name = "covers";
        this.data.meshTree.add(mesh);
    }



    //pickup ,instance or box
    getInstanceOfElement(sourceData, nodes, target, transEnable) {

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Parte of draw
    draw() {
        let enableTrane = this.setting.enabletrans = this.parent.otherParent.Draw.getTransEnable();
        let transRate = this.setting.transRate = this.parent.otherParent.Draw.getTransRate();


        this.getTriangle(enableTrane, transRate);



        // if (this.parent.setting.center) {
        //     this.center();
        // }
        this.afterDraw();


    }

    afterDraw() {
        this.callback.afterDraw(this.parent, "draw");
    }

    show() {
        this.setting.DefaultTransSite = this.parent.otherParent.Draw.setting.DefaultTransSite;
        this.setting.enabletrans = this.parent.otherParent.Draw.setting.enabletrans;
        this.setting.transRate = this.parent.otherParent.Draw.setting.transRate;

        this.scene.remove(this.data.meshTree);
        this.data.meshTree = new Group();
        this.data.meshTree.name = "midas";
        this.scene.add(this.data.meshTree);

        this.draw();
        if (this.parent.otherParent.Draw.setting.currentCMSite)
            this.updateColors(this.parent.otherParent.Draw.setting.currentCMSite);
        this.parent.parent.render();
    }


    //////////////////////////////////////
    //标准云图
    //update color for lines and points ,triangle don't need this function ,because update by updatePressures attribute of cm
    updateColorsOfTriangle(nameOfSiteCM, instanceName, child) {
        // let MM;
        let cm = [];
        let values = [];

        if (nameOfSiteCM.indexOf("str.") != -1) {
            let CMI = this.getCMIndexOfStrOfTriangleOfElemnet();//

            let MM = this.parent.info.getMMOfStrALL(nameOfSiteCM);
            if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
                let max = MM.max;
                let min = MM.min;
                MM.max = -min;
                MM.min = -max;
            }
            values = this.parent.info.getSiteAllValuesOfStrOfLines(nameOfSiteCM, instanceName);
            for (let i in CMI) {
                // if (i != 583) continue;
                let perValues = values[i];
                let perElementCMSI = CMI[i];
                if (typeof perValues == "undefined") {
                    for (let j = 0; j < perElementCMSI.length; j++) {
                        cm.push(1, 1, 1);
                    }
                    // console.log(i);
                    continue;
                }

                for (let j = 0; j < perElementCMSI.length;) {
                    let a = perElementCMSI[j++];
                    let b = perElementCMSI[j++];
                    let c = perElementCMSI[j++];

                    let perOne1 = perValues[a];
                    let perOne2 = perValues[b];
                    let perOne3 = perValues[c];

                    if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
                        perOne1 = - perValues[a];
                        perOne2 = - perValues[b];
                        perOne3 = - perValues[c];
                    }

                    let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
                    let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
                    let pressures3 = (perOne3 - MM["min"]) / (MM["max"] - MM["min"]);

                    cm.push(pressures1, pressures2, pressures3);
                    cm.push(pressures1, pressures2, pressures3);
                    cm.push(pressures1, pressures2, pressures3);
                    // if (i == 579) {
                    //     // continue;
                    //     // console.log(a, b, c, perOne1, perOne2, perOne3, pressures1, pressures2, pressures3);
                    // }
                }

            }
        }
        else {
            // let CMI = this.getCMIndexOfTriangleOfElemnet();
            let elements = this.getCMIndexOfTriangleOfElemnet();
            let MM = this.getMaxMinOfCM(nameOfSiteCM);
            // console.log(MM);
            values = this.parent.info.getSiteAllValues(nameOfSiteCM, instanceName, this.setting.currentCMLevel);
            for (let Ei in elements) {
                let perElement = elements[Ei];
                let perCMI = perElement.CMI;
                let perIndex = perElement.index;
                let L = cm.length;

                for (let ii = 0; ii < perIndex.length;) {
                    let a = perIndex[ii++];
                    let b = perIndex[ii++];
                    let c = perIndex[ii++];

                    let A = perCMI[a];
                    let B = perCMI[b];
                    let C = perCMI[c];

                    let perOne1 = values[A];
                    let perOne2 = values[B];
                    let perOne3 = values[C];
                    if (typeof perOne1 == "undefined" || typeof perOne2 == "undefined" || typeof perOne3 == "undefined") {
                        perOne3 = perOne1;
                    }
                    let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
                    let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
                    let pressures3 = (perOne3 - MM["min"]) / (MM["max"] - MM["min"]);
                    cm.push(pressures1, pressures2, pressures3);
                    cm.push(pressures1, pressures2, pressures3);
                    cm.push(pressures1, pressures2, pressures3);
                }
            }
        }
        // console.log(cm.length);
        child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 3));
    }
    updateColorsOfCover(nameOfSiteCM, instanceName, child) {
        // let MM;
        let cm = [];
        let values = [];
        if (nameOfSiteCM.indexOf("str.") != -1) {
            let CMI = this.getCMStrIndexOfTrianglCovereOfElemnet();
            let MM = this.parent.info.getMMOfStrALL(nameOfSiteCM);
            if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
                let max = MM.max;
                let min = MM.min;
                MM.max = -min;
                MM.min = -max;
            }

            values = this.parent.info.getSiteAllValuesOfStrOfLines(nameOfSiteCM, instanceName, this.setting.currentCMLevel);
            for (let i = 0; i < CMI.length; i++) {
                // console.log(i);
                let perCoverEid = CMI[i];
                if (typeof values[perCoverEid] == "undefined") {
                    cm.push(0, 0, 0);
                    // cm.push(0, 0, 0);
                    // cm.push(0, 0, 0);
                    // console.log(i,perCoverEid)
                    continue;
                }
                let a = values[perCoverEid][0];
                let perOne1 = a;
                let perOne2 = a;
                let perOne3 = a;
                if (this.setting.currentCMSite == "str.Fx" || this.setting.currentCMSite == "str.My" || this.setting.currentCMSite == "str.fx" || this.setting.currentCMSite == "str.my") {
                    perOne1 = -a;
                    perOne2 = -a;
                    perOne3 = -a;
                }
                let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures3 = (perOne3 - MM["min"]) / (MM["max"] - MM["min"]);
                cm.push(pressures1, pressures2, pressures3);
                cm.push(pressures1, pressures2, pressures3);
                cm.push(pressures1, pressures2, pressures3);
            }
        }
        else {
            let CMI = this.getCMIndexOfTrianglCovereOfElemnet();
            let MM = this.getMaxMinOfCM(nameOfSiteCM);
            values = this.parent.info.getSiteAllValues(nameOfSiteCM, instanceName);
            for (let i = 0; i < CMI.length;) {
                let a = CMI[i++];
                let b = CMI[i++];
                let c = CMI[i++];

                let perOne1 = values[a];
                let perOne2 = values[b];
                let perOne3 = values[c];
                let pressures1 = (perOne1 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures2 = (perOne2 - MM["min"]) / (MM["max"] - MM["min"]);
                let pressures3 = (perOne3 - MM["min"]) / (MM["max"] - MM["min"]);
                cm.push(pressures1, pressures2, pressures3, pressures1, pressures2, pressures3, pressures1, pressures2, pressures3);

            }
        }
        child.geometry.setAttribute("cm", new Float32BufferAttribute(new Float32Array(cm), 3));
    }


    updateColorsOfCM(nameOfSiteCM, title = false, unit = false) {
        //forearch the CM object
        this.setting.currentCMSite = nameOfSiteCM;
        let allInstance = this.data.meshTree.children;
        for (let i in allInstance) {

            let child = allInstance[i];
            let childName = child.name;// get the child object name :
            let visible = child.visible;
            //check visible 
            if (visible === false || childName == "frameLines") {
                //  frame line ignore
                continue;
            }
            else {
                //check the kind of PLTFR  type ,and do different method
                // triangle update cm
                if (childName == "elements") {
                    this.updateColorsOfTriangle(nameOfSiteCM, childName, child);
                    if (child.visible === false)
                        child.visible = true;
                }
                // // line update color 
                // else if (childName == "framelines") {
                //     this.updateColorsOfLine(nameOfSiteCM, instanceName, child);
                //     if (child.visible === false)//防止STR禁止 显示后的问题
                //         child.visible = true;
                //     // this.updateLutColor(child);
                // }
                //  point  updat color
                else if (childName == "covers") {
                    this.updateColorsOfCover(nameOfSiteCM, childName, child);
                    if (child.visible === false)
                        child.visible = true;
                }
            }

        }
        // this.repairPoly();
        this.updateLutText(nameOfSiteCM, title, unit);
        this.parent.parent.render();
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update pressures for triangles,lines ,points ,they have diffent way (cm and pressure)

    /////////////////////////////////////////////////////
    //updateColors 总入口
    updateColors(nameOfSiteCM, title = false, unit = false) {
        this.updateColorsOfCM(nameOfSiteCM, title, unit);
    }
    doTrans(that = false) {
        let enableTrane = this.setting.enabletrans;//= this.parent.otherParent.Draw.getTransEnable();
        let transRate = this.setting.transRate = this.parent.otherParent.Draw.getTransRate();


        this.getTriangle(enableTrane, transRate);
    }
    play(s = 0.5) {
        if (this.timer.timer === false) {
            if (this.parent.getGlobalCMMMVisable()) {
                let that = this;
                let siteOfCM = this.setting.currentCMSite;
                let timerIndexList = that.getResTimeList();
                this.timer.timer = setInterval(function () {

                    that.setting.enabletrans = that.parent.otherParent.Draw.getTransEnable();
                    if (that.setting.enabletrans) {
                        that.doTrans(that);
                    }
                    that.updateColors(siteOfCM);
                    if (that.parent.parent.needsUpdate === false)
                        that.render(that.parent.parent);
                    // if (that.timer.visible == false) {
                    //     clearInterval(that.timer.timer);
                    // }
                    if (that.timer.timerIndex >= timerIndexList.length - 1) {
                        that.timer.timerIndex = 0;
                        that.setting.currentCMLevel = timerIndexList[that.timer.timerIndex];
                    }
                    else {
                        that.timer.timerIndex++;
                        that.setting.currentCMLevel = timerIndexList[that.timer.timerIndex];
                    }
                }, s * 1000);
            }
        }
    }

}
export { DrawMidas };