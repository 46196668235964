
import {
    Vector3, Group, Matrix4, Box3, Line3, BufferGeometry, BufferAttribute, Float32BufferAttribute, Mesh, LineSegments
} from "three";
import { md5 } from "../libs/md5/md5";
import triangulate from "delaunay-triangulate";
// import {triangulate} from "../libs/triangulate/triangulate";

class Clipping {

    constructor(parent) {
        this.parent = parent;
        this.data = this.parent.data;
        this.drawTree = this.parent.data.drawTree;
        this.scene = this.parent.data.meshTree;
        this.max = this.maxOfObject(this.scene) * 10;
        this.zero = 0.00000001;
        this.localPlaneOf2D = [];// two plane of xyz's planes
        this.localPlaneS = [];// xyz planes
        this.pointsOfXAxis = [];
        this.dataOfClipping = {
            triangles: {
                xyz: [],//1*3
                cm: [],//1*1
            },
            framelines: [],
        };
        this.groupOfPoly = {};
        this.tempOfFrameLines = {
            // md5 of sort of "x1-x2-u1-y2": {
            //     //     xyz:[] ,//1*3
            //     //     cm:[],//1*1
            // }
        };
        this.updateUVW2Network(true);
        this.repairPoly();
        return this;
    }


    applyMatrix4(positions, m4) {
        let lists = [];
        for (let position of positions) {

            let vector = new Vector3(position[0], position[1], position[2]);
            vector.applyMatrix4(m4);
            lists.push([vector.x, vector.y, vector.z]);
        }
        return lists;
    }
    updateUVW2Network(init = false) {
        if (init === false) {
            if (this.rateOfTrans == this.parent.getTransRate() && this.enableOfTrans == this.parent.getTransEnable()) {//变形是否改变 ，变形比率是否改变
                return;//无变化
            }
        }

        this.enableOfTrans = this.parent.getTransEnable();
        this.rateOfTrans = this.parent.getTransRate();

        for (let i in this.drawTree) {
            if (i == "Dimension_2OR3") {
                continue;
            }
            let instanceName = i;
            //get the name of group of CM 
            let instance = this.drawTree[i];
            let network = instance.network;
            for (let j of network) {

                let xyzuwv = this.parent.parent.getPointsXYZUVW(instanceName, j.index);
                let xyz = xyzuwv.xyz;
                if (instance.matrix4)
                    xyz = this.applyMatrix4(xyz, instance.matrix4);
                let uvw = xyzuwv.uvw;
                if (this.enableOfTrans === true) {
                    for (let l in xyz) {
                        let perXYZ = xyz[l];
                        for (let m in perXYZ) {

                            xyz[l][m] += uvw[l][m] * this.rateOfTrans;
                        }
                    }
                }
                j.xyz = xyz;
            }
        }
    }
    maxOfObject(obj) {
        var box3 = new Box3();
        // 计算层级模型group的包围盒
        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
        box3.expandByObject(obj);
        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
        let cx = box3.max.x - box3.min.x;
        let cy = box3.max.y - box3.min.y;
        let cz = box3.max.z - box3.min.z;
        let centerMax = cx > cy ? cx : (cy > cz ? cy : cz);
        return centerMax;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // functoin

    repairPoly() {
        // console.log("repairPoly");
        /**
         * 0、预处理
         *  A、plane，applyMatrix4(CM)
         *  B、平原原点，平面x远点
         *  C、function：计算距离ABC，计算xy
         *  D、释放旧数据，
         *  E、element 单元处理，
         *      、更新xyz，（是否有变形UVW的叠加）
         * 1、box3相交     
         *  B、线段相交判断 求交点，pressure值
         *  C、求2D的xy
         *  D、function 三角形剖分，三角形集合，线集合（优化）单元内
         *  E、写入，triangles，CM（处理后），framelines
         *  
         * 2、画图
         *  A、释放三角形，是否framelines
         *  B、三角形
         *  C、framelines
         *  
         */

        this.pretreatment();
        this.computeData();
        this.draw();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    pretreatment() {
        this.M4X = new Matrix4();
        this.M4X.makeRotationX(0.5 * Math.PI);
        this.M4Y = new Matrix4();
        this.M4Y.makeRotationY(0.5 * Math.PI);
        this.M4Z = new Matrix4();
        this.M4Z.makeRotationZ(0.5 * Math.PI);

        this.CMM4 = this.parent.data.meshTree.matrix.clone();
        this.CMM4.invert();

        this.pointsOfClipping = {
            index: [],
            xyz: [],
            pressures: []
        };
        this.pointsOfAll = {
            index: [],//link 
            xyz: [],// xyz of trans if enableOfTransForm ,and if update rate ,xyz will compute again
            rateOfTransForm: 1,
            enableOfTransForm: false,

        };

        this.localPlane = this.parent.parent.parent.clipping.localPlane.clone();
        this.localPlane.applyMatrix4(this.CMM4);

        this.localPlaneS = [];
        this.localPlaneS[0] = this.localPlane.clone();
        this.localPlaneS[0].applyMatrix4(this.M4X);
        this.localPlaneS[1] = this.localPlane.clone();
        this.localPlaneS[1].applyMatrix4(this.M4Y);
        this.localPlaneS[2] = this.localPlane.clone();
        this.localPlaneS[2].applyMatrix4(this.M4Z);

        let j = 0;
        this.localPlaneOf2D = [];
        for (let i in this.localPlaneS) {
            let plane = this.localPlaneS[i];
            if (Math.abs(plane.normal.x - this.localPlane.normal.x) <= this.zero && Math.abs(plane.normal.y - this.localPlane.normal.y) <= this.zero && Math.abs(plane.normal.z - this.localPlane.normal.z) <= this.zero) {
                continue;
            }
            else
                this.localPlaneOf2D[j++] = plane;
        }

        this.pointsOfXAxis = [
            new Vector3(), new Vector3()
        ];
        let point1OfY = this.localPlaneOf2D[0].normal.clone();
        point1OfY.x *= this.max;
        point1OfY.y *= this.max;
        point1OfY.z *= this.max;
        let point2OfY = this.localPlaneOf2D[0].normal.clone();
        point2OfY.x *= - parseFloat(this.max);
        point2OfY.y *= - parseFloat(this.max);
        point2OfY.z *= - parseFloat(this.max);

        this.pointsOfXAxis[2] = 2 * Math.abs(parseFloat(this.max));

        this.localPlaneOf2D[1].constant = this.max;
        this.localPlaneOf2D[1].projectPoint(point1OfY, this.pointsOfXAxis[0]);
        this.localPlaneOf2D[1].projectPoint(point2OfY, this.pointsOfXAxis[1]);

        this.updateUVW2Network();
    }
    getBox3OfPoints(points) {
        let box3 = new Box3();
        for (let xyz of points) {
            box3.expandByPoint(new Vector3(xyz[0], xyz[1], xyz[2]));
        }
        return box3;
    }
    intersectPoints(instanceName, plane, oneElement) {
        let intersect = plane(this.getBox3OfPoints(oneElement.xyz));
        if (intersect) {
            let points = {
                // "1-1-1": {
                //     xyz: [],//6 个
                //     pressure: 0,//1 个
                //     enable: true,
                //     parentOfIndex: []//2 个
                // }
            };
            let lines = [];
            if (oneElement.face == 6) {
                let element = oneElement.index;//element的 单体
                let e1 = element[0];//element 的点序列，矩形体
                let e2 = element[1];
                let e3 = element[2];
                let e4 = element[3];
                let e5 = element[4];
                let e6 = element[5];
                let e7 = element[6];
                let e8 = element[7];
                lines = [
                    e1, e4, e4, e3, e3, e2, e2, e1,//right
                    e5, e8, e8, e7, e7, e6, e6, e5,//left
                    e5, e1, e2, e6, e3, e7, e4, e8,//other
                ];
            }
            else if (oneElement.face == 4) {
                let element = oneElement.index;//element的 单体
                let e1 = element[0];//element 的点序列，矩形体
                let e2 = element[1];
                let e3 = element[2];
                let e4 = element[3];
                lines = [
                    e1, e2,
                    e2, e3,
                    e3, e1,

                    e1, e4,
                    e2, e4,
                    e3, e4,
                ];
            }
            for (let line_i = 0; line_i < lines.length; line_i += 2) {
                let perStore = JSON.parse(JSON.stringify([lines[line_i], lines[line_i + 1]])).sort().join("-");
                if (typeof points[perStore] == "undefined") {
                    points[perStore] = {
                        index: [lines[line_i], lines[line_i + 1]],
                        xyz: [
                            nodes[lines[line_i]], nodes[lines[line_i + 1]]
                        ]
                    };
                }
            }
        }
        else {
            return false;
        }
    }

    getMeshTreeChileByName(name = false) {
        let meshTree = this.scene.children;
        for (let i of meshTree) {
            if (i.name == name && i.visible === true)
                return true;
        }
        return false;
    }
    computeData() {
        this.dataOfClipping = {
            triangles: {
                xyz: [],//1*3
                cm: [],//1*1
                uv: [],
                normal: [],
            },
            framelines: [],
        };

        let MM = this.parent.parent.getMaxMinOfCM(this.parent.getCurrentCMSite());
        for (let i in this.drawTree) {
            if (i == "Dimension_2OR3") {
                continue;
            }
            let instanceName = i;
            let abc = this.getMeshTreeChileByName(i);
            if (abc === false) {
                continue;
            }

            let network = this.drawTree[i].network;
            for (let j in network) {
                let oneElement = network[j];
                let intersectBox = this.intersectBoxOfPlane(oneElement.xyz, this.localPlane);
                let intersectPoints = [];
                let intersectPoints2D = [];//[[x1,y1],[x2,y2]]
                if (intersectBox) {
                    let tempOfFrameLines = {};
                    intersectPoints = this.getPointsOfIntersectLinesOfPlane(oneElement, this.localPlane, instanceName);
                    if (intersectPoints.length == 3) {//three point 
                        //triangle's points
                        this.dataOfClipping.triangles.xyz.push(intersectPoints[0].xyz[0], intersectPoints[0].xyz[1], intersectPoints[0].xyz[2]);
                        this.dataOfClipping.triangles.xyz.push(intersectPoints[1].xyz[0], intersectPoints[1].xyz[1], intersectPoints[1].xyz[2]);
                        this.dataOfClipping.triangles.xyz.push(intersectPoints[2].xyz[0], intersectPoints[2].xyz[1], intersectPoints[2].xyz[2]);
                        let pressures11 = (intersectPoints[0].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                        let pressures21 = (intersectPoints[1].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                        let pressures31 = (intersectPoints[2].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                        this.dataOfClipping.triangles.cm.push(pressures11, pressures21, pressures31, pressures11, pressures21, pressures31, pressures11, pressures21, pressures31);

                        //frameline
                        this.dataOfClipping.framelines[md5([intersectPoints[0], intersectPoints[1]].sort().join("-"))] =
                            [intersectPoints[0].xyz[0], intersectPoints[0].xyz[1], intersectPoints[0].xyz[2], intersectPoints[1].xyz[0], intersectPoints[1].xyz[1], intersectPoints[1].xyz[2]];
                        this.dataOfClipping.framelines[md5([intersectPoints[1], intersectPoints[2]].sort().join("-"))] =
                            [intersectPoints[1].xyz[0], intersectPoints[1].xyz[1], intersectPoints[1].xyz[2], intersectPoints[2].xyz[0], intersectPoints[2].xyz[1], intersectPoints[2].xyz[2]];
                        this.dataOfClipping.framelines[md5([intersectPoints[0], intersectPoints[2]].sort().join("-"))] =
                            [intersectPoints[0].xyz[0], intersectPoints[0].xyz[1], intersectPoints[0].xyz[2], intersectPoints[2].xyz[0], intersectPoints[2].xyz[1], intersectPoints[2].xyz[2]];
                    }
                    else if (intersectPoints.length >= 3) {//>3
                        intersectPoints2D = this.getPointsOf2D(intersectPoints);
                        let cell = triangulate(intersectPoints2D);
                        for (let cell_i in cell) {//[[1,2,3],[0,1,2]], No of values is index of points2D
                            let perOne = cell[cell_i];
                            //triangle's points
                            this.dataOfClipping.triangles.xyz.push(intersectPoints[perOne[0]].xyz[0], intersectPoints[perOne[0]].xyz[1], intersectPoints[perOne[0]].xyz[2]);
                            this.dataOfClipping.triangles.xyz.push(intersectPoints[perOne[1]].xyz[0], intersectPoints[perOne[1]].xyz[1], intersectPoints[perOne[1]].xyz[2]);
                            this.dataOfClipping.triangles.xyz.push(intersectPoints[perOne[2]].xyz[0], intersectPoints[perOne[2]].xyz[1], intersectPoints[perOne[2]].xyz[2]);
                            let pressures1 = -9.;
                            let pressures2 = -9.;
                            let pressures3 = -9.;

                            if (intersectPoints[perOne[0]].pressure != -9999.9999 && intersectPoints[perOne[1]].pressure != -9999.9999 && intersectPoints[perOne[2]].pressure != -9999.9999) {
                                pressures1 = (intersectPoints[perOne[0]].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                                pressures2 = (intersectPoints[perOne[1]].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                                pressures3 = (intersectPoints[perOne[2]].pressure - MM["min"]) / (MM["max"] - MM["min"]);
                            }
                            this.dataOfClipping.triangles.cm.push(pressures1, pressures2, pressures3, pressures1, pressures2, pressures3, pressures1, pressures2, pressures3);
                            this.dataOfClipping.triangles.uv.push(0, 0,
                                1, 0,
                                1, 1);

                            //frameline
                            if (typeof tempOfFrameLines[[perOne[0], perOne[1]].sort().join("-")] == "undefined") {
                                tempOfFrameLines[[perOne[0], perOne[1]].sort().join("-")] = {
                                    xyz: [
                                        intersectPoints[perOne[0]].xyz[0], intersectPoints[perOne[0]].xyz[1], intersectPoints[perOne[0]].xyz[2],
                                        intersectPoints[perOne[1]].xyz[0], intersectPoints[perOne[1]].xyz[1], intersectPoints[perOne[1]].xyz[2]
                                    ],
                                    enable: true,
                                };
                            }
                            else {
                                tempOfFrameLines[[perOne[0], perOne[1]].sort().join("-")].enable = false;
                            }

                            if (typeof tempOfFrameLines[[perOne[1], perOne[2]].sort().join("-")] == "undefined") {
                                tempOfFrameLines[[perOne[1], perOne[2]].sort().join("-")] = {
                                    xyz: [
                                        intersectPoints[perOne[1]].xyz[0], intersectPoints[perOne[1]].xyz[1], intersectPoints[perOne[1]].xyz[2],
                                        intersectPoints[perOne[2]].xyz[0], intersectPoints[perOne[2]].xyz[1], intersectPoints[perOne[2]].xyz[2]
                                    ],
                                    enable: true,
                                };
                            }
                            else {
                                tempOfFrameLines[[perOne[1], perOne[2]].sort().join("-")].enable = false;
                            }

                            if (typeof tempOfFrameLines[[perOne[0], perOne[2]].sort().join("-")] == "undefined") {
                                tempOfFrameLines[[perOne[0], perOne[2]].sort().join("-")] = {
                                    xyz: [
                                        intersectPoints[perOne[0]].xyz[0], intersectPoints[perOne[0]].xyz[1], intersectPoints[perOne[0]].xyz[2],
                                        intersectPoints[perOne[2]].xyz[0], intersectPoints[perOne[2]].xyz[1], intersectPoints[perOne[2]].xyz[2]
                                    ],
                                    enable: true,
                                };
                            }
                            else {
                                tempOfFrameLines[[perOne[0], perOne[2]].sort().join("-")].enable = false;
                            }
                        }
                        if (Object.keys(tempOfFrameLines).length > 0) {
                            for (let tempI in tempOfFrameLines) {
                                let perFrameline = tempOfFrameLines[tempI];
                                if (perFrameline.enable) {
                                    this.dataOfClipping.framelines[md5(JSON.parse(JSON.stringify(perFrameline.xyz)).sort().join("-"))] = perFrameline.xyz;
                                }
                            }
                        }

                    }

                }

            }
        }
    }
    //获取点群在plane的xy，交点index不变
    //返回三角化序列
    //  let intersectPoint = [
    // [
    //     xyz: [],//xyz index*3
    //     pressure: 1,//perssure index*1
    // ]
    getPointsOf2D(intersectPoint) {
        let points2D = [];
        for (let perPoint of intersectPoint) {
            let p1 = new Vector3(perPoint.xyz[0], perPoint.xyz[1], perPoint.xyz[2]);
            let a = this.pointsOfXAxis[0].distanceTo(p1);
            let b = this.pointsOfXAxis[1].distanceTo(p1);
            let c = this.pointsOfXAxis[2];
            let x = -(-a * a + b * b - c * c) / (2 * c);
            let y = Math.sqrt(-(-a * a + b * b - c * c) / (4 * c * c) + a * a);
            points2D.push([x, y]);
        }
        return points2D;

    }


    //相交的点 ，从line而来，xyz and pressure，，交点index不变（以后）
    // oneElement={face:4/6,xyz:[[],[]],index:[1,2]}
    getPointsOfIntersectLinesOfPlane(oneElement, plane, instanceName) {
        let intersectPoint = [
            // [
            //     xyz: [],//xyz index*3
            //     pressure: 1,//perssure index*1
            // ]
        ];
        let indexOfLineOfPoint = [];
        if (oneElement.face == 6) {
            indexOfLineOfPoint = [
                0, 3, 3, 2, 2, 1, 1, 0,
                4, 7, 7, 6, 6, 5, 5, 4,
                4, 0, 1, 5, 2, 6, 3, 7,
            ];
        }

        if (oneElement.face == 4) {
            indexOfLineOfPoint = [
                0, 1,
                1, 2,
                2, 0,

                0, 3,
                1, 3,
                2, 3,
            ];
        }

        for (let i = 0; i < indexOfLineOfPoint.length; i += 2) {
            let arrayIndexOfP1 = indexOfLineOfPoint[i];
            let arrayIndexOfP2 = indexOfLineOfPoint[i + 1];
            let indexp1 = oneElement.index[arrayIndexOfP1];
            let indexp2 = oneElement.index[arrayIndexOfP2];
            let p1 = new Vector3(oneElement.xyz[arrayIndexOfP1][0], oneElement.xyz[arrayIndexOfP1][1], oneElement.xyz[arrayIndexOfP1][2]);
            let p2 = new Vector3(oneElement.xyz[arrayIndexOfP2][0], oneElement.xyz[arrayIndexOfP2][1], oneElement.xyz[arrayIndexOfP2][2]);
            let line = new Line3(p1, p2);
            let max = -100000;
            let p3 = new Vector3(max, max, max);
            plane.intersectLine(line, p3);//交点{
            if (p3.x != max && p3.y != max && p3.z != max) {
                let disOfp1p2 = p1.distanceTo(p2);
                let disOfp1p3 = p1.distanceTo(p3);
                let pp1 = this.parent.parent.getOnePointPressure(instanceName, this.parent.getCurrentLevel(), this.parent.getCurrentCMSite(), indexp1);
                let pp2 = this.parent.parent.getOnePointPressure(instanceName, this.parent.getCurrentLevel(), this.parent.getCurrentCMSite(), indexp2);
                let C = -9999.9999;
                if (pp1 !== false && pp2 !== false)
                    C = (1 - disOfp1p3 / disOfp1p2) * pp1 + disOfp1p3 / disOfp1p2 * pp2;
                intersectPoint.push({
                    pressure: C,
                    xyz: [p3.x, p3.y, p3.z],
                    uv: [0, 0, 1, 0, 1, 1],
                    normal: [0, 0, 1,
                        0, 0, 1,
                        0, 0, 1,],
                });
            }
        }
        return intersectPoint;
    }
    //相交box
    intersectBoxOfPlane(points, plane) {
        let box3 = new Box3();
        for (let i of points) {
            box3.expandByPoint(new Vector3(i[0], i[1], i[2]));
        }
        let intersect = plane.intersectsBox(box3);
        return intersect;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // end clipping and remove poly of clipping 
    emptyClipping() {
        if (typeof this.meshOfTriangle != "undefined") {
            this.scene.remove(this.meshOfTriangle);
        }
        if (typeof this.meshOfFrame != "undefined") {
            this.scene.remove(this.meshOfFrame);
        }
    }
    hide() {
        this.meshOfTriangle.visible = false;
        this.meshOfFrame.visible = false;
    }
    show() {
        this.meshOfTriangle.visible = true;
        this.meshOfFrame.visible = true;
    }
    clean() {
        this.emptyClipping();
    }

    //show or hide frameline of clipping 
    setFramelineEnable() {
        let value = this.parent.setting.enableFrameline;
        if (value) {
            if (typeof this.groupOfRepairePoly != "undefined") {
                this.groupOfRepairePolytraverse(function (child) {
                    if (child.name === "frameLines") {
                        child.visible = true;
                    }
                });
            }
        }
        else {
            if (typeof this.groupOfRepairePoly != "undefined")
                this.groupOfRepairePoly.traverse(function (child) {
                    if (child.name === "frameLines") {
                        child.visible = false;
                    }
                });
        }
        this.parent.render();
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //draw 
    draw() {
        if (typeof this.dataOfClipping.triangles.xyz != "undefined" /*&& this.dataOfClipping.triangles.xyz.length > 0*/) {
            let cm = new Float32Array(this.dataOfClipping.triangles.cm);
            let uv = new Float32Array(this.dataOfClipping.triangles.uv);
            let normal = new Float32Array(this.dataOfClipping.triangles.normal);
            let geometry = new BufferGeometry();
            geometry.setAttribute("position", new BufferAttribute(new Float32Array(this.dataOfClipping.triangles.xyz), 3));
            geometry.setAttribute("uv", new Float32BufferAttribute(uv, 2));
            geometry.setAttribute("normal", new Float32BufferAttribute(normal, 3));
            geometry.setAttribute("cm", new Float32BufferAttribute(cm, 3));
            let meshOfTriangle = new Mesh(geometry, this.parent.material.TriangleShaderStair());
            meshOfTriangle.name = "triagnle of clipping";
            if (typeof this.meshOfTriangle != "undefined") {
                this.scene.remove(this.meshOfTriangle);
            }
            this.meshOfTriangle = meshOfTriangle;
            this.scene.add(meshOfTriangle);

        }
        if (typeof this.dataOfClipping.framelines != "undefined"/* && Object.keys(this.dataOfClipping.framelines).length > 0*/) {
            let points = [];
            for (let i in this.dataOfClipping.framelines) {
                let oneLine = this.dataOfClipping.framelines[i];
                for (let j of oneLine) {
                    points.push(j);
                }
            }
            let geometry = new BufferGeometry();
            let vertices = new Float32Array(points);
            // let colors = new Float32Array(data.color);
            geometry.setAttribute("position", new BufferAttribute(vertices, 3));
            let meshOfFrame = new LineSegments(geometry, this.parent.material.FameLine());
            meshOfFrame.name = "frame of clipping";
            if (typeof this.meshOfFrame != "undefined") {
                this.scene.remove(this.meshOfFrame);
            }
            this.meshOfFrame = meshOfFrame;
            this.scene.add(meshOfFrame);
        }
    }





}
export { Clipping };
